<template>
  <div id="App" :class="headerShow?'AppClassNo':'AppClass'">
<!--    <nav>-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </nav>-->
	<div v-if="headerShow">
		<Header></Header>
	</div> 
    <router-view></router-view>
	<div v-if="headerShow">
		<Footer></Footer>
	</div>
  </div>
</template>
<script>
	import Header  from './components/layout/header';
	import Footer  from './components/layout/footer';

	export default {
		  name: 'App',
			data() {
			return {
			  headerShow:true,
			};
		  },
	    components:{
	        Header,
			Footer
	    },
		created() {
			this.routeFun()
		},
		watch:{
			$route(to,from) {
			 if (this.$route.path === "/login" || this.$route.path === "/register" || this.$route.path === "/forget") {
			 	this.headerShow = false;
			 	document.body.style.height = '100%';
			 	document.documentElement.style.height = '100%';

			 } else {
			 	this.headerShow = true;
				document.body.style.height = '100%';
				document.documentElement.style.height = 'auto';
			 }
			},
		},
		methods: {
			routeFun(){
				if (this.$route.path === "/login" || this.$route.path === "/register" || this.$route.path === "/forget") {
					this.headerShow = false;
					document.body.style.height = '100%';
					document.documentElement.style.height = '100%';
				} else {
					this.headerShow = true;
					document.body.style.height = '100%';
					document.documentElement.style.height = 'auto';
				}
				console.log(this.headerShow)
			},
			request(){
				this.$http.post('/itemProps/addItemProps',{
				　　name: this.name,
				　　parentId:this.parentId,
				　　sortOrder:0
				}).then((res)=>{
				　　console.log(res)
				}).catch((err)=>{
				   alert("请求失败") 
				})
				//get
				this.$http.get("/roles/getPersonnelInfoRoles",{
				　　params:{
				　　　　key:"value"
				　　}
				}).then((res) => {
				　　console.log(res)
				}).catch((err)=>{
				　　alert("请求失败")
				})
			}
			
		}
	}
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.AppClass{
	width: 100%;
	height: 100%;
}
.AppClassNo{
	width: 100%;
	height: auto;
}
.seizeSeat{
	width: 100%;
	height: 0.7rem;
}
.display{
	display: flex;
	align-items: center;
}
.discen{
	display: flex;
	justify-content: center;
	align-items: center;
}
.disbet{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.discolcen{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.ellipsisNew{
	white-space: normal;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.indexTitle{
		width: 100%;
		height: auto;
		font-size: 0.32rem;
		color: #202020;
		text-align: center;
		padding: 0 0.3rem;
		line-height: 0.4rem;
		font-weight: 700;
	}
	.indexSubTitle{
		width: 100%;
		height: auto;
		padding: 0 1rem;
		font-size: 0.16rem;
		color: #7F8284;
		margin: 0.1rem auto 0;
		text-align: center;
	}
	.pageBox{
		width: 100%;
		height: 0.5rem;
	}
	.pageButton{
		width: auto;
		height: 0.3rem;
		border: 1px solid #D51820;
		border-radius: 5px;
		padding: 0 0.1rem;
		margin-right: 0.2rem;
		font-size: 0.16rem;
		color: #D51820;
		cursor: pointer;
	}
	.pageButtonPrev{
		background: #efefef;
		color: #828384;
		border: 1px solid #efefef;
	}
	.pageNum{
		width: auto;
		height: 0.3rem;
		border: 1px solid #202020;
		border-radius: 5px;
		padding: 0 0.15rem;
		margin-right: 0.2rem;
		font-size: 0.16rem;
		color: #202020;
		margin-right: 0.2rem;
		cursor: pointer;
	}
	.pageNumCun{
		background: #D51820;
		color: #ffffff;
		border: 1px solid #D51820;
	}
	/* element-message.css */
	.el-message {
	  z-index: 10005 !important; /* 修改为你需要的层级 */
	}
@media only screen and (max-width:1080px){
	.seizeSeat{
		width: 100%;
		height: 0.9rem;
	}
}
</style>
