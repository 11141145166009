<template>
	<div id="risk">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- <div class="riskBox">
			<div class="riskTitle">{{ $t('risk.title') }}</div>
			<div class="riskTime">{{ $t('risk.time') }}</div>
			<div class="riskDesc" v-html="$t('risk.desc') "></div>
		</div> -->
		<div class="scrollBox" v-if="id == 1">
		      <pdf v-for="item in 24" :key="item" :src="pdfSrc1" :page="item" ref="pdf"></pdf>
		</div>
		<div class="scrollBox" v-else-if="id == 2">
		      <pdf v-for="item in 8" :key="item" :src="pdfSrc2" :page="item" ref="pdf"></pdf>
		</div>
		<div class="scrollBox" v-else-if="id == 3">
		      <pdf v-for="item in 25" :key="item" :src="pdfSrc3" :page="item" ref="pdf"></pdf>
		</div>
		<div class="scrollBox" v-else>
		      <pdf v-for="item in 3" :key="item" :src="pdfSrc4" :page="item" ref="pdf"></pdf>
		</div>
	</div>
</template>

<script>
	import pdf from 'vue-pdf'
	export default {
		name: 'risk',
		components: {
			pdf
		  },
		data() {
			return {
				id:1,
				pdfSrc1:'https://ychpro.oss-ap-southeast-1.aliyuncs.com/common/Disclosure%20Statement--YCHpro.pdf',//《披露与声明》
				pdfSrc2:'https://ychpro.oss-ap-southeast-1.aliyuncs.com/common/PrivacyPolicy-YCHpro.pdf',//《隐私政策》
				// pdfSrc3:'https://mp-e1d07986-ea54-48f1-bff2-c1a675dde98f.cdn.bspapp.com/TermsandConditionsYCHpro.pdf',
				pdfSrc3:'https://ychpro.oss-ap-southeast-1.aliyuncs.com/common/TermsandConditions--YCHpro.pdf',//《条款与条件》
				pdfSrc4:'https://ychpro.oss-ap-southeast-1.aliyuncs.com/common/ExecutionPolicy--YCHpro.pdf',//执行政策
				numPages: 24, // pdf 总页数
			}
		},
		mounted() {

		},
		created() {
			this.id = this.$route.query.id;
			this.numPages = this.id == 1?24:(this.id == 2?8:25)
			console.log(this.id)
			// this.getNumPages()
			// console.log(this.markets[this.navIndex].six)
		},

		methods: {
			getNumPages() {
			      let loadingTask = pdf.createLoadingTask(this.pdfSrc)
			      loadingTask.promise.then(pdf => {
			        this.numPages = pdf.numPages
			      }).catch(err => {
			        console.error('pdf 加载失败', err);
			      })
			    },
		}
	}
</script>

<style lang="scss" scoped>
	.scrollBox{
		width: 80%;
		height: auto;
		margin: 0.5rem auto;
	}
	.riskBox{
		width: 12rem;
		height:auto;
		min-height: 5.6rem;
		margin: 0 auto;
		padding: 0.4rem 0 0;
		.riskTitle{
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
		}
		.riskTime{
			font-size: 0.16rem;
			color: #7F8284;
			margin: 0.1rem 0 0.28rem;
		}
		.riskDesc{
			font-size: 0.16rem;
			color: #202020;
			line-height: 0.25rem;
		}
	}
</style>
