<template>
	<div id="problem">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<div class="problemBox" v-if="mainJson.block1 && mainJson.block2">
			<div class="indexTitle" v-html="mainJson.block1.title"></div>
			<div class="indexSubTitle" v-html="mainJson.block1.desc"></div>
			<div class="sameproblem"  v-for="(item,index) in list" :key="index">
				<div class="indexTitle" v-html="item.title"></div>
				<div class="indexSubTitle" v-html="item.desc"></div>
				<div class="samePlateSix" :class="sitem.show?'samePlateSixSe':''" v-if="item.list" v-for="(sitem,sindex) in item.list" :key="sindex"> 
					<div class="samePlateSixTitle" @click="showPlateSix(index,sindex)">
						<span v-html="sitem.title"></span>
						<img :class="sitem.show?'samePlateSixImg':''" src="@/assets/images/markets/dropdown.png"/>
					</div>
					<div class="samePlateSixDesc">
						<div class="samesixDec" v-html="sitem.desc"></div>
						<router-link to="" class="sameAccounta" v-if="sitem.button">
							<span>{{sitem.button}}</span>
							<img src="@/assets/images/img/more.png"/>
						</router-link>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		name: 'problem',
		data() {
			return {
				list:[],
				mainJson:{}
			}
		},
		mounted() {
			// var that = this;
			// window.addEventListener('setItemEvent', function(e) {
			//       // const newdata = JSON.parse(e.newValue)
			//    that.list = that.$t('faq.list');
			// })
		},
		created() {
			// this.list = this.$t('faq.list');
			this.getJsonConfig();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=problem.json':'getJsonConfig?fileName=problem_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					// if(configData.block3.list.length>0&&configData.block3.list[0].hasOwnProperty('sortNo')){
					// 	configData.block3.list =  (configData.block3.list).sort((a, b) => a.sortNo - b.sortNo);
					// }
					// if(configData.block5.list.length>0&&configData.block5.list[0].hasOwnProperty('sortNo')){
					// 	configData.block5.list =  (configData.block5.list).sort((a, b) => a.sortNo - b.sortNo);
					// }
					(configData.block2).forEach((item,index)=>{
						(item.list).forEach((sitem,sindex)=>{
							sitem['show'] = false
						})
					})
					this.mainJson = configData;
					this.list = this.mainJson.block2;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			showPlateSix(index,sindex){
				console.log(index,sindex)
				if(!this.list[index].list[sindex].show){
					this.list.forEach((item,i)=>{
						item.list.forEach((sitem,s)=>{
							if(sindex == s&&i == index){
								sitem.show = true
							}else{
								sitem.show = false
							}
						})
						
					})
				}else{
					this.list[index].list[sindex].show = !this.list[index].list[sindex].show
				}
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	.problemBox{
		width: 12rem;
		height: auto;
		margin: 0 auto;
		padding: 1.05rem 0 2.6rem ;
		.sameproblem{
			width:100%;
			height: auto;
			.indexTitle{
				padding: 1rem 0 0.7rem;
			}
			.samePlateSix{
				width: 100%;
				height: 1rem;
				border-bottom: 1px solid #EAECEC;
				overflow: hidden;
				transition: all 0.5s;
				.samePlateSixTitle{
					width: 100%;
					height: 1rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 0.2rem;
					color: #202020;
					// font-weight: 700;
					>img{
						width: 0.2rem;
						height: 0.2rem;
						transition: all 0.3s;
					}
					.samePlateSixImg{
						transform: rotate(180deg);
						transition: all 0.3s;
					}
				}
				.samePlateSixDesc{
					width: 100%;
					height: auto;
					padding-bottom: 0.3rem;
					font-size: 0.16rem;
					color: #202020;
					line-height: 0.35rem;
					.ifSixListTitle{
						font-weight: 700;
					}
				}
				.sameAccounta{
					width: 2.36rem;
					height: 0.46rem;
					background: #D51820;
					opacity: 1;
					border-radius: 0.25rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.16rem;
					color: #FFFFFF;
					margin: 0.3rem auto;
					>img{
						width: 0.12rem;
						height: 0.12rem;
						margin-left: 0.1rem;
					}
				}
			}
			.samePlateSixSe{
				height: auto;
				transition: all 0.5s;
			}
		}
		
	}
	@media only screen and (max-width:1080px){
		.problemBox{
			width: 100%;
			padding: 1.05rem 0.3rem 2.6rem ;
			.indexSubTitle{
				padding: 0;
			}
		}
		
	}
</style>