<template>
	<div id="openAccount">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="customBanner" v-if="mainJson.block1" v-html="mainJson.block1.title"
		:style="{backgroundImage:'url('+mainJson.block1.img+')'}"></div>
		<!-- accountBox -->
		<div class="accountBox">
			<div class="sameAccount" v-for="(item,index) in mainJson.block2" :key="index">
				<div class="sameAccountTitle" v-html="item.title"></div>
				<div class="sameAccountDesc" v-html="item.desc"></div>
				<div @click="openAccount(1)" class="sameAccounta">
					<span>{{$t('open.button')}}</span>
					<img src="@/assets/images/img/more.png"/>
				</div>
				<div class="sameAccountList">
					<div class="sameAccountListBox" :class="sindex == 0?'sameAccountListBoxTitle':''"
					 v-for="(sitem,sindex) in item.list" :key="sindex">
						<div v-html="sitem.title"></div>
						<div v-html="sitem.desc"></div>
					</div>
				</div>
			</div>
		</div>
		<Tips :clickCcountAll="clickCcountAll" @openAccountAllFun="openAccountAllFun"></Tips>
	</div>
</template>

<script>
	import Tips  from '../components/layout/tips';
	export default {
		name: 'openAccount',
		components:{
			Tips
		},
		data() {
			return {
				clickCcountAll:false,
				mainJson:{}
			}
		},
		mounted() {

		},
		created() {
			this.getJsonConfig();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=open_account.json':'getJsonConfig?fileName=open_account_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					this.mainJson = configData;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			openAccountAllFun(){
				this.clickCcountAll = !this.clickCcountAll;
			},
			openAccount(type){
				// type:1 开户   2合作伙伴注册
				if(localStorage.getItem('token')){
					this.openAccountAllFun();
				}else{
					this.openAccountAll(type);
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.customBanner{
		width: 100%;
		height: 4.3rem;
		background-image: url('../assets/images/custom/custom01.png');
		background-size: cover;
		font-size: 0.44rem;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		// align-items: center;
		text-align: center;
		padding: 1.28rem 0 0;
		line-height: 0.7rem;
	}
	.accountBox{
		width: 12rem;
		height: auto;
		margin: 0 auto 2.5rem;
		.sameAccount{
			width: 100%;
			height: auto;
			padding: 0.94rem 0 0;
			.sameAccountTitle{
				width: 100%;
				height:auto;
				font-size: 0.32rem;
				color: #202020;
				font-weight: 700;
				text-align: center;
			}
			.sameAccountDesc{
				width: 100%;
				height:auto;
				font-size: 0.16rem;
				color: #202020;
				text-align: center;
				margin: 0.35rem 0;
			}
			.sameAccounta{
				width: 2.36rem;
				height: 0.46rem;
				background: #D51820;
				opacity: 1;
				border-radius: 0.25rem;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0.16rem;
				color: #FFFFFF;
				margin: 0 auto;
				cursor: pointer;
				>img{
					width: 0.12rem;
					height: 0.12rem;
					margin-left: 0.1rem;
				}
			}
			.sameAccountList{
				width: 100%;
				height: auto;
				padding: 0.56rem 0.3rem 0;
				.sameAccountListBox{
					width: 100%;
					height: 0.6rem;
					display: flex;
					align-items: center;
					font-size: 0.16rem;
					color: #202020;
					border-bottom: 1px solid #E4E4E4;
					>div:first-child{
						width: 3.78rem;
						padding-left: 0.4rem;
					}
					>div:last-child{
						flex: 1;
						padding-left: 0.4rem;
					}
				}
				.sameAccountListBoxTitle{
					height: 0.66rem;
					background-color: #ECEFF2;
					font-size: 0.2rem;
					font-weight: 700;
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		.customBanner{
			width: 100%;
			height: auto;
			font-size: 0.36rem;
			padding: 1.28rem 0.3rem;
			line-height: 0.5rem;
		}
		.accountBox{
			width: 100%;
			height: auto;
			padding: 0 0.5rem;
			margin: 0 auto 2.5rem;
			.sameAccount{
				padding: 1rem 0 0.5rem;
				.sameAccounta{
					width: 3rem;
					height: 0.5rem;
				}
				.sameAccountList{
					width: 100%;
					height: auto;
					padding: 0.56rem 0.3rem 0;
					.sameAccountListBox{
						width: 100%;
						height: auto;
						>div:first-child{
							width:30%;
							padding: 0.15rem 0 0.15rem 0.2rem;
						}
						>div:last-child{
							flex: 1;
							padding: 0.15rem 0 0.15rem 0.2rem;
						}
					}
					.sameAccountListBoxTitle{
						height: 0.9rem;
						background-color: #ECEFF2;
						font-size: 0.2rem;
						font-weight: 700;
					}
				}
			}
		}
		
		
	}
</style>