<template>
	<div id="Forget">
		<HeaderAccount></HeaderAccount>
		<div class="loginBox discen">
			<div class="loginLeft">
				<div class="loginLeftTitle display" v-html="$t('aboutAccount.title')"></div>
				<div class="loginLeftDesc">{{ $t('aboutAccount.desc') }}</div>
			</div>
			<div class="loginRight discolcen">
				<div class="loginRightTitle">{{ $t('aboutAccount.forget') }}</div>
				<div class="sameForm">
					<div class="formTitle display">{{ $t('aboutAccount.accountNumber') }}:</div>
					<div class="sameInput disbet">
						<input class="inputpassword" style="width: 60%;" type="text"  placeholder=""/>
						<div class="sameRight discen" v-if="issend">55s {{ $t('aboutAccount.resend') }}</div>
						<div class="sameRight discen" v-if="!issend"> {{ $t('aboutAccount.send') }}</div>
					</div>
				</div>
				<div class="sameForm">
					<div class="formTitle display">{{ $t('aboutAccount.code') }}:</div>
					<input class="inputBox" type="text"  placeholder=""/>
				</div>
				<div class="sameForm">
					<div class="formTitle display">{{ $t('aboutAccount.resetPassword') }}:</div>
					<div class="sameInput disbet">
						<input class="inputpassword" v-if="ispassword" type="text"  placeholder=""/>
						<input class="inputpassword" v-if="!ispassword" type="password"  placeholder=""/>
						<img class="ispassword" @click="ispasswordFun" v-if="ispassword" src="@/assets/images/img/display.png"/>
						<img class="ispassword" @click="ispasswordFun" v-if="!ispassword" src="@/assets/images/img/hide.png"/>
					</div>
				</div>

				<div class="submit discen">{{ $t('login.submit') }}</div>
				<div class="signUp discen">
					<span>{{ $t('aboutAccount.account') }}</span>
					<router-link class="signUpa" to="/login">{{ $t('login.signIn') }}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import HeaderAccount  from '../components/layout/headerAccount';
	export default {
		name: 'Forget',
		components:{
	        HeaderAccount
	    },
		data() {
			return {
				ispassword:false,
				issend:false,
				
			}
		},
		mounted() {
		},
		created() {
			// this.$message({
			//   message: '恭喜你，这是一条成功消息',
			//   type: 'success'
			// });
			
			
		},
		methods: {
			login(){
				console.log(this.$apis)
				const userinfo = {
					username:'11',
					password:11
				};
				this.$apis.loginUrl(userinfo).then(res => {
					console.log(res)
				})
				// axios({
				//   method: 'post',
				//   url: 'https://www.ychpro.com/website/public/member/login',
				//   data: {
				//     username: 'Fred',
				//     password: '11'
				//   }
				// }).then(res=>{
				// 	console.log(res)
				// })

				
			},
			ispasswordFun(){
				this.ispassword = !this.ispassword;
			},
		}
	}
</script>
<style lang="scss" scoped>
	#Forget{
		width: 100%;
		height: 100%;
		background-image: url('/src/assets/images/img/loginBg.png');
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		
		.loginBox{
			width: auto;
			height: auto;
			overflow: hidden;
			border-radius: 0.18rem;
			.loginLeft{
				width: 5.2rem;
				height: 6rem;
				background: #D51820;
				box-shadow: 0px 0px 40px rgba(184,187,192,0.16);
				opacity: 0.72;
				padding: 1.2rem 0.73rem 0;
				.loginLeftTitle{
					width: 100%;
					height: auto;
					margin-bottom: 0.2rem;
					font-size: 0.4rem;
					color: #FFFFFF;
					font-weight: 700;
				}
				.loginLeftDesc{
					width: 100%;
					height: auto;
					font-size: 0.19rem;
					color: #FFFFFF;
					line-height: 0.4rem;
				}
			}
			.loginRight{
				width: 5.20rem;
				height: 6rem;
				background: #FFFFFF;
				padding: 0 0.5rem;
				.loginRightTitle{
					width: 100%;
					height: 0.4rem;
					font-size: 0.34rem;
					color: #202020;
					font-weight: 700;
				}
				.sameForm{
					width: 100%;
					height: auto;
					margin-top: 0.15rem;
					.formTitle{
						width: 100%;
						height: 0.4rem;
						font-size: 0.16rem;
						color: #202020;
					}
					.inputBox{
						width: 100%;
						height: 0.54rem;
						background: #F3F3F3;
						border-radius: 2px;
						padding: 0 0.2rem;
						font-size: 0.16rem;
						color: #202020;
						margin: 0;
						display: block;
					}
					.sameInput{
						width: 100%;
						height: 0.54rem;
						background: #F3F3F3;
						border-radius: 2px;
						padding: 0 0.2rem;
						font-size: 0.16rem;
						color: #202020;
						.inputpassword{
							width: 80%;
							height: 0.54rem;
							font-size: 0.16rem;
							color: #202020;
							margin: 0;
							display: block;
						}
						.ispassword{
							width: 0.44rem;
							height: 0.44rem;
							cursor: pointer;
						}
						.sameRight{
							width: 0.95rem;
							height: 0.35rem;
							border: 1px solid #D51820;
							opacity: 1;
							border-radius: 0.04rem;
							font-size: 0.14rem;
							color: #D51820;
							cursor: pointer;
						}
					}
				}
				.forGet{
					width: 100%;
					height: 50rpx;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					.forGeta{
						width: auto;
						height: 0.5rem;
						font-size: 0.14rem;
						color: #BEBEBE;
						line-height: 0.5rem;
					}	
				}
				.submit{
					width: 100%;
					height: 0.54rem;
					background: #D51820;
					border-radius: 2px;
					font-size: 0.16rem;
					color: #FFFFFF;
					margin-top: 0.5rem;
				}
				.signUp{
					width: 100%;
					height: 0.5rem;
					font-size: 0.14rem;
					color: #BEBEBE;
					.signUpa{
						width: auto;
						height: 0.5rem;
						line-height: 0.5rem;
						font-size: 0.16rem;
						color: #D51820;
					}
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
			#Forget{			
				.loginBox{
					width:100%;
					padding:0 0.5rem;
					.loginLeft{
						display: none;
					}
					.loginRight{
						width: 100%;
						min-height: 6rem;
						height: auto;
						padding: 0.5rem;
						border-radius: 0.2rem;
						.loginRightTitle{
							width: 100%;
							height: 0.8rem;
						}
						.sameForm{
							width: 100%;
							height: auto;
							margin-top: 0.3rem;
							.formTitle{
								width: 100%;
								height: 0.8rem;
							}
							.inputBox{
								width: 100%;
								height: 0.8rem;
							}
							.sameInput{
								width: 100%;
								height: 0.8rem;
								.inputpassword{
									height: 0.8rem;
								}
								.ispassword{
									width: 0.44rem;
									height: 0.44rem;
									cursor: pointer;
								}
								.sameRight{
									width: 0.95rem;
									height: 0.4rem;
								}
							}
						}
						.forGet{
							width: 100%;
							height: 0.7rem;
							.forGeta{
								width: auto;
								height: 0.5rem;
								font-size: 0.14rem;
								color: #BEBEBE;
								line-height: 0.5rem;
							}	
						}
						.submit{
							width: 100%;
							height: 0.7rem;
						}
						.signUp{
							width: 100%;
							height: 0.6rem;
							.signUpa{
								width: auto;
								height: 0.6rem;
								line-height: 0.6rem;
							}
						}
					}
				}
			}
			
		}
	
</style>