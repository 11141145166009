<template>
	<div id="Partners">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="partnerbanner" v-if="mainJson.block1" :style="{backgroundImage:'url('+mainJson.block1.img+')'}">
			<div class="bannerBox">
				<div class="bannerTitle" v-html="mainJson.block1.title"></div>
				<div class="bannerDesc" v-html="mainJson.block1.desc"></div>
			</div>
		</div>
		<!-- two -->
		<div class="parTwo">
			<div v-if="mainJson.block2">
				<div class="parTwoTitle" v-html="mainJson.block2.title"></div>
				<div class="parTwoSubTitle" v-html="mainJson.block2.desc"></div>
			</div>
			<div v-if="mainJson.block3">
				<div class="parTwoTitle" v-html="mainJson.block3.title"></div>
				<div class="subdesc" v-html="mainJson.block3.desc"></div>
				<div class="parTwoBox">
					<div class="sameparTwo" v-for="(item,index) in mainJson.block3.list" :key="index">
						<img :src="item.img"/>
						<div v-html="item.title"></div>
					</div>
				</div>
			</div>	
		</div>
		
		<!-- Enjoy more, earn more -->
		<div class="enjoyBox"  v-if="mainJson.block4">
			<div class="enjoyTitle" v-html="mainJson.block4.title"></div>
			<div class="enjoyDesc" v-html="mainJson.block4.desc"></div>
			<!-- <div class="enjoycontact">{{ $t('partner.three.subdesc') }}</div> -->
			<div @click="openAccount(2)" class="enjoya">
				<span>{{ $t('partner.three.button') }}</span>
				<img src="@/assets/images/img/more.png"/>
			</div>
		</div>
		<!-- join now -->
		<div class="joinNow" v-if="mainJson.block5">
			<div class="joinNowTitle" v-html="mainJson.block5.title"></div>
			<div class="joinNowSubtitle" v-html="mainJson.block5.desc"></div>
			<div class="joinNowBox">
				<div class="samejoin" :style="{backgroundImage:'url('+item.img+')'}"
				v-for="(item,index) in mainJson.block5.list" :key="index" v-html="item.title">
					
				</div>
			</div>
			<div class="joinNowTips" v-html="$t('partner.four.contact')"></div>
			<div @click="openAccount(2)" class="joinNowa">
				<span>{{ $t('login.signUp') }}</span>
				<img src="@/assets/images/img/more.png"/>
			</div>
		</div>
		<Tips :clickCcountAll="clickCcountAll" @openAccountAllFun="openAccountAllFun"></Tips>
	</div>
</template>

<script>
	import Tips  from '../components/layout/tips';
	export default {
		name: 'Partners',
		components:{
			Tips
		},
		data() {
			return {
				clickCcountAll:false,
				mainJson:{}
			}
		},
		mounted() {

		},
		created() {
			this.getJsonConfig();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=partners.json':'getJsonConfig?fileName=partners_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block3.list.length>0&&configData.block3.list[0].hasOwnProperty('sortNo')){
						configData.block3.list =  (configData.block3.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block5.list.length>0&&configData.block5.list[0].hasOwnProperty('sortNo')){
						configData.block5.list =  (configData.block5.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					this.mainJson = res.data;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			openAccountAllFun(){
				this.clickCcountAll = !this.clickCcountAll;
			},
			openAccount(type){
				// type:1 开户   2合作伙伴注册
				if(localStorage.getItem('token')){
					this.openAccountAllFun();
				}else{
					this.openAccountAll(type);
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/assets/css/partners.css';
	.partnerbanner{
		width: 100%;
		height: 6.8rem;
		background-size: cover;
		.bannerBox{
			width: 100%;
			height: 6.8rem;
			padding-top: 2.06rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			.bannerTitle{
				width: 100%;
				font-size: 0.44rem;
				color: #FFFFFF;
				// padding-left: 4.54rem;
				text-align: center;
			}
			.bannerDesc{
				// padding-left: 7.94rem;
				font-size: 0.34rem;
				color: #FFFFFF;
				margin:0.3rem 0 0;
				width: 100%;
				text-align: center;
			}
		}
	}
	.parTwo{
		width: 100%;
		height: auto;
		padding: 0.9rem 0;
		.parTwoTitle{
			width: 100%;
			height: auto;
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
			text-align: center;
			padding: 0 0.3rem;
			line-height: 0.4rem;
			font-weight: 700;
		}
		.parTwoSubTitle{
			width: 100%;
			height: auto;
			line-height: 0.3rem;
			padding: 0 1rem;
			font-size: 0.18rem;
			color: #7F8284;
			margin: 0.3rem auto 1rem;
			text-align: center;
		}
		.subdesc{
			text-align: center;
			font-size: 0.32rem;
			color: #202020;
			margin: 0.75rem auto 0.4rem;
		}
		.parTwoBox{
			width: 12rem;
			height: auto;
			margin:0 auto;
			display: flex;
			flex-wrap: wrap;
			.sameparTwo{
				width: 2.8rem;
				height: auto;
				display: flex;
				flex-direction: column;
				font-size: 0.18rem;
				color: #0D0A0A;
				line-height: 0.3rem;
				margin-right: 0.2rem;
				text-align: center;
				>img{
					width: 0.56rem;
					height: 0.56rem;
					margin-bottom: 0.16rem;
				}
			}
		}
	}
	
	.enjoyBox{
		width: 100%;
		height: 4.1rem;
		background-image: url('../assets/images/partners/partners012.png');
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.enjoyTitle{
			font-size: 0.32rem;
			color: #FFFFFF;
			font-weight: 700;
		}
		.enjoyDesc{
			font-size: 0.22rem;
			color: #FFFFFF;
			margin:0.3rem 0 0;
			text-align: center;
		}
		.enjoycontact{
			font-size: 0.18rem;
			color: #FFFFFF;
			margin:0.3rem 0 0;
			text-align: center;
		}
		.enjoya{
			width: 2.70rem;
			height: 0.46rem;
			background: #D51820;
			border-radius: 0.25rem;
			font-size: 0.16rem;
			color: #FFFFFF;
			margin:0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			margin:0.3rem 0 0;
			cursor: pointer;
			>img{
				width: 0.12rem;
				height:0.12rem;
				margin-left: 0.05rem;
			}
		}
	}
	.joinNow{
		width: 100%;
		height: auto;
		padding: 1.2rem 0.3rem 2.6rem;
		.joinNowTitle{
			line-height: 0.5rem;
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
			text-align: center;
		}
		.joinNowSubtitle{
			font-size: 0.26rem;
			color: #202020;
			text-align: center;
			margin: 0.5rem auto;
		}
		.joinNowTips{
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
			text-align: center;
			margin: 1.7rem auto 0.4rem;
		}
		.joinNowBox{
			width: 12rem;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 auto;
			.samejoin{
				width: 3.8rem;
				height: 2.9rem;
				margin: 0 0 0.3rem 0;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 0.4rem;
				font-size: 0.2rem;
				color: #FFFFFF;
				background-size: cover;
				text-align: center;
			}
		}
		.joinNowa{
			width: 2.30rem;
			height: 0.46rem;
			background: #D51820;
			border-radius: 0.25rem;
			font-size: 0.16rem;
			color: #FFFFFF;
			margin:0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			>img{
				width: 0.12rem;
				height:0.12rem;
				margin-left: 0.05rem;
			}
		}
	}
	@media only screen and (max-width:1080px){
		.partnerbanner{
			height: auto;
			.bannerBox{
				width: 100%;
				height:auto;
				padding: 1.5rem 0;
				.bannerTitle{
					width: 100%;
					font-size: 0.36rem;
					color: #FFFFFF;
					padding:0 0.5rem;
					text-align: center;
				}
				.bannerDesc{
					width: 100%;
					padding:0 0.5rem;
					font-size: 0.36rem;
					text-align: center;
				}
			}
		}
		.parTwo{
			width: 100%;
			height: auto;
			padding: 0.9rem 0.3rem;
			.parTwoBox{
				width: 100%;
				justify-content: space-between;
				.sameparTwo{
					width: 47%;
					margin-right: 0;
					margin-bottom: 0.5rem;
					>img{
						margin: 0 auto 0.16rem;
					}
				}
			}
		}
		.enjoyBox{
			width: 100%;
			height: auto;
			padding: 1rem 0.5rem;
			.enjoya{
				width: 3rem;
				height: 0.5rem;
			}
		}
		.joinNow{
			.joinNowBox{
				width: 100%;
				.samejoin{
					width: 48%;
					background-size: 100% 2.9rem;
				}
			}
			.joinNowa{
				width: 2.5rem;
				height: 0.5rem;
			}
		}
		
		
	}
</style>