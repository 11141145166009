module.exports =  {
	element:{
		inReview:"正在审核中",
		logon:"请先登录",
		confirm:"确认",
		cancel:"取消",
		prompt:'提示',
		cookie:'我们在本网站上使用许多 cookie 用于功能、分析和营销目的。继续使用本网站，即表示您同意我们使用 cookie。',
		loginTips:'登录即代表同意',
		registerTips:'注册即代表同意',
		word1:'《披露与声明》',
		word2:'《隐私政策》',
		word3:'《条款与条件》',
		prevPage:'上一页',
		nextPage:'下一页',
		newsList:'新闻列表',
		marketsBannerTitle:'立即开户',
		marketsThreeTitle:'现在开户',
		learnMore:'查看更多',
		author:'老师'
	},
    login: {
        signUp:"注册",
        signIn: "登录",
    	signOut:"登出",
		submit:'提交',
		tips:'账号审核中',
		signtips:'确认退出登录?'
    },
	aboutAccount:{
		title:'无条件交易 <br/> 限制',
		desc:'超越想象，体验非凡的交易乐趣',
		accountNumber:'邮箱地址',
		code:'验证码',
		noAccount:'还没有账号？',
		account:'已有账号？',
		password:'密码',
		confirmPassword:"确认密码",
		forget:'忘记密码',
		resend:'重新发送',
		send:'发送',
		resetPassword:'重置密码',
		accountNumberNew:"账号"
	},
    language:{
    	en:'英文',
    	zh:'中文',
		home:'首页'
    },
	risk:{
		title:"风险披露",
		subDesc:"风险提示:",
		subTitle:"外汇和差价合约交易对于您的投资资本含有风险。请确保您已通读并完全理解我们的风险披露",
		time:"2022年11月6日",
		desc0:"YCHpro Securities Co Ltd根据2005年《证券法》、2007年《证券(牌照)规则》、2008年《金融服务(综合牌照及收费)规则》，有毛里求斯金融服务委员会（FSC）注册，许可证号GB23202152，公司注册号199660。",
		desc:"YCHpro的网站和服务，均严禁开放提供给任何受国家/地区法律法规禁止使用的任何个体。因此，若选择从该国访问本公司网站，无论该国是否禁止使用本网站，用户在使用本网站时，均有责任确保自己遵守当地法律或法规。YCHpro不保证本公司网站上的信息，适用于所有的司法管辖区域。<br/><br/>受限地区： YCHpro无法为特定国家/地区的居民提供服务，例如： 美国、伊朗和朝鲜。"
	},
    nav:[
    	{id:1,title:'产品',src:'/markets',list:[{id:1,title:'外汇'},{id:2,title:'指数'},{id:3,title:'大宗商品'},{id:5,title:'贵金属'},]},
    	{id:2,title:'交易平台',src:'/tradingPlatform',list:[]},
    	// {id:3,title:'市场研究',src:'/marketingResearch',list:[{id:1,title:'市场研究',src:'/marketingResearch'},{id:2,title:'交易信号分析',src:'/tradingSignalAnalysis'},{id:3,title:'经济日历',src:'/economicCalendar'}]},
    	// {id:4,title:'社区',src:'/activity',list:[{id:1,title:'活动',src:'/activity'},{id:2,title:'学院',src:'/college'},{id:3,title:'直播课程'},{id:4,title:'教育视频'}]},
    	{id:3,title:'研究院',src:'/ResearchInstitute',list:[{id:1,title:'市场研究',src:'/marketingResearch'},{id:2,title:'交易信号分析',src:'/tradingSignalAnalysis'},{id:3,title:'经济日历',src:'/economicCalendar'},{id:4,title:'教育视频',src:'/college'},{id:5,title:'直播课程',src:''}]},
    	{id:4,title:'活动',src:'/activity',list:[]},
		{id:5,title:'合作伙伴',src:'/partners',list:[]},
    	{id:6,title:'客户支持',src:'/openAccount',list:[{id:1,title:'开户'},{id:2,title:'入金&出金',src:'/access'}]},
    	{id:7,title:'关于我们',src:'/aboutUs',list:[{id:1,title:'公司简介'},{id:2,title:'优势介绍'},{id:3,title:'常见问题',src:'/problem'},{id:4,title:'联系我们',src:'/contactUs'}]},
    ],
	banner:[
		{id:1,text:'服务更贴心  交易更便捷 <br/> 尽在YCHpro'},
		{id:2,text:'一个平台 无限机会'},
		{id:3,text:'专业知识<br/>共享与世界领先的交易社交平台<br/>一起成长'}
	],
	bannerButton:{
		left:'立即开户',
		right:'合作伙伴注册',
		center:'查看直播'
	},
	webData:[
		{id:1,text:'500+',name:'Different Tradings'},
		{id:2,text:'0.0',name:'Point difference'},
		{id:3,text:'1000:1',name:'Leverage up'}
	],
	plateTwo:{title:'超越想象  体验非凡交易乐趣',list:[
		{id:1,text:'简单快捷的操作界面'},
		{id:2,text:'交易尽在指尖'},
		{id:3,text:'多种技术图标'},
		{id:4,text:'实现更好交易体验'},
		],more:'了解更多'},
	support:{
		title:'支持浏览器/PC客户端/手机端交易',
		windows:'Windows',
		andriod:'Andriod',
		ios:'Ios'
	},
	plateThree:{
		title:'Unlimited opportunities in the global market',
		list:[
			{id:1,text:'外汇 ',src:''},
			{id:2,text:'指数 ',src:''},
			{id:3,text:'商品 ',src:''},
			{id:4,text:'贵金属 ',src:''},
			{id:5,text:'能源 ',src:''},
			{id:6,text:'股票 ',src:''},
		]
	},
	plateFour:{
		title:'',
		list:[
			{id:1,title:'价格和执行优势',text:'提供极具竞争力且稳定、透明的低点差、隔夜利息和出色的交易执行力'},
			{id:2,title:'交易工具和平台',text:'卓越的交易平台，丰富的手机应用，优质的第三方交易工具和市场行情分析'},
			{id:3,title:'账户优势',text:'严格保护客户资金，便捷出入金，一对一的市场策略师，优质的服务'},
			{id:4,title:'500+交易产品',text:'交易外汇、贵金属、原油、全球热门股票、股指、期货等差价合约'},
		]
	},
	plateFive:{
		title:'交易无限制 解锁无限可能',
		desc:"根据您的需求和目标选择适合自己的交易账户，无论您是初学者或是经验丰富的交易员",
		subtitle:'账户如何升级',
		subDesc:'当您开立账户时，您的账户类型会根据您的初始资金而定。如要进一步了解如何升级，请联系我们的客户经理。',
		customerManager:'请联系我们的客户经理',
		list:[
			{id:1,title:'微型账户',price:'100',limitation:'最低资金',desc:'尊享领先的入门价格',list:[
				{id:1,title:'低入门价格'},
				{id:2,title:'一流线上服务与支持'},
				{id:3,title:'24/5技术和账户支持服务'},
				],
			},
			{id:2,title:'标准账户',price:'500',limitation:'最低资金',desc:'尊享更低点差和佣金',list:[
				{id:1,title:'价格比一般用户更优惠'},
				{id:2,title:'一流线上服务与支持'},
				{id:3,title:'24/5技术和账户支持服务'},
				{id:4,title:'24/5随时直接联系我们的交易专家'},
				],
			},
			{id:3,title:'VIP账户',price:'3000',limitation:'最低资金',desc:'获取VIP待遇，尊享最佳价格和服务',list:[
				{id:1,title:'价格比一般用户更优惠'},
				{id:2,title:'一流线上服务与支持'},
				{id:3,title:'24/5技术和账户支持服务'},
				{id:4,title:'24/5随时直接联系我们的交易专家'},
				{id:5,title:'YCHpro专家1对1服务'},
				],
			}
		]
	},
	plateSix:{
		title:'为什么选择YCHpro?',
		list:[
			{id:1,title:'多资产类别平台',text:'涵盖股票、外汇、大宗商品以及更多'},
			{id:2,title:'通过YCHpro增强您的交易能力',text:'YCHpro的产品开发员不仅是技术专家，他们也是身处交易中的资深交易员<br/>无论何时买入或卖出，都会准确地知道其风险所在'},
			{id:3,title:'交易信号',text:'用大数据分析以往走向，找到当下最好的交易机会'},
		]
	},
	plateSeven:{
		title:'在YCHpro，您拥有的将不仅限于一个外汇与差价合约交易账户 <br/>更多服务助力您 ',
		list:[
			{id:1,title:'最新市场研究',text:'解析国际主流市场经济的重大新闻事件，助您把握市场的每个机遇'},
			{id:2,title:'每日线上直播',text:'每天10小时交易直播，在线接收热门产品交易信号，掌握交易技巧'},
			{id:3,title:'实时查看交易信号',text:'实时交易策略助您了解最新市场走势，更好把握交易时机'},
			{id:4,title:'24/7在线客服交易知识',text:'您可以随时随地与我们取得联系，为您提供定制化行情分析推送、即时获取最新优惠活动'},
			{id:5,title:'交易知识',text:'YCHpro与您一起学习成长，提升交易技能'},
			{id:6,title:'财经日历',text:'更多服务详情请登陆客户专区查看'}
		]
	},
	plateEight:[
		{id:1,title:'专注于客户',text:'我们不在乎您的资金规模，客户至上是我们坚持的唯一准则，而并非资金、账户类型或投资规模多少。我们的所有客户都会接受同样的执行速度，同样的客户支持。YCHpro初创时坚持的价值观是不会改变的。'},
		{id:2,title:'交易商品的范围',text:'可以使用一个平台来交易多种商品，YCHpro使交易变得更简单、有效。'},
		{id:3,title:'透明与公平',text:'YCHpro 没有任何隐藏条款。就是你所见的价格、执行与优惠活动。'}
	],
	plateNine:{
		title:'YCHpro 合作伙伴计划,为全球市场领先平台推荐客户，赚取稳定收入',list:[
			{id:1,text:'我们的佣金报酬丰厚，且定期支付，极具市场竞争力'},
			],joinNow:'马上加入'
	},
	contactUs:{
		title:'联系我们',
		desc:'您可以在市场开放的任何时候与我们联系，我们每周5天，每天24小时都在这里。',
		submit:'提交',
		tips:"请输入",
		tipss:"请选择",
		list:[
			{id:1,type:'text',title:'名',value:''},
			{id:2,type:'text',title:'姓',value:''},
			{id:3,type:'text',title:'电子邮件地址',value:''},
			{id:4,type:'text',title:'确认电子邮件地址',value:''},
			{id:5,type:'select',title:'国家',value:''},
			{id:6,type:'text',title:'联系电话',value:''}
		]
	},
	footer:[
		{id:1,title:'产品',src:'/markets',list:[
			{id:1,text:'外汇'},
			{id:2,text:'指数'},
			{id:3,text:'大宗商品'},
			{id:5,text:'贵金属'}
		]},
		{id:2,title:'交易平台',src:'/tradingPlatform',list:[
			{id:1,text:'MT5'}
		]},
		{id:3,title:'交易账户',src:'/openAccount',list:[
			{id:1,text:'微型账户'},
			{id:2,text:'标准账户'},
			{id:3,text:'VIP账户'}
		]},
		{id:4,title:'客户支持',src:'/openAccount',list:[
			{id:1,text:'入金&出金',src:'/access'},
			{id:2,text:'杠杆和保证金'},
			{id:3,text:'交易时间',src:'/economicCalendar'}
		]},
		{id:5,title:'合作伙伴',src:'/partners',list:[
			{id:1,text:'介绍经纪人'}
		]},
		{id:6,title:'关于我们',src:'/aboutUs',list:[
			{id:1,text:'YCHpro介绍',src:'/aboutUs'},
			{id:2,text:'联系我们',src:'/contactUs'},
			{id:3,text:'执行政策',src:'/risk?id=4'},
			{id:4,text:'隐私政策',src:'/risk?id=2'}
		]}
	],
	marketTab:[
		{id:0,title:"外汇对的范围",desc:"*平均点差是根据历史点差的平均值计算的。点差是可变的。",list:[
			{id:1,title:'主要外汇对',list:[
				{id:0,title:'货币对',num:'平均点差*'},
				{id:1,title:'AUDUSD',num:'0.1'},
				{id:2,title:'USDCAD',num:'0.2'},
				{id:3,title:'EURUSD',num:'0.1'},
				{id:4,title:'USDJPY',num:'0.2'},
				{id:5,title:'USDCHF',num:'0.3'},
				{id:6,title:'GBPUSD',num:'0.3'}]},
			{id:2,title:'次要外汇对',list:[
				{id:0,title:'外汇对',num:''},
				{id:1,title:'AUDCAD',num:'AUDJPY'},
				{id:2,title:'USDSGD',num:'CHFJPY'},
				{id:3,title:'EURCAD',num:'EURGBP'},
				{id:4,title:'EURNZD',num:'GBPCAD'},
				{id:5,title:'GBPJPY',num:'NZDUSD'},
				{id:6,title:'AUDCHF',num:'AUDNZD'},
				{id:7,title:'CADJPY',num:'EURAUD'},
				{id:8,title:'EURCHF',num:'EURJPY'},
				{id:9,title:'GBPAUD',num:'GBPCHF'},
				{id:10,title:'GBPNZD',num:'NZDJPY'},
			]},
			{id:3,title:'异国外汇对',list:[
				{id:0,title:'外汇对',num:''},
				{id:1,title:'AUDHKD',num:'USDTRY'},
				{id:2,title:'EURMXN',num:'USDZAR'},
				{id:3,title:'EURTRY',num:'EURDKK'},
				{id:4,title:'USDCNH',num:'EURHUF'},
				{id:5,title:'USDDKK',num:'EURNOK'},
				{id:6,title:'USDHKD',num:'EURPLN'},
				{id:7,title:'USDMXN',num:'EURSEK'},
				{id:8,title:'USDNOK',num:'USDHUF'},
				{id:9,title:'USDSEK',num:'USDPLN'},
				{id:10,title:'AUDCNH',num:'CADCHF'},
				{id:11,title:'NZDCAD',num:'NZDCHF'},
				{id:12,title:'AUDSGD',num:''}
			]},
			{id:4,title:'金属对',list:[
				{id:0,title:'金属对',num:''},
				{id:1,title:'XAGUSD',num:''},
				{id:2,title:'XAUUSD',num:''},
			]}
		]},
		{id:1,title:"交易指数范围",list:[
			{id:1,title:'现金指数差价合约',desc:'以下是您可以通过我们的 MetaTrader 4 指数交易平台访问的全球指数的完整列表。请注意，所有提供的指数均以差价合约形式提供。',list:[
				{id:0,one:'产品',two:'代码',three:'每手价值',four:'保证金率',five:'货币',six:'最小手数交易',seven:'最大手数交易'},
				{id:1,one:'FTSE 100',two:'FTSE100',three:'£1 x symbol value',four:'1.00%',five:'GBP',six:'0.1',seven:'250'},
				{id:2,one:'DAX 30',two:'DAX30',three:'€1 x symbol value',four:'1.00%',five:'EUR',six:'0.1',seven:'250'},
				{id:3,one:'ESP 35',two:'ESP35',three:'€1 x symbol value',four:'2.00%',five:'EUR',six:'0.1',seven:'250'},
				{id:4,one:'CAC 40',two:'CAC40',three:'€1 x symbol value',four:'2.00%',five:'EUR',six:'0.1',seven:'250'},
				{id:5,one:'STOXX 50',two:'STOXX50',three:'€1 x symbol value',four:'1.00%',five:'EUR',six:'0.1',seven:'250'},
				{id:6,one:'WS 30',two:'WS30',three:'€1 x symbol value',four:'1.00%',five:'USD',six:'0.1',seven:'250'},
				{id:7,one:'US 500',two:'US500',three:'$10 x symbol value',four:'1.00%',five:'USD',six:'0.1',seven:'50'},
				{id:8,one:'US 2000',two:'US2000',three:'$10 x symbol value',four:'1.00%',five:'USD',six:'0.1',seven:'50'},
				{id:9,one:'NDX 100',two:'NDX100',three:'$1 x symbol value',four:'1.00%',five:'USD',six:'0.1',seven:'250'},
				{id:10,one:'ASX 200',two:'ASX200',three:'$1 x symbol value',four:'1.00%',five:'AUD',six:'0.1',seven:'250'},
				{id:11,one:'HK 50',two:'HK50',three:'HK$10 x symbol value',four:'4.00%',five:'HKD',six:'0.1',seven:'100'},
				{id:12,one:'JP 225',two:'JP225',three:'¥100 x symbol value',four:'1.00%',five:'JPY',six:'0.1',seven:'100'},
			]},
			{id:2,title:'期货指数差价合约',desc:'GO Markets将自动开仓期货差价合约的任何未平仓头寸，这将导致在开仓时支付点差（ASK的价格-BID价格）。 当与差价合约相关的基础工具到期时，GO Markets开始根据下一个可用的期货合约为差价合约定价，从而产生隔夜利息。 由于下一个已到期的期货合约以比到期的期货合约折扣或溢价的价格进行交易，您的交易帐户将被记入或借记到期合约的收盘价与新合约的开盘价之间的差额，具体取决于您的 滚动工具的净敞口。 GO Markets通常会在当前合约到期日的72个工作小时内滚动期货合约，以避免当前合约到期时流动性低和价差较大的情况。',list:[
				{id:0,one:'产品',two:'符号',three:'每手价值 ',four:'保证金率',five:'货币',six:'最小交易手数',seven:'最大交易手数',eight:'点值举例'},
				{id:1,one:'China 50',two:'CHINA 50',three:'$1x symbol value',four:'5%',five:'USD',six:'0.1',seven:'50',eight:'10055>10056'},
				{id:2,one:'US Dollar',two:'USDOLLAR',three:'$1000x symbol value',four:'1%',five:'USD',six:'0.1',seven:'50',eight:'101.305>101.315'},
			]},
		]},
		{id:2,title:"商品种类",list:[
			{id:1,title:'大宗商品列表',desc:'以下是您可以通过 MetaTrader 4 & MetaTrader 5交易平台进行交易的所有大宗商品差价合约:',list:[
				{id:0,one:'产品',two:'代码',three:'点差类型',four:'手数',five:'平台时间'},
				{id:1,one:'Spot Gold',two:'XAU/USD',three:'From 0.04',four:'1000z',five:'01:01-23:59'},
				{id:2,one:'Spot Silver',two:'XAU/USD',three:'From 0.008',four:'5.0000z',five:'01:01-23:59'},
				{id:3,one:'Copper Futures',two:'COPPER-F',three:'0.004',four:'25.000 1bs',five:'01:00-24:00'},
				{id:4,one:'Spot WTI Crude Oil',two:'USO/USD',three:'Variable',four:'100 Barrels',five:'01:00-24:00'},
				{id:5,one:'Spot Brent Crude Oil',two:'USO/USD',three:'Variable',four:'100 Barrels',five:'03:00-24:00'},
				{id:6,one:'US Oil Futures',two:'USOILE',three:'Variable',four:'100 Barrels',five:'01:00-24:00'},
				{id:7,one:'US Oil Futures',two:'UKOiLF',three:'Variable',four:'100 Barrels',five:'03:00-24:00'},
				{id:8,one:'Soybean Futures',two:'SBEAN-F',three:'Variable',four:'10,000 bushels',five:'03:00-15:45 16:30-21:20'},
				{id:9,one:'Wheat Futures',two:'WHEATF',three:'Variable',four:'10,000 bushels',five:'3:00-15:45 16:30-21:21'},
				{id:10,one:'Spot Natural Gas',two:'NGAS',three:'0.013',four:'10,000 MMBtu',five:'01:00-24:00'},
			]},

		]},
		{id:3,title:"商品种类",list:[
			{id:1,title:'贵金属列表',desc:'以下是您可以通过我们的 MetaTrader 平台交易的贵金属差价合约：',list:[
				{id:0,one:'产品',two:'代码',three:'点差类型',four:'手数',five:'平台交易时间'},
				{id:1,one:'Spot Gold',two:'XAU/USD',three:'From 0.04',four:'1000z',five:'01:01-23:59'},
				{id:2,one:'Spot Silver',two:'XAU/USD',three:'From 0.008',four:'5.0000z',five:'01:01-23:59'},
				{id:3,one:'Copper Futures',two:'COPPER-F0.004',three:'0.004',four:'25000 unit',five:'01:00-24:00'},
			]},
		]},
		{id:4,title:"商品种类",list:[
			{id:1,title:'贵金属列表',desc:'以下是您可以通过我们的 MetaTrader 平台交易的贵金属差价合约：',list:[
				{id:0,one:'产品',two:'代码',three:'点差类型',four:'手数',five:'平台交易时间'},
				{id:1,one:'Spot Gold',two:'XAU/USD',three:'From 0.04',four:'1000z',five:'01:01-23:59'},
				{id:2,one:'Spot Silver',two:'XAU/USD',three:'From 0.008',four:'5.0000z',five:'01:01-23:59'},
				{id:3,one:'Copper Futures',two:'COPPER-F0.004',three:'0.004',four:'25000 unit',five:'01:00-24:00'},
			]},
		]}
	],
	markets:[
		{id:1,title:'外汇',button:'立即开户',
		banner:{
			title:'在线外汇交易平台首选交易商',
			desc:'外汇市场是世界上最大、流动性最强的市场，日平均交易量超过8万亿美元。<br/>通过YCHpro，您不仅可以进行主流货币对，还可以获得进入全球最大市场所需的工具和支持',
			notReady:'还没准备好？'
			},
		two:{
			title:'为什么要与YCHpro交易外汇差价合约？',
			desc:'外币兑换为资深交易者和市场新手提供了大量潜在机会。如果您正在寻找一个目标丰富的活跃交易环境，那么外汇就是您的不二之选。',
			list:[
				{id:1,title:'24/5小时交易',desc:'外汇市场24小时不间断交易，而不用像股票市场一样特定交易时段的限制。'},
				{id:2,title:'即可做多也可做空',desc:'可以进行双向交易，即使在下跌的市场中，仍可通过做空来寻找获利机会。'},
				{id:3,title:'灵活的杠杆',desc:'提供灵活的杠杆，便可以建立一笔价值是百倍甚至千倍的仓位。'},
				{id:4,title:'更小的交易合约',desc:'YCHpro最低可交易0.01手，大幅降低了投资的门槛。'},
				{id:5,title:'简洁明了的平台',desc:'强大的MT5平台，集行情、交易、账户管理、资讯为一体。'},
				{id:6,title:'有竞争力的交易成本',desc:'0交易佣金，透明的低价差、低隔夜利息。'}
			]
		},
		three:{
			title:'互联网技术让来自世界各地的交易者相互之间能够彼此直接进行交易。<br/>简单地说，外汇是一个没有中间商的市场。在参与这个不可思议的、快节奏的市场前，<br/>所有您需要做的仅仅是通过一家可靠的经纪商开设一个交易账户。',
			right:{
				title:'现在开户',
				list:[
					{id:1,title:'顶级流动性和迅捷执行速度'},
					{id:2,title:'一键链接全球500多个交易品种'},
					{id:3,title:'全面账户类型，点差低至0点'},
					{id:4,title:'无论身在何处，都能无缝交易'}
				]
			}
		},
		four:{
			title:'外汇交易的好处',
			list:[
				{id:1,title:'多种外汇货币对',src:''},
				{id:2,title:'流动性高',src:''},
				{id:3,title:'灵活杠杆',src:''},
				{id:4,title:'24小时交易',src:''},
				{id:5,title:'交易成本低',src:''},
			]
		},
		six:[
			{id:1,title:'什么是外汇货币对？',desc:'在外汇市场中，交易包括两种不同货币之间的购买或出售。这些货币组合被称为货币对。汇率是货币对相互比较的相对价格。有七种主要货币对，所有货币对都包括美元。',show:false},
			{id:2,title:'如何通过外汇交易赚钱？',desc:'国际货币交易为参与者提供了无与伦比的机会。鉴于外汇的深度和流动性，几乎可以以最高效率实施任何可行的策略。从短线盘中即时交易信号到趋势波段交易计划，从周期性汇率波动中获利。<br/>  与股票等更传统的交易模块不同，外汇交易利润不仅限于低买高卖。您可以从特定市场的多头或空头中赚钱，从而成倍增加潜在交易机会的数量。<br/>  无论你对货币的展望是看涨还是看跌，你都可以通过低买高卖或高卖低买实现盈利。',show:false},
			{id:3,title:'如何计算您的外汇利润？',desc:'外汇报价一般有五位小数。其中第四位小数就是人们常说的“点”。我们常用点数来计算盈亏。<br/>  在交易平台Metatrader 5软件系统中可直观展现收益和损失。随着市场的变化，您的账户余额会通过您平台的功能自动更新。',show:false},
			{id:4,title:'您需要多少钱才能开始？',desc:'外汇交易的最大优势之一是开始所需的最少资本。YCHpro为感兴趣的个人提供了以低至100美元开始交易的机会。',show:false},
			{id:5,title:'什么因素会影响外汇价格？',desc:'长期来看，交易外汇即是交易两个不同国家间的经济状况，经济越好的国家越会吸引国际资金流入，该国货币则会升值。而在实际情况中，影响外汇价格的因素非常复杂，一般来说有以下三个重要影响因素：',
			list:[
				{id:1,title:'经济数据',desc:'一国的经济情况是通过经济数据所体现出来，因此在重要的经济数据公布时，时常会对相关的货币对产生重大影响。例如最知名的美国非农数据，由于它反映了美国的就业状况，因此对美元相关的货币对走势会产生重要影响。'},
				{id:2,title:'央行货币政策',desc:'当一国央行收紧货币政策（加息）时，会对该国货币产生正面影响。因为全球资本是逐利的，资金会自然的从低息国家的货币流向高息国家的货币，从而推动高息国际货币升值。'},
				{id:3,title:'地缘政治',desc:'地缘政治亦会对外汇市场产生重大影响，例如近期俄罗斯与乌克兰之间的战争，使得全球的避险情绪高涨，与各种因素一起推动美元指数波动巨大。'}
			],show:false}
		]
		},
		{id:2,title:'指数',button:'立即开户',
		banner:{
			title:'指数',
			desc:'指数交易可让您交易全球股票市场走势，而无需直接拥有特定股票。<br/>您只需根据指数的价格变动进行交易。',
			notReady:'还没准备好？'
			},
		two:{
			title:'为什么要与YCHpro交易指数差价合约？',
			desc:'',
			list:[
				{id:1,title:'股市敞口',desc:'是接触一个经济体或经济领域的股票市场的一种方式，即时连接全球经济，与全球证券市场接轨。'},
				{id:2,title:'经济之窗',desc:'对于差价合约交易者来说，这提供了一种简单的方式来参与到全球国家主要股票交易中，能立即接触整个行业或整个经济。'},
				{id:3,title:'简洁明了的平台',desc:'强大的MT5平台，集行情、交易、账户管理、资讯为一体。'},
				{id:4,title:'有竞争力的交易成本',desc:'0交易佣金，透明的低价差、低隔夜利息。'},
				{id:5,title:'低门槛的交易条件',desc:'最小交易手数低至0.01手，地保证金即可开仓交易。'},
				{id:6,title:'高水准的线上客服',desc:'24/5线上客服，提供优质服务。'}
			]
		},
		three:{
			title:'相比起传统的股票交易，指数交易正在变得日益流行。<br/>我们的指数差价合约专为您提供广泛的全球市场准入机会，使您能够在不相关的工具上实现交易策略的多样化，<br/>从而减少单独交易某个公司股票所带来的风险。',
			right:{
				title:'现在开户',
				list:[
					{id:1,title:'顶级流动性和迅捷执行速度'},
					{id:2,title:'一键链接全球500多个交易品种'},
					{id:3,title:'全面账户类型，点差低至0点'},
					{id:4,title:'无论身在何处，都能无缝交易'}
				]
			}
		},
		four:{
			title:'指数交易的好处',
			list:[
				{id:1,title:'以低于单独交易成本的价格形成一组股票组合数据',src:''},
				{id:2,title:'一次接触整个行业或经济体',src:''},
				{id:3,title:'对冲股票投资组合价值下跌',src:''},
			]
		},
		six:[
			{id:1,title:'什么是指数？',desc:'指数是对在交易所上市的一组股票表现的衡量标准，用于跟踪一国经济、某个行业或证券交易所的表现。由于在交易指数时没有基础实物资产可供交换，因此大多数指数交易都是使用差价合约等金融衍生品完成的。是对沖其他頭寸的一个很好方法，在杠杆下对资本要求较低。',show:false},
			{id:2,title:'如何交易指数？',desc:'在交易指数的过程中，您既可以买进（做多），也可以卖出（做空），使得指数成为一个理想的对冲工具。举个例子，假设您看好汇丰银行未来的股价，于是买进了汇丰银行的股票。同时您可以做空英国富时100指数，以抵消大盘逆势波动的影响。',show:false},
			{id:3,title:'世界上主要的指数有哪些？',desc:'第一个股票价格指数是由Charles Dow先生于1896年创立，被命名为道琼斯指数。到今天，道琼斯指数包含了30家美国最大公司的股票价格。在互联网时代到来时，曾经冒出了各种千奇百怪的指数。而成为主要指数的准则是看其所代表的公司是否具有知名度。世界上交易最多的股票指数包括道琼斯工业平均、标普500、英国富时、德国DAX、澳大利亚ASX200、纳斯达克、法国CAC和日经225指数。',show:false},

		]},
		{id:3,title:'大宗商品',button:'立即开户',
		banner:{
			title:'大宗商品交易',
			desc:'拓宽视野  把握众多交易机会',
			notReady:''
			},
		two:{
			title:'为什么要与YCHpro大宗商品差价合约？',
			desc:'',
			list:[
				{id:1,title:'超低点差',desc:'我们为您提供有竞争力的点差'},
				{id:2,title:'低保证金',desc:'只需少量入金，即可在国际市场交易能源商品'},
				{id:3,title:'先进的交易平台',desc:'我们提供广受欢迎的全新MT5平台，集行情、交易、账户管理、资讯、风险管理为一体'},
				{id:4,title:'投资组合多元化',desc:'可以进行多品种的交叉配置，降低风险'},
				{id:5,title:'24/5 全天候专属客户支持',desc:'每个客户都被分配了一个个人客户经理'},
				{id:6,title:'交易多头和空头',desc:'您可以对商品持有多头或空头头寸，因为您只交易价格变动'}
			]
		},
		three:{
			title:'大宗商品中，原石油是最受欢迎的交易市场之一，以至于许多交易者将其昵称为“黑金”。<br/>其高波动性使短期交易者在市场中能快速寻找潜在的交易机会。<br/>在YCHpro，您可以轻松地在线交易大宗商品，始终保持同样的低点差和具有竞争力的交易条件！',
			right:{
				title:'现在开户',
				list:[
					{id:1,title:'顶级流动性和迅捷执行速度'},
					{id:2,title:'一键链接全球500多个交易品种'},
					{id:3,title:'全面账户类型，点差低至0点'},
					{id:4,title:'无论身在何处，都能无缝交易'}
				]
			}
		},
		four:{
			title:'能源交易的好处',
			list:[
				{id:1,title:'在世界上最大的商品市场寻找机会',src:''},
				{id:2,title:'不可替代的全球经济的关键因素',src:''},
				{id:3,title:'高流动性市场，任何时候进出交易变得更加容易',src:''},
				{id:4,title:'地缘政治紧张局势下的抵御风险',src:''}
			]
		},
		six:[
			{id:1,title:'什么是大宗商品？',desc:'大宗商品是一种资源，而不仅是交易产品。它们可划分为两大类即是硬商品和软商品。<br/>硬商品是指可以提取或开发的东西如金属和能源；而软商品指的是自然资源，如可以种植的农产品。<br/>您可以像交易其他金融产品一样，交易大宗商品。您可以选择交易黄金，石油或铂金等硬商品；或您也可以交易小麦，玉米和大豆等期货软商品。<br/>大宗商品可以是您的投资组合多样化。',show:false},
			{id:2,title:'影响大宗商品的因素有哪些？',desc:'',list:[
				{id:1,title:'',desc:'供需：所有市场都由买卖资产的供求动态驱动。然而，在大宗商品市场，这种情况更为明显。对任何特定商品的需求大幅上升（或下降）都可能导致该商品价格的显着上涨（或下跌）。相反，商品供应动态的变化将起到相反的作用。'},
				{id:2,title:'',desc:'美元：大多数商品以美元报价和交易。这意味着美元对其他货币的价值变化会影响商品价格。一般来说（尽管这种关系可能会发生变化），美元的价值与商品的价格水平之间存在负相关关系。'},
				{id:3,title:'',desc:'全球经济状况：全球经济的健康状况会对各种商品的总体需求和供应产生重大影响，从而影响价格。'},
				{id:4,title:'',desc:'通常由于世界大事会对供应产生直接影响，因此，能源是与生俱来的波动市场。许多政治和环境因素都会影响能源价格，进而影响供求关系，包括经济增长、油气生产国的政治动荡、天气预报以及极端天气条件和政府法规。'}
			],show:false},

		]},
		{id:4,title:'股票'},
		{id:5,title:'贵金属',button:'立即开户',
		banner:{
			title:'一站式交易黄金、白银、铂金等贵金属',
			desc:'以最具竞争力的成本交易黄金白银等金属产品',
			notReady:''
			},
		two:{
			title:'为什么要与YCHpro交易贵金属差价合约？',
			desc:'',
			list:[
				{id:1,title:'超低点差',desc:'我们为您提供有竞争力的点差'},
				{id:2,title:'低保证金',desc:'只需少量入金，即可在国际市场交易黄金等贵金属。'},
				{id:3,title:'先进的交易平台',desc:'我们提供广受欢迎的全新MT5平台，集行情、交易、账户管理、资讯、风险管理为一体'},
				{id:4,title:'投资组合多元化',desc:'能源商品产品交易可以使投资组合多样化和更大的灵活性，自由交易而无需实际资产'},
				{id:5,title:'无合约到期日',desc:'无需考虑合约到期交割和展期的问题，给您更加灵活的交易体验。'},
				{id:6,title:'24/5 全天候专属客户支持',desc:'每个客户都被分配了一个个人客户经理'}
			]
		},
		three:{
			title:'投资贵金属可以帮助交易者对冲市场波动、政治不确定性和经济崩溃。<br/> 它也可以成为多元化长期投资组合的重要组成部分。<br/> 在YCHpro，您可以轻松地在线交易黄金和其他贵金属，始终保持同样的低点差和具有竞争力的交易条件！',
			right:{
				title:'现在开户',
				list:[
					{id:1,title:'顶级流动性和迅捷执行速度'},
					{id:2,title:'一键链接全球500多个交易品种'},
					{id:3,title:'全面账户类型，点差低至0点'},
					{id:4,title:'无论身在何处，都能无缝交易'}
				]
			}
		},
		four:{
			title:'贵金属交易的好处',
			list:[
				{id:1,title:'避风港',desc:'在市场动荡时期，黄金和白银被广泛认为是避风港，可以帮助您分散投资或对冲通货膨胀。',src:''},
				{id:2,title:'平衡投资组合',desc:'交易或投资金银可以使整体投资组合更加平衡。',src:''},
				{id:3,title:'内在价值',desc:'与其他投资（如金条）不同，可以持有实物',src:''},
				{id:4,title:'流动性强',desc:'具有国际流动性',src:''},
			]
		},
		six:[
			{id:1,title:'什么是贵金属？',desc:'在汇市，其包含有黄金、白银。 其中黄金是最有吸引力且成交量最高的。 但是，其它几种，尤其是白银，也显示出了非常高的波动性，由此，它们一样可以提供大量的交易机会。 从潜力方面来说，将贵金属纳入您的投资组合不仅可以增加投资的多样性，还极有可能会提高您投资组合的长期盈利能力。',show:false},
			{id:2,title:'如何交易贵金属？',desc:'令交易贵金属特别是黄金分外吸引人的因素有数种。 其中最主要的一项是金价的基本面预测可基于其与全球经济趋势间的反比关系。 如经济形势在变糟，投资商会寻求黄金做为庇护所 -- 金价随之上涨。 如全球经济都在蓬勃发展，那么乐观的情绪就会推动交易商去冒更多险 -- 金价此时通常就会下滑。 这也正是2020年疫情危机能将金价推至超过2000美元的历史高价的原因！',show:false},
			{id:3,title:'响贵金属价格的因素有哪些？',desc:'',list:[
				{id:1,title:'供需',desc:'随着需求的增加，金属价格上涨，而当需求疲软时，贵金属的价值下降。'},
				{id:2,title:'美元的价值',desc:'由于贵金属以美元计价，因此其价格通常与美元价值成反比。'},
				{id:3,title:'通货膨胀',desc:'通货膨胀直接影响贵金属的价格。通常，通货膨胀率的上升往往会提高贵金属的价格。'},
				{id:4,title:'工业需求',desc:'黄金的技术和工业用途，例如对珠宝和电子产品的需求可以使贵金属的价格上涨。'}
			],show:false},

		]},
	],
	aboutUs:{
		banner:{
			title:'为全球投资者社群赋能',
			desc:'不仅是使命，也是对于客户的承诺',
			src:require("@/assets/images/about/about01.png")
		},
		about:{
			title:'关于我们',
			desc:'YCHpro是一家全球领先金融产品在线交易提供商，是全球领先的社交投资网络之一，<br/>为交易者提供外汇、贵金属、指数等金融衍生品交易。为欧洲、亚洲、非洲、澳洲等多个国家和地区提供全天候的零售和机构服务。<br/>始终坚持以客户的需求为我们的核心服务原则，努力为客户提供最贴心的服务。'
		},
		why:{
			title:'为什么选择YCHpro交易',
			list:[
				{id:1,title:'资金安全',desc:'所有客户资金分别存放在顶级国际银行。承诺将客户资金和公司运营资金隔离存放，使客户资金始终处于保护之下。',src:require("@/assets/images/about/about02.png")},
				{id:2,title:'交易工具和平台',desc:'卓越的交易平台，丰富的手机应用，优质的第三方交易工具和市场行情分析。',src:require("@/assets/images/about/about03.png")},
				{id:3,title:'丰富交易产品',desc:'交易外汇、黄金、白银等贵金属、全球热门股票、股指等差价合约。',src:require("@/assets/images/about/about04.png")},
				{id:4,title:'深度流动性',desc:'我们收集了多家顶级银行的财务流动性，无论交易有多大都能获得最佳价格。',src:require("@/assets/images/about/about05.png")},
				{id:5,title:'优质客户服务',desc:'YCHpro致力于为所有客户提供卓越的客户服务和支持，在线客服将在周一至周五每天24小时提供帮助。',src:require("@/assets/images/about/about06.png")},
				{id:6,title:'全球中英文直播',desc:"每天10小时中英文直播，制推送行情分析、交易信号，确保您紧跟市场动态，始终不懈为您创造交易机会",src:require("@/assets/images/about/about08.png")}
			]
		},
		ourMission:{
			title:'我们的使命',
			desc:'YCHpro将始终以社交协作与投资者教育为基础，<br/>打造一种多资产投资平台，为用户赋能。'
		},
		ourVision:{
			title:'我们的愿景',
			desc:" 加入YCHpro使之成为全球投资者社群的一员，<br/>在世界领先的社交投资网络，与他人联系、分享和学习。",
			src:require("@/assets/images/about/about07.png")
		},
		ourValues:{
			title:'我们的价值观',
			desc:'YCHpro的价值观体现于推出的产品与用户体验，也根植于我们的内部文化和员工体验之中。',
			list:[
				{id:1,title:"简单便捷",desc:"我们简化交易和投资过程，平台简洁明了，让交易者便捷迅速参与交易和投资。"},
				{id:1,title:"创新进取",desc:"创立YCHpro，肩负着创新金融发展使命，我们将提供全新服务方式，适应用户不断升级的需求，已用户体验为中心的思维方式，不断努力进取。"},
				{id:1,title:"全球共享直播社群",desc:" YVHpro是一家金融服务公司，也是一个社交网络。用户参与的不仅仅是金融投资，更是在交流知识、分享经验、表达情感与思想的平台。"},
			]
		}
	},
	partner:{
		banner:{
			title:"创新 可靠 与众不同",
			subtitle:'与值得信赖的全球经纪商合作',
			src:require("@/assets/images/partners/partners01.png")
		},
		two:{
			title:"合伙经纪人",
			desc:"YCHpro能够为所有经纪商提供有竞争力的佣金、多功能直播平台、交易工具和出色的客户支持。<br/>建立您的客户群，同时与YCHpro合作，为您的客户提供最佳的交易体验。",
			subtitle:"与YCHpro合作获得的不仅仅是一个合作伙伴账户",
			subdesc:"您将获得",
			list:[
				{id:1,title:"可靠的后台支持和网络营销推广获客的技术培训",desc:"",src:require("@/assets/images/partners/partners02.png")},
				{id:2,title:"定制的佣金结构",desc:"",src:require("@/assets/images/partners/partners03.png")},
				{id:3,title:"行业领先的营销直播平台共享",desc:"",src:require("@/assets/images/partners/partners04.png")},
				{id:4,title:"业务开发培训与指导",desc:"",src:require("@/assets/images/partners/partners05.png")}
			]
		},
		three:{
			title:"享更多，赚更多。",
			desc:"选择一个公平和人性化的经纪商",
			src:"",
			subdesc:"如果您想加入我们的计划，请立即申请账户注册",
			button:"账户注册"
		},
		four:{
			title:"我们将每个客户视为首选客户<br/>始终可靠地为合作伙伴提供支持<br/>我们帮您一步步完成!",
			subtitle:"现在加入",
			contact:"我们也提供其他的定制合作伙伴方案，联系我们咨询更多详情。",
			list:[
				{id:1,title:"与可靠的外汇经纪商合作",desc:"",src:require("@/assets/images/partners/partners06.png")},
				{id:2,title:"无需投钱 即可开始 ",desc:"",src:require("@/assets/images/partners/partners07.png")},
				{id:3,title:"容易上手 无需经验 ",desc:"",src:require("@/assets/images/partners/partners08.png")},
				{id:4,title:"营销支持 技术支持 /免费的营销工具",desc:"",src:require("@/assets/images/partners/partners09.png")},
				{id:5,title:"详尽的MT5报告",desc:"",src:require("@/assets/images/partners/partners010.png")},
				{id:6,title:"具竞争力的佣金",desc:"",src:require("@/assets/images/partners/partners011.png")}
			]
		}
	},
	mt5:{
		banner:{
			title:"MetaTrader 5",
			desc:"MetaTrader 5 建立于前一代平台在金融技术领域打下的坚实基础之上.通过YCHpro，探索 MT5 最新功能与更多工具选择！",
			button:"立即开户"
		},
		introduce:{
			title:"MetaTrader 5（MT5）",
			desc:"MetaTrader 5——第五代 MetaTrader 平台，是一款强大的差价合约交易平台，支持货币对及其他金融交易品种。<br/> <br/>MetaTrader 5 在 2010 年一经金融交易软件开发公司推出后，迅速成为全球在线外汇交易者和经纪商最青睐的交易平台。",
			button:"立即下载"
		},
		main:{
			title:"MetaTrader 5 主要特性",
			list:[
				{id:1,title:"200+ 种交易品种",desc:""},
				{id:2,title:"38+预先安装技术指标",desc:""},
				{id:3,title:"8 种挂单类型",desc:""},
				{id:4,title:"21 种时间周期",desc:""},
				{id:5,title:"3 种图表类型",desc:""},
				{id:6,title:"44种分析图表工具",desc:""},
				{id:7,title:"即时执行",desc:""},
				{id:8,title:"市价执行",desc:""},
				{id:9,title:"对冲系统",desc:""},
				{id:10,title:"内置外汇日历",desc:""},
				{id:11,title:"移动止损",desc:""},
				{id:12,title:"技术面分析",desc:""},
				{id:13,title:"自定义指标",desc:""},
				{id:14,title:"智能交易（EA）",desc:""},
				{id:15,title:"易于使用的界面",desc:""},
				{id:16,title:"完全可定制的图表",desc:""}
			]
		},
		platform:{
			title:"其他设备上的MetaTrader 5",
			list:[
				{id:1,link:"https://download.mql5.com/cdn/web/14707/mt5/cwgmarketssvg5setup.exe",button:"下载Windows版本",title:"下载适用于Windows的MT5",desc:"MT 5是符合行业标准的优秀交易平台，将最新版本下载到您的桌面计算机上，立即开展交易。平台界面流畅，您可以依照个人喜好进行调整，并基于想要的方式开展交易和分析。",src:require("@/assets/images/mt5/tp03.png")},
				{id:2,link:"https://download.metatrader.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg",button:"下载MAC版本",title:"下载适用于Mac的MT5",desc:"想在Mac上运行吗？为您的设备提供强大的解决方案！只需下载适用于Mac OS X的MT5终端，立即安装并开始交易！",src:require("@/assets/images/mt5/tp04.png")},
				{id:3,link:"http://81.69.29.89:8099/",button:"跳转",title:"跳转到网络版本MT5",desc:"MT5 Webtrader是一个安全的高级平台，让您轻松管理持仓。所有YCHpro客户均可通过桌面客户端访问该平台版本，或者点击首页上的Webtrader链接以继续。",src:require("@/assets/images/mt5/tp05.png")}
			]
		},
		mobile:{
			title:"MetaTrader 5 - 移动版",
			desc:"将MT5交易平台下载到您的移动设备上，无论您身在何处，都能随时把握市场行情！<br/><br/>只需在手机屏幕上轻轻点击，就能轻松获得最新市场动向与技术分析资料。",
			list:[
				{id:1,title:"苹果应用商店",link:require("@/assets/AppStore.jpg"),desc:"",src:require("@/assets/images/mt5/tp06.png")},
				{id:2,title:"Play 商店",link:"",desc:"",src:require("@/assets/images/mt5/tp07.png")},
				{id:3,title:"安卓",desc:"",link:require("@/assets/Andriod.jpg"),src:require("@/assets/images/mt5/tp08.png")}
			]
		}
	},
	open:{
		banner:"只需选择最适合您的帐户即可。<br/>随心所欲地进行交易。",
		button:"立即开户",
		account:[
			{id:1,title:"微型账户",desc:"只需 100 美元即可交易全球最大的市场。以最小的风险掌握交易基础知识。",button:"立即开户",list:[
				{id:1,title:"交易产品",desc:"外汇，贵金属，指数"},
				{id:2,title:"最低入金",desc:"$100"},
				{id:3,title:"点差类型",desc:"浮动"},
				{id:4,title:"点差",desc:"低至 2.2"},
				{id:5,title:"最大杠杆",desc:"1000"},
				{id:6,title:"EA",desc:"是"},
				{id:7,title:"最小交易量",desc:"0.01手 (外汇) , 0.01手 (CFDs), 1手 (指数) "},
				{id:8,title:"最大交易量",desc:"50手 (外汇) , 25手 (黄金美元, 白银美元) , 150 (指数)"}
			]},
			{id:2,title:"标准账户",desc:"对于那些不是第一天交易的人，我们建议开设一个标准账户。",button:"立即开户",list:[
				{id:1,title:"交易产品",desc:"外汇，贵金属，指数，"},
				{id:2,title:"最低入金",desc:"$500"},
				{id:3,title:"点差类型",desc:"浮动"},
				{id:4,title:"点差",desc:"低至 1.0"},
				{id:5,title:"最大杠杆",desc:"400"},
				{id:6,title:"EA",desc:"是"},
				{id:7,title:"最小交易量",desc:"0.01手 (外汇) , 0.01手 (CFDs), 1手 (指数)"},
				{id:8,title:"最大交易量",desc:"50手 (外汇) , 25手 (黄金美元, 白银美元) , 150 (指数),25shou (CFDs)"}
			]},
			{id:3,title:"VIP账户",desc:"高级账户拥有超低点差，是专业交易者的交易保障。",button:"立即开户",list:[
				{id:1,title:"交易产品",desc:"外汇，贵金属，指数，CFDS，大宗商品"},
				{id:2,title:"最低入金",desc:"$3000"},
				{id:3,title:"点差类型",desc:"浮动"},
				{id:4,title:"点差",desc:"低至 0"},
				{id:5,title:"最大杠杆",desc:"400"},
				{id:6,title:"EA",desc:"是"},
				{id:7,title:"最小交易量",desc:"0.01手 (外汇) , 0.01手 (CFDs), 1手 (指数)"},
				{id:8,title:"最大交易量",desc:"50手 (外汇) , 50手 (黄金美元, 白银美元) , 150 (指数)，25手(CFDs)"}
			]}
		]
	},
	access:{
		banner:"在YCHpro，我们让您的账户快速安全地注资，<br/>值得信赖和监管,安全且受保护",
		cash:{
			title:"存款和取款",
			desc:"我们通过安全的资金账户提供广泛的出入金方式，最大限度地提高客户的便利性，随时随地使用交易平台存入和提取资金",
			disclaimer:"免责声明：YCHpro不对由于我们无法控制的原因而未能或延迟在交易账户中存入或取出资金承担任何责任或索赔。",
			list:[
				{id:1,src:require("@/assets/images/custom/access01.png"),title:"万事达卡",subtitle:"接受货币",button:"存款",desc:"澳元、美元、英镑、欧元、阿联酋迪拉姆、新加坡元、加元、瑞士法郎、港元、新西兰元",top:"瞬间处理",bot:"内部费用",num:"零"},
				{id:1,src:require("@/assets/images/custom/access02.png"),title:"签证",subtitle:"接受货币",button:"存款",desc:"澳元、美元、英镑、欧元、阿联酋迪拉姆、新加坡元、加元、瑞士法郎、港元、新西兰元",top:"瞬间处理",bot:"内部费用",num:"零"},
				{id:1,src:require("@/assets/images/custom/access03.png"),title:"斯克里尔",subtitle:"接受货币",button:"存款",desc:"澳元、美元、英镑、欧元、新西兰元、新加坡元",top:"瞬间处理",bot:"内部费用",num:"零"},
				{id:1,src:require("@/assets/images/custom/access04.png"),title:"内特勒",subtitle:"接受货币",button:"存款",desc:"澳元、美元、英镑、欧元、新加坡元",top:"瞬间处理",bot:"内部费用",num:"零"},
				{id:1,src:require("@/assets/images/custom/access05.png"),title:"银行/电汇",subtitle:"接受货币",button:"存款",desc:"澳元、美元、英镑、欧元、新西兰元",top:"1-3个工作日的处理",bot:"内部费用",num:"零"},
				{id:1,src:require("@/assets/images/custom/access06.png"),title:"法萨佩",subtitle:"接受货币",button:"存款",desc:"美元",top:"瞬间处理",bot:"内部费用",num:"零"},
			],
			button:'存款'
		},
		annotate:[
			{id:1,title:"存款手续费",desc:"YCHpro对存款或取款不收取任何手续费。但国际银行之间的转账可能会收取转账费用。客户应承担任何此类费用。<br/><br/>我们不接受任何通过第三方渠道支付的款项<br/><br/>请使用本人的账号进行付款。<br/><br/>如通过第三方付款，我们会有收取行政处理费的可能。<br/><br/>存款和取款我们不收取任何转账费用，但中介银行或电子钱包等渠道会有收取手续费的可能。"},
			{id:2,title:"取款优先顺序",desc:"取款方式将优先与您初始存款的付款方式相同。<br/><br/>当用户使用信用卡存款后，无论用户选择哪种取款方式，取款方式将优先使用客户的信用卡，直至取款金额与信用卡存款金额一致；信用卡存款限额全额退款后，剩余资金的取款将采用其他支付方式；上述两种方式的存款限额取款后，YCHpro将采用其他方式办理客户取款，如银行电汇或继续使用支付渠道向客户取款。"},
		]
	},
	faq:{
		title:"常见问题",
		desc:"我们已经涵盖了从如何开设外汇交易账户到如何使用MetaTrader交易平台，无论您是外汇交易新手，还是有几年的交易经验专业者，您都可以在这些常见问题中找到许多有用的信息。",
		list:[
			{id:1,title:"交易基础",desc:"",list:[
				{id:1,title:"什么是保证金？",desc:"保证金是您的账户中开立交易所需的金额。",show:false},
				{id:2,title:"如何计算我的外汇保证金要求？",desc:"要计算外汇保证金要求，您需要以下公式：（市场报价 * 交易量） / 杠杆 = $Margin要求。例当前EURUSD报价为1.13729，我们希望使用100：000的账户杠杆交易一个标准手（1，100）计算公式为 （1.13729 * 100，000） / 100 = -1137.30 美元 注意：如果您账户的基础货币不是美元，则可以简单地将此金额转换为相应的货币澳元、英镑、新西兰元等。",show:false},
				{id:3,title:"什么是追加保证金通知？",desc:"由于保证金本质上是开立交易所需的抵押品，如果市场走势与客户的头寸相反并且您的整体账户净值减少，那么您可能没有足够的资金来维持该头寸。 如果您的净值（余额 - 未平仓利润/亏损）低于维持未平仓头寸所需保证金的 50%，则系统将开始自动关闭最大的亏损头寸，以尝试增加您的净值。",show:false},
				{id:4,title:"追加保证金和强制平仓水平是多少？",desc:"追加保证金和强制平仓水平分别为80%和50%。",show:false},
				{id:5,title:"什么是杠杆？",desc:"您的账户杠杆是您购买大量货币的能力的乘数，并将决定您开仓需要多少保证金。例如果您的杠杆为300：1，则可以用少量保证金（150美元）控制大头寸（000，500美元）。",show:false},
				{id:6,title:"你们是否禁止任何交易风格，我至少必须持有多长时间的交易？",desc:"不，我们不禁止任何交易风格，您可以随意开仓和平仓。",show:false},
				{id:7,title:"保证金和杠杆之间有什么关系？",desc:"保证金是开立交易所需的存款金额，而杠杆是从经纪商那里借来的资本，以获得更大的交易头寸。因此，保证金外汇交易使交易者能够以相对较小的存款开立更大的头寸。重要的是要记住，杠杆交易可能存在风险，因为损失和利润都会被放大。",show:false},
				{id:8,title:"账户余额是什么？",desc:"交易时，余额是指交易者在其交易账户中拥有的金额。但是，重要的是要记住，此金额不包括交易者可能从任何未平仓头寸中获得的任何利润或损失。如果交易者有未平仓头寸，他或她的余额可能会根据交易（或部分交易）平仓后他或她的损失或利润而变化。<br/><br/>交易者的余额位于交易平台的不同部分，具体取决于交易者使用的是MT4还是MT5平台。在MT4客户端上，余额显示在“交易”选项卡下的“终端”窗口中。在MT5上，余额可以在“交易”选项卡下的“工具箱”中看到。",show:false}
			]},
			{id:2,title:"交易平台",desc:"",list:[
				{id:1,title:"如何在交易平台商打开消息功能？",desc:"登录到平台，进入“工具”栏，单击“选项”然后“服务器”，选择“启用新闻”框，点击“确定”。<br/><br/>请注意，在重大经济消息公布期间，“新闻”选项可能需要占用额外的CPU，这可能会降低平台的运行速度。",show:false},
				{id:2,title:"止损和止盈如何设定？",desc:"客户是可以自己在交易软件上设置止损和止盈的。<br/><br/>止损止盈的有效期可以在MT4或MT5软件上查看产品规格 (GTC Mode)。<br/><br/>如果您在当天设置了止损止盈，需要在第二天重新设置止损止盈。<br/><br/>止损位：也称止损水平，就是设置止损或止盈需要离当前价格的最小距离。比如1.11050买入多单欧美，止损位为40，则是指设置止损和止盈需要在1.1150上下4个基点，也就是止损最大1.11010，止盈最小1.11090.不同的平台止损水平规定是有所不同的。<br/><br/>GTC模式：是指挂单的有效时间，而不是已经交易了的订单。",show:false},
				{id:3,title:"挂单多久失效？",desc:"1.外汇及贵金属挂单设定：一直有效（直至客户取消）<br/><br/>2.期货品种与现货油类挂单设定：当日有效（不包括止损止盈）<br/><br/>指数与指数期货品种挂单设定：当日有效（包括止损止盈）",show:false},
				{id:4,title:"交易账户隔夜利息结算时间？",desc:"交易账户隔夜利息结算时间为：<br/><br/>每个交易日-GMT时间 夏令时(+3)：23:59； 冬令时(+2) 23:59<br/><br/>夏令时，平台时间上显示的是GMT+3<br/><br/>冬令时：平台时间上显示的是GMT+2",show:false},
				{id:5,title:"什么是锁仓？",desc:"锁仓是期货投资者在买卖期货合约后，当市场出现与自己操作相反的走势时，开立与原先持仓相反的新仓。如果使用锁单，则双向两个部位均会同时存在,，不会相互冲销, 一般而言使用锁单来锁定目前的损失或利润, 直至适当时机来临再做处理，又称对锁、锁单。",show:false},
				{id:6,title:"什么是爆仓？",desc:"因保证金不足而被强制平仓所导致的账户保证金归零的过程，称之“爆仓”。<br/><br/>当我们账户里除了已经使用的保证金外，剩余的钱也就是可用保证金为0的时候，就会爆仓。也就是强行平仓。通常强行平仓都从亏损最大的订单开始，直到保证金比例恢复到平台规定的比例。<br/><br/>平台设置了爆仓线，当保证金不足以支持订单时，当时持仓的订单会全部爆仓。",show:false}

			]},
			{id:3,title:"交易账户",desc:"",list:[
				{id:1,button:"开设账户",title:"如何开设真实交易账户？",desc:"点击“开设账户”，填写表格，完成后您将收到一封电子邮件，其中包含您的登录详细信息，您可以使用该详细信息登录我们的安全会员区。",show:false},
				{id:2,title:"在您那里开设真实交易账户需要多长时间？",desc:"24小时内回复所有新的交易账户查询。 ",show:false},
				{id:3,title:"我可以持有什么账户基础货币的交易资金？",desc:"可以以美元持有资金。",show:false},

			]}
		]
	},
	contact:{
		title:"联系我们",
		banner:"有问题或寻求专业帮助？<br/>联系我们专业，多语言的客服团队，为您提供24/5卓越的客服支持。",
		box:{
			title:"您的5 星级客户服务",
			desc:"有问题或寻求专业帮助？联系我们专业，多语言的客服团队，为您提供24/5卓越的客服支持。",
			tab:[
				{id:1,title:"在线咨询",desc:"",src:require("@/assets/images/custom/contactUs01.png")},
				{id:2,title:"电话回拨",desc:"",src:require("@/assets/images/custom/contactUs01.png")},
				{id:3,title:"电邮给我们/support@YCHpro.com",desc:"",src:require("@/assets/images/custom/contactUs01.png")},
				{id:4,title:"从客服随时获取帮助",desc:"",src:require("@/assets/images/custom/contactUs01.png")}
			]
		},
		form:{
			button:"提交",
			tips:"通过提交信息，我确认我同意YCHpro 条款和条件以及隐私政策，并可能就我的申请与您联系。",
			list:[
				{id:1,type:'text',title:'名字',value:'',key:'firstName'},
				{id:2,type:'text',title:'姓氏',value:'',key:'lastName'},
				{id:3,type:'text',title:'电子邮件地址',value:'',key:'email'},
				{id:4,type:'select',title:'请选择国家',value:'',key:'country'},
				{id:5,type:'text',title:'电话号码',value:'',key:'phone'},
				{id:6,type:'textarea',title:'您的留言',value:'',key:'content'},
			]
		}
	},
	activity:{
		banner:{
			title:"现在开始，YCHpro客户每笔入金均可申请10%赠金，最高可享$2000美元，为您的交易助力护航。",
			button:"立即开户",
			list:[
				{id:1,title:"高达$2000赠金",desc:""},
				{id:2,title:"每笔入金均可申请",desc:""},
				{id:3,title:"90天超长交易期",desc:""},
				{id:4,title:"EA交易可享",desc:""},
				{id:5,title:"保障低成本交易",desc:""}
			]
		},
		two:{
			title:"高达$2000赠金，每笔入金均可申请，即刻开启您的交易之旅！",
			desc:"10%赠金累积最高赠金金额为5000美金。<br/>*例：客户入金金额为$10000，入金时若申请10%赠金，则账户增加信用金$1000，需完成200标准手。",
			list:[
				{id:1,title:"申请条件",desc:"每笔入金均可申请"},
				{id:2,title:"赠金金额",desc:"入金金额的10%"},
				{id:3,title:"手数要求",desc:"赠金金额的20%"},
			],
			problemList:[
				{id:1,title:"什么是欢迎赠金？谁可以申请欢迎赠金？",desc:"欢迎赠金是YCHpro为答谢新老客户特别准备的高额赠金欢迎礼，在活动期内，YCHpro客户每笔入金均可申请10%欢迎赠金，赠金累计最高不超过2000美金。"},
				{id:2,title:"如何申请10%欢迎赠金？",desc:"申请账户只需在客户专区入金时勾选10%赠金即可申请，赠金会在一个工作日内到账入金账户。"},
				{id:3,title:"赠金有效期多久，如果分多次入金，有效期应该如何计算？",desc:"欢迎赠金有效期为首笔赠金发放后90天内，交易时间和手数从账户首笔入金的赠金到账时开始计算，逾期赠金将全部自动扣除。"},
			]
		}
	},
	college:{
		title:"通过YCHpro学院培养您的交易技巧",
		desc:"通过免费的在线课程、视频教学、市场分析师撰写的文章、研讨会等多种形式了解投资知识，掌握交易技巧。",
		list:[
			{id:1,title:"学习视频",desc:"通过我们一些列教学视频，助您快速了解交易知识。",src:require("@/assets/images/community/community02.png")},
			{id:2,title:"市场分析",desc:"每天更新影响国际主流市场经济的重大新闻事件，助您把握市场的每个机遇。",src:require("@/assets/images/community/community03.png")},
			{id:3,title:"基本面分析",desc:"了解并掌握影响金融市场的基本面因素和重要的财经数据",src:require("@/assets/images/community/community04.png")},
			{id:4,title:"技术分析",desc:"了解技术指标、K线、K线图、K线形态和形态学等技术面分析方法",src:require("@/assets/images/community/community05.png")},
			{id:5,title:"交易信号",desc:"YCHpro市场数据提供强大的见解，帮助您寻找潜在的交易机会。",src:require("@/assets/images/community/community06.png")},
			{id:6,title:"直播TV",desc:"每天全行情时段在线交易直播陪伴，帮助您实时了解行情变化趋势",src:require("@/assets/images/community/community07.png")}

		]
	},
	marketOne:{
		banner:{
			title:"市场研究",
			desc:"获取我们分析师为您精心准备的市场报告，内含各类重要财经事件的<br/>分析与见解",
			src:require("@/assets/images/market/market01.png")
		},
		listOne:[
			{id:1,title:"节约时间",desc:"研究市场的活儿我们的分析师来干 -- 您只需直接看报告",src:require("@/assets/images/market/market02.png")},
			{id:2,title:"获得专属特供信息",desc:"您会比其他人更早获得最新市场机会",src:require("@/assets/images/market/market03.png")},
			{id:3,title:"实实在在的利润",desc:"利用这些信息实现收益最大化",src:require("@/assets/images/market/market04.png")}
		],
		listTwo:[
			{id:1,time:"2023 05.31",title:"【本周焦点】美国股市下跌，因债务上限僵局继续令投资者承压，新西兰联储今天利率决定",src:require("@/assets/images/market/market05.png"),
			desc:"美国主要股指周二走低，在整个交易日延续下行，因白宫与共和党谈判代表之间的进一步谈判进展甚微后，债务限额谈判进一步延伸到边缘。纳斯达克指数下跌160点1.26%，因避险基调和经济状况收紧尤其打击了成长型股票。<br/><br/>"+
			"来自初值PMI的积极经济释放（尽管不是顶级）和新屋销售的上升坚定地处于债务上限大象的背景下，这似乎将成为风险情绪的主要驱动力，直到达成协议，美国政治双方似乎在这方面相去甚远， 至少在短期内，风险情绪看起来不稳定。<br/><br/>"+
			"<b>外汇市场</b><br/><br/>美元周二上涨，美国股市避险基调帮助美元指数触及103.65的高点，以测试上周高点的阻力位。盘中收益率走低，削减了早些时候的强势，这确实限制了涨幅，缺乏美联储发言人也未能提供任何其他催化剂。<br/><br/>"+
			"日元成功逆势而上，兑美元小幅上涨。美元兑日元在盘中早盘上涨至138.91日元，然后触及阻力位，随着风险基调恶化，日元作为避风港获得支撑，这也得益于美国国债收益率在整个美国下午走低。<br/><br/>"+
			"纽元和澳元因风险基调而疲软，澳元兑美元从高点0.6600报0.6661，因风险情绪仍然悲观，铁矿石和铜价疲软也阻碍了澳元。周三的一个关键风险是新西兰联储的利率决定，预计该行将再加息25个基点，但期货定价加息50个基点的可能性很大，纽元应该会看到一些波动。<br/><br/>"+
			"在今天的经济公告中，主要的风险事件将是澳大利亚东部标准时间下午12：00的新西兰联储利率公告。这将是一个有趣的决定，市场完全定价加息25个基点，在加息40个基点的基础上加息约50%。（期货市场定价35个基点）最近的新西兰预算宣布了大量支出措施并被视为通货膨胀，这肯定会让新西兰联储继续推进其利率紧缩周期，问题是，他们会以另一个超大的 50 让市场感到惊讶还是满足于 25，无论哪种方式，随着市场重新定价，纽元此时肯定会出现波动， 值得关注的一个图表是澳元纽元，当前定价使其处于历史买入区域，“鸽派”25应该会看到一个良好的均值回归交易机会。<br/><br/>"},
			{id:2,time:"2023 05.31",title:"随着美国通胀压力的上升，大宗商品货币下跌;澳洲联储会议",src:require("@/assets/images/market/market06.png"),
			desc:"随着市场越来越厌恶风险，大宗商品货币仍面临压力。美国股市周五大幅下跌，标准普尔500指数下跌3.6%，陷入困境的纳斯达克指数下跌4.2%。纳斯达克指数今年迄今下跌了21%，是一年来最糟糕的开局。<br/><br/>"+
			"美国股市周五受到打击，因为衡量美国劳动力成本的最全面指标就业成本指数（ECI）在第一季度创下了创纪录的飞跃（数据可以追溯到1年），超过了市场预期。该报告证实了其他工资增长指标，例如亚特兰大联储工资增长跟踪器，并引发了对工资/价格螺旋式上升的担忧。<br/><br/>"+
			"更高的工资也是劳动力市场紧张的明确标志。 请记住，美联储主席鲍威尔曾表示，美国劳动力市场“历史上极度紧张”和“波动性高”。这样的数据只是证实了这一想法，并使美联储更有可能认为它可以在不危及其“最大就业”承诺的情况下进一步加息。<br/><br/>"+
			"结果是，市场现在在今年剩余时间内贴现50个基点的加息。这就是在3月会议前将联邦基金利率提高到0.3%-25.75%的必要条件。市场甚至定价在本次会议（9%）或14月会议（<>%）上加息<>个基点的可能性很小。<br/><br/>"+
			"重新定价的联邦基金利率也推高了债券收益率。请注意，较高的AU，CA和NZ债券收益率对这些货币没有任何帮助。或者至少，尽管债券收益率较高，但它们还是下跌了。<br/><br/>"+
			"重新定价的联邦基金利率也推高了债券收益率。请注意，较高的AU，CA和NZ债券收益率对这些货币没有任何帮助。或者至少，尽管债券收益率较高，但它们还是下跌了。<br/><br/>"+
			"周五的欧元区整体通胀符合预期，但核心利率高于预期（见上表）。欧洲央行的目标是整体通胀，但更高的核心数据仍然令人担忧，因为它表明通胀压力不仅仅是由于能源价格上涨。欧洲央行首席经济学家莱恩周五表示，问题不再是加息，而是“利率正常化的规模和时机”。他补充说，欧元下跌将是决定其预测的“重要因素”。莱恩通常是管理委员会中较为温和的成员之一，所以如果他转向这种观点，那么它至少已经成为主流。<br/><br/>"+
			"重要的是，莱恩没有反对市场目前的定价。市场目前预计欧洲央行今年的存款利率约为1个百分点，即四次加息25个基点。<br/><br/>"},
			{id:3,time:"2023 05.31",title:"美元周三创下两个月新高，限制了美国债务违约风险迫在眉睫推动的黄金避险买盘",src:require("@/assets/images/market/market07.png"),
			desc:"随着美国经济数据显示出韧性并给美元带来优势，市场对美联储将很快开始降息的预期减弱。一直有一种观点认为，如果美联储像市场最近预期的那样降息，以及欧洲继续加息，美元可能会失去其收益率优势，现在对全球利率前景的看法有了一些改变。<br/><br/>"+
			"5月政策会议记录公布后，市场对美联储将在6月的下次会议上加息的押注略有上升。美联储官员5月“普遍同意”进一步加息的必要性“已经变得不太确定”，但其他官员警告说，鉴于通胀持续的风险，美联储有必要保持选择余地。<br/><br/>"+
			"根据芝商所的FedWatch工具，联邦基金利率期货走势显示，在6月14日为期两天的政策会议结束时，美联储加息的概率为35.3%。追踪美元兑六种主要货币汇率的美元指数早些时候达到103.91，是3月20日以来最高。该指数尾盘上涨0.319%，报103.86。<br/><br/>"+
			"Bannockburn Global Forex驻纽约的首席市场策略师Marc Chandler表示，他怀疑债务上限谈判已经成为影响外汇市场走势的一个重要因素。他说，“在强于预期的数据和美国利率上升的帮助下，美元已经或多或少地上涨了三周，”Chandler说，经济数据可能继续支撑美元，亚特兰大联邦储备银行预计美国经济第二季度环比增长年率为2.9%。英镑兑美元盘中跌至五周低点1.2358美元，尾盘下跌0.42%，此前数据显示英国通胀放缓幅度远低于市场预期。<br/><br/>"+
			"英镑兑欧元也出现下跌，尾盘下跌0.26%，报1.1495欧元。周二报告的欧元区核心服务业通胀率仍然高企，打击了瑞典克朗，欧洲央行势将在6月和7月加息。瑞典克朗兑欧元盘中触及11.544克朗，是2009年3月以来最低水平。<br/><br/>"},
			{id:4,time:"2023 05.31",title:"美国CPI在即，美元货币对待突破",src:require("@/assets/images/market/market08.png"),
			desc:"美元在进入震荡趋势接近一个月后，视乎还没有任何形成明显趋势的表现，在上周美联储利率和货币政策的公布后没有给出任何明确的表决，因此市场也没有太大的波动。依据美联储鲍威尔在事后的新闻发布会内容来看，市场很大概率会通过本周美国CPI的数据来作为预测美联储货币政策的方向。<br/><br/>"+
			"因此美元本周很大概率会在CPI公布后引来一定的波动。如果CPI数据没有明显下跌或甚至出现上升，这很大可能让美元出现一定的反弹；反之，CPI数据的下滑或会给美元带来一定的压力，有望突破近期的支撑点。<br/><br/>"}
		]
	},
	marketTwo:{
		banner:{
			title:"交易信号分析",
			desc:"专业交易团队提供每日交易信号分析，寻找理想的交易机会。",
			src:require("@/assets/images/market/marketTwo01.png")
		},
		list:[
			{id:1,title:"美元指数日图：",src:require("@/assets/images/market/marketTwo02.png"),
			desc:"100.900 – 101.500 可视为目前的主要支撑区域，上方压力关键位落在 102.450，有效的突破这两个关键位或引来较为明显的美元走向，交易员可多关注在CPI指数之后的价格走势能否突破这些关键位。<br/><br/>欧元兑美元在过去一个月基本处在了近1.1000 整数位的震荡区间内，汇价能否突破 该价位是该货币对接下来走势的关键。以目前的走势来看，欧元兑美元处在震荡区间和小型的上升频道的支撑位上，以日内交易来说目前可视为日内交易做多的价位。风险偏好较高的交易员，则可以适当的止损来持仓至CPI数据公布之后。"},
			{id:2,title:"欧元兑美元：",src:require("@/assets/images/market/marketTwo03.png"),
			desc:"欧元兑美元在过去一个月基本处在了近1.1000 整数位的震荡区间内，汇价能否突破 该价位是该货币对接下来走势的关键。以目前的走势来看，欧元兑美元处在震荡区间和小型的上升频道的支撑位上，以日内交易来说目前可视为日内交易做多的价位。风险偏好较高的交易员，则可以适当的止损来持仓至CPI数据公布之后。<br/><br/>"+
			"另外，交易员可等待CPI数据公布后，美元走势形成较明显的走势后才寻找入场机会。<br/><br/>"+
			"如果CPI指数利空美元，交易员可等待价格是否能够稳定运行于 1.1000 的上方后可入场做多；<br/><br/>"+
			"相反，如果在利多美元的情况下，欧元兑美元或形成趋势反转下跌，交易员可在有效突破1.09500 的关键支撑位后，做空欧元兑美元，下一个目标关键位可看在1.0800 – 1.07800 一带。<br/><br/>"},
			{id:3,title:"美元指数技术分析：",src:require("@/assets/images/market/marketTwo03.png"),
			desc:"美指周三上涨在103.95之下遇阻，下跌在103.30之上受到支持，意味着美元短线下跌后有可能保持上涨的走势。如果美指今天下跌在103.70之上受到支持，后市上涨的目标将会指向104.10--104.30之间。今天美指短线阻力在104.05--104.10，短线重要阻力在104.25--104.30。今天美指短线支持在103.70--103.75，短线重要支持在103.50--103.55。<br/><br/>"},
			{id:4,title:"欧元/美元技术分析：",src:require("@/assets/images/market/marketTwo03.png"),
			desc:"欧美周三下跌在1.0745之上受到支持，上涨在1.0805之下遇阻，意味着欧美短线上涨后有可能保持下跌的走势。如果欧美今天上涨在1.0770之下遇阻，后市下跌的目标将会指向1.0730--1.0710之间。今天欧美短线阻力在1.0770--1.0775，短线重要阻力在1.0780--1.0785。今天欧美短线支持在1.0730--1.0735，短线重要支持在1.0710--1.0715。<br/><br/>"},
			{id:5,title:"黄金技术分析：",src:require("@/assets/images/market/marketTwo03.png"),
			desc:"黄金周三下跌在1956.00之上受到支持，上涨在1985.00之下遇阻，意味着黄金短线上涨后有可能保持下跌的走势。如果黄金今天上涨在1968.00之下遇阻，后市下跌的目标将会指向1949.00--1938.00之间。今天黄金短线阻力在1967.00--1968.00，短线重要阻力1977.00--1978.00。今天黄金短线支持在1949.00--1950.00，短线重要支持在1938.00--1939.00。<br/><br/>"}
		]
	},
	marketThree:{
		banner:{
			title:"经济日历",
			desc:"如果你真的想成为一名交易者，交易计划将是你策略的重要组成部分。在我们的工具中找到经济日历，根据即将发布的经济报告、之前的经济事件、共识预测和估计波动率，计划您的交易，精确到分钟。",
			button:"立即交易"
		}
	}
}
