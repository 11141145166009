<template>
	<div id="tradingSignalAnalysisDetails">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="teacherBanner" v-if="info.detail.block1">
			<img class="bannerImg" :src="info.detail.block1.img"/>
			<div class="teacherBannerTitle" v-html="info.detail.block1.title"></div>
		</div>
		<div class="teacherCon">
			<div class="teacherDesc">
				<div class="teacherDescLeft ql-editor">
					<!-- <div v-html="info.title"></div> -->
					<!-- <div v-html="info.desc"></div> -->
					<!-- <div class="ql-container ql-snow"></div> -->
					<div v-html="info.detail.content"></div>
				</div>
				<div class="teacherDescRight">
					<img class="teacherImg" :src="info.img"/>
					<div class="imgBox">
            <template v-for="(sitem,sindex) in info.detail.block2" >
              <img :key="sindex" @click="imgJump(sitem)" :src="sitem.img"/> <br>
            </template>
					</div>
				</div>
			</div>
			<div class="videoBox">
				<div class="videoList" v-for="(item,index) in info.detail.block3" :key="index">
					<video class="videoBox" controls :src="item.video" :poster="item.img"></video>
				</div>

			</div>
			<div class="marketListTwo" v-if="newsList.length>0">
				<div class="marketTitle">{{language == 1?'市场研究':'MarketingResearch'}}</div>
				<router-link :to="'/marketingResearchDetails?id='+item.id" class="samemarketList"
				v-for="(item,index) in newsList" :key="index">
					<img :src="item.cover"/>
					<div class="samemarketListRight">
						<div class="newsTitle" v-html="item.title"></div>
						<div class="newtime">{{item.author?item.author+'-':''}}{{item.newTime}}</div>
						<div class="newsDesc" v-html="item.remark"></div>
						<div class="imgBox">
							<img v-for="(sitem,sindex) in item.iconList" :key="sindex" :src="sitem"/>
						</div>
					</div>
				</router-link>
				<div class="pageBox discen" v-if="totalPage>0">
					<div class="pageButton discen" :class="page==1?'pageButtonPrev':''" @click="prevPage">{{$t('element.prevPage')}}</div>
					<div class="pageNum discen" v-for="(item,index) in totalPage" :key="index"
					:class="(index+1 == page)?'pageNumCun':''" @click="currenPage(index+1)">{{index+1}}</div>
					<div class="pageButton discen" @click="nextPage" :class="page==totalPage?'pageButtonPrev':''">{{$t('element.nextPage')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	export default {
		name: 'tradingSignalAnalysisDetails',
		data() {
			return {
				index:0,
				info:{},
				newsList:[],
				language:1,
				page:1,
				totalPage:1
			}
		},
		mounted() {

		},
		created() {

			this.info = JSON.parse(localStorage.getItem('teacherDetails'));
			this.language = localStorage.getItem('lang') == 'zh'?1:2;
			console.log(this.info);
			this.getNewsList();
		},

		methods: {
			imgJump(item){
				if(item.link){
					window.open(item.link,'_blank')
				}
			},
			getNewsListhh(){
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				this.$http.get(this.$apiBaseUrl+'newsList?lang='+lang+'&pageSize=10'+'&pageNum='+this.page+'&module=news,signal_analysis').then((res)=>{
					// console.log('res',res)
					this.totalPage = Math.ceil(res.total/10);
					 res.rows.forEach((item,index)=>{
					 	item['newTime'] = this.formatPast(item.createTime);
						if(item.icons){
							item['iconList'] = (item.icons).split(',');
						}else{
							item['iconList'] = [];
						}
					 })
					this.newsList = res.rows;
				}).catch((err)=>{
				   console.log(err)
				   loading.close();
				})
			},
			getNewsList(){
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				this.$http.get(this.$apiBaseUrl+'newsList?lang='+lang+'&pageSize=10'+'&pageNum='+this.page+'&teacherName='+this.info.title+'&module=news,signal_analysis').then((res)=>{
					 this.totalPage = Math.ceil(res.total/10);
					 res.rows.forEach((item,index)=>{
					 	item['newTime'] = this.formatPast(item.createTime);
						if(item.icons){
							item['iconList'] = (item.icons).split(',');
						}else{
							item['iconList'] = [];
						}
					 })
					this.newsList = res.rows;
				}).catch((err)=>{
				   console.log(err)
				   loading.close();
				})
			},
			// 上一页
			prevPage(){
				if(this.page>1){
					this.page = this.page - 1;
					this.getNewsList();
				}
			},
			// 下一页
			nextPage(){
				if(this.page < this.totalPage){
					this.page = this.page + 1;
					this.getNewsList();
				}
			},
			// 当前页
			currenPage(page){
				this.page = page;
				this.getNewsList();
			},
			formatPast(date, type = "default", zeroFillFlag = true){
				// 定义countTime变量，用于存储计算后的数据
				let countTime;
				// 获取当前时间戳
				let time = new Date().getTime();
				// 转换传入参数为时间戳
				let afferentTime = new Date(date).getTime();
				// 当前时间戳 - 传入时间戳
				time = Number.parseInt(`${time - afferentTime}`);
				if (time < 10000) {
				    // 10秒内
				    return "刚刚";
				} else if (time < 60000) {
				    // 超过10秒少于1分钟内
				    countTime = Math.floor(time / 1000);
				    return `${countTime}秒前`;
				} else if (time < 3600000) {
				    // 超过1分钟少于1小时
				    countTime = Math.floor(time / 60000);
				    return `${countTime}分钟前`;
				} else if (time < 86400000) {
				    // 超过1小时少于24小时
				    countTime = Math.floor(time / 3600000);
				    return `${countTime}小时前`;
				} else if (time >= 86400000 && type == "default") {
				    // 超过二十四小时（一天）且格式参数为默认"default"
				    countTime = Math.floor(time / 86400000);
				    //大于等于365天
				    if (countTime >= 365) {
				        return `${Math.floor(countTime / 365)}年前`;
				    }
				    //大于等于30天
				    if (countTime >= 30) {
				        return `${Math.floor(countTime / 30)}个月前`;
				    }
				    return `${countTime}天前`;
				} else {
				    // 一天（24小时）以上且格式不为"default"则按传入格式参数显示不同格式
				    // 数字补零
				    let Y = new Date(date).getFullYear();
				    let M = new Date(date).getMonth() + 1;
				    let zeroFillM = M > 9 ? M : "0" + M;
				    let D = new Date(date).getDate();
				    let zeroFillD = D > 9 ? D : "0" + D;
				    // 传入格式为"-" "/" "."
				    if (type == "-" || type == "/" || type == ".") {
				        return zeroFillFlag
				            ? Y + type + zeroFillM + type + zeroFillD
				            : Y + type + M + type + D;
				    }
				    // 传入格式为"年月日"
				    if (type == "年月日") {
				        return zeroFillFlag
				            ? Y + type[0] + zeroFillM + type[1] + zeroFillD + type[2]
				            : Y + type[0] + M + type[1] + D + type[2];
				    }
				    // 传入格式为"月日"
				    if (type == "月日") {
				        return zeroFillFlag
				            ? zeroFillM + type[0] + zeroFillD + type[1]
				            : M + type[0] + D + type[1]
				    }
				    // 传入格式为"年"
				    if (type == "年") {
				        return Y + type
				    }

				}
			},

		}
	}
</script>
<style lang="scss" scoped>
	.teacherBanner{
		width: 100%;
		height: auto;
		position: relative;
		color: #FFFFFF;
		.bannerImg{
			display: block;
			width: 100%;
			height: auto;
		}
		.teacherBannerTitle{
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding:0 1rem;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			font-size: 0.44rem;
		}
	}

	.teacherCon{
		width: 100%;
		height: auto;
		padding: 1rem 3.5rem 0.5rem;
		.teacherDesc{
			width: 100%;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.teacherDescLeft{
				flex: 1;
				height: auto;
				font-size: 0.2rem;
				color: #202020;
				line-height: 0.35rem;
			}
			.teacherDescRight{
				width: 3.5rem;
				margin-left: 0.5rem;
				height: auto;
				.teacherImg{
					width: 100%;
					height: auto;
					display: block;
				}
				.imgBox{
					width: 100%;
					height: auto;
					//display: flex;
					align-items: center;
					margin-top:0.3rem;
					>img{
						display: block;
						width: auto;
						height: 0.5rem;
						margin-right: 0.1rem;
					}
				}
			}
		}
		.videoBox{
			width: 100%;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			margin-top: 1rem;
			.videoList{
				width: 30%;
				height: auto;
				margin: 0 0 0.5rem 2.5%;
				.videoBox{
					width: 100%;
					height: 4rem;
				}
			}
		}
		.marketListTwo{
			width: 100%;
			height: auto;
			padding: 1rem 0;
			.marketTitle{
				width: 100%;
				height: auto;
				font-size: 0.32rem;
				font-weight: 700;
				color: #202020;
				text-align: center;
				margin-bottom: 0.5rem;
			}
			.samemarketList{
				width: 100%;
				height: auto;
				// margin-bottom: 0.64rem;
				margin-bottom: 0.5rem;
				display: flex;
				>img{
					width: 2.66rem;
					height: 1.66rem;
					margin-right: 0.24rem;
				}
				.samemarketListRight{
					flex: 1;
					// height: 1.5rem;
					height: auto;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					font-size: 0.16rem;
					color: #7F8284;
					.newtime{
						width: 100%;
						height: 0.4rem;
						line-height: 0.4rem;
						font-size: 0.18rem;
						color: #7F8284;
					}
					.newsTitle{
						width: 100%;
						height:auto;
						font-size: 0.2rem;
						color: #29261C;
						font-weight: 700;
						// line-height: 0.3rem;
						// margin-bottom: 0.16rem;
						white-space: normal;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
					}
					.newsDesc{
						width: 100%;
						height: 0.5rem;
						font-size: 0.16rem;
						color: #7F8284;
						line-height: 0.25rem;
						white-space: normal;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					.imgBox{
						width: 100%;
						height: auto;
						display: flex;
						align-items: center;
						margin-top:0.1rem;
						>img{
							display: block;
							width: auto;
							height: 0.3rem;
							margin-right: 0.1rem;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width:1080px){
		.teacherBanner{
			font-size: 0.36rem;
			.bannerImg{
				display: block;
				width: 100%;
				height: 3.5rem;
			}
		}
		.teacherCon{
			padding: 0.5rem 5% 0.5rem;
			.teacherDesc{
				.teacherDescLeft{
					font-size: 0.24rem;
					line-height: 0.5rem;
				}
				.teacherDescRight{
					width: 100%;
					margin-left: 0;
					margin-top: 0.5rem;
				}
			}
			.videoBox{
				.videoList{
					width: 100%;
					margin: 0 0 0.5rem 0;
				}
			}
			.marketListTwo{
				.samemarketList{
					.samemarketListRight{
						flex: 1;
						height: 1.6rem;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						font-size: 0.16rem;
						color: #7F8284;
						.title{
							width: 100%;
							height: 1.2rem;
							font-size: 0.22rem;
							color: #202020;
							line-height: 0.3rem;
							white-space: normal;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 4;
							overflow: hidden;
						}
					}
				}
			}
		}


	}
</style>
