<template>
	<div id="footer" class="footer">
		<div class="footerBox">
			<div class="sameFooter" v-for="(item,index) in $t('footer')" :key="index">
				<router-link :to="item.src || ''" class="sameFooterTitle">{{item.title}}</router-link>
				<router-link :to="index == 0?(item.src+'?id='+sitem.id+'&navIndex='+(sindex == 3?4:sindex)):(sitem.src?sitem.src:(item.src || ''))" class="sameFooterdesc"
				v-for="(sitem,sindex) in item.list" :key="sindex">{{sitem.text}}</router-link>
			</div>
		</div>
		<div class="footerLast discen">Copyright © {{year}} All rights reserved</div>
		<div class="riskFooter">
			<div class="samerisk" v-if="language == 1">
        YCHpro Securities Co Ltd 是一家全球商业公司（GBC：199660），受毛里求斯金融服务委员会（FSC）批准和监管，从事全球投资交易商服务（全服务经纪商，不包括承销），许可证编号：GB23202152。
        <br> 办公地址： <br/>   路易港轩尼诗大厦 8 楼 803 室，轩尼诗教皇街。<br><br>
        风险警告： <br/>   外汇和差价合约交易涉及您投资资本的风险。请确保您已阅读并完全理解我们的
				<router-link to="/risk?id=1" class="footerRiskDiv">风险披露</router-link>。
				<br/><br/>
        YCHpro的网站和服务严禁提供给任何国家的法律法规禁止使用的用户。因此，如果您选择从该国家访问我们的网站，无论该国家是否禁止使用我们的网站，您都有责任确保在使用我们的网站时遵守任何当地法律或法规。YCHpro 不保证我们网站上的信息适合在所有司法管辖区使用。
<!--				<br/><br/>-->
<!--				受限地区： YCHpro无法为特定国家/地区的居民提供服务，例如： 美国、伊朗和朝鲜。-->
			</div>
			<div class="samerisk" v-else>
        YCHpro Securities Co Ltd is a Global Business Company (GBC: 199660) authorised and regulated by the Financial Services Commission (FSC) of Mauritius to carry out Global Investment Dealer Services (Full Service Dealer, excluding Underwriting) under Licence No. GB23202152.
        <br>Office address: <br/>   Suite 803, 8' Floor, Hennessy Tower, Pope Hennessy Street, Port Louis, Mauritius<br><br>
        Risk Warning:<br/>
        Trading in foreign exchange and CFDs involves risk to your invested capital. Please ensure that you have read and fully understand our
				<router-link to="/risk?id=1" class="footerRiskDiv">Risk Disclosure</router-link>.
				<br/><br/>
        YCHpro's website and services are strictly prohibited from being made available to users in any country where such use is prohibited by law or regulation. Therefore, if you choose to access our website from that country, it is your responsibility to ensure that you comply with any local laws or regulations when using our website, regardless of whether or not the use of our website is prohibited in that country. ychpro does not warrant that the information on our website is appropriate for use in all jurisdictions.
<!--        -->
<!--				YCHpro's website and services are strictly prohibited from being made available to any individual whose use is prohibited by national laws and regulations. Therefore, if you choose to access our website from a country, it is your responsibility to ensure that you comply with local laws or regulations when using the Website, regardless of whether the use of the Website is prohibited in that country. YCHpro does not warrant that the information on this website is applicable in all jurisdictions.-->
<!--				<br/><br/>-->
<!--				Restricted Areas： YCHpro cannot provide services to residents of certain countries, such as the United States, Iran and North Korea.-->
			</div>
		</div>
		<div class="footerRisk" v-if="footerRisk">
			<img src="@/assets/images/img/dangerous.png"/>
			<router-link to="/risk" class="footerRiskDiv">
				<span>{{ $t('risk.subDesc') }}</span>
				{{$t('risk.subTitle')}}
			</router-link>
			<img @click="footerRisk = !footerRisk" src="@/assets/images/img/closegary.png"/>
		</div>
		<div class="cookie" v-if="ifcookie">
			<div>{{ $t('element.cookie') }}</div>
			<img @click="ifcookieFun" src="@/assets/images/img/closegary.png"/>
		</div>
	</div>
</template>

<script>
	export default {
	  name:'footere',
	  components: {

	  },
	  data() {
	  	return{
			footerRisk:false,
			language:localStorage.getItem('lang') == 'zh'?1:2,
			ifcookie:false,
			year:2024

		}
	  },
	  mounted() {
	      // console.log('mounted')

	  },
	  created() {
			console.log(typeof(localStorage.getItem('ifcookie')))
			this.ifcookie = (localStorage.getItem('ifcookie') == 'false'||localStorage.getItem('ifcookie') == false)?false:true;
			console.log(this.ifcookie)
			this.language = localStorage.getItem('lang') == 'zh'?1:2;
			this.year = new Date().getFullYear();
	  },
	  methods:{
		ifcookieFun(){
			this.ifcookie = false;
			localStorage.setItem('ifcookie',false);
		}
	  }
	}
</script>

<style lang="scss" scoped>
	.cookie{
		width: 100%;
		height: 0.8rem;
		background-color: #FFFFFF;
		padding: 0 0.3rem;
		position: fixed;
		left: 0;
		bottom:0;
		z-index: 1111;
		font-size: 0.18rem;
		color: #202020;
		display: flex;
		justify-content: center;
		align-items: center;
		>div{
			max-width: 70%;
		}
		>img{
			width: 0.25rem;
			height: 0.25rem;
			margin-left: 0.5rem;
		}
	}
	.riskFooter{
		width:12rem;
		height: auto;
		border-top: 1px solid #ffffff;
		margin: 0.5rem auto 0;
		font-size: 0.16rem;
		color: #ffffff;
		padding: 0.3rem 0.1rem;
		.samerisk{
			line-height: 0.25rem;
			>span{
				color: #D51820;
			}
			>a{
				color: #D51820;
			}
		}
	}
	.footerRisk{
		width: 100%;
		height: 0.7rem;
		padding:0 0.5rem;
		background-color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.14rem;
		color: #202020;
		position: fixed;
		left:0;
		bottom:0;
		z-index:11;
		.footerRiskDiv{
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 80%;
			line-height: 0.35rem;
			cursor: pointer;
			>span{
				color: #D51820;
			}
		}
		>img:first-child{
			width: 0.22rem;
			height: 0.22rem;
			margin-right: 0.1rem;
		}
		>img:last-child{
			width: 0.14rem;
			height: 0.14rem;
			margin-left: 1rem;
		}
	}
	.footer{
		width: 100%;
		min-height: 4.5rem;
		height: auto;
		background-color: #0D0A0A;
		padding: 0.8rem 0;
	}
	.footerLast{
		width: 100%;
		height: auto;
		font-size: 0.14rem;
		color: #CCCCCC;
		margin-top: 0.4rem;
	}
	.footerBox{
		width: 12rem;
		padding:0 0.2rem;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin:0 auto;
		.sameFooter{
			min-width: 1.7rem;
			width: auto;
			height: auto;
			padding-right: 0.5rem;
			.sameFooterTitle{
				display: block;
				width: 100%;
				height: auto;
				font-size: 0.16rem;
				color: #FFFFFF;
				margin-bottom: 0.2rem;
				white-space: nowrap;
			}
			.sameFooterdesc{
				display: block;
				width: 100%;
				height: auto;
				font-size: 0.16rem;
				color: #CCCCCC;
				margin-bottom: 0.14rem;
			}
		}
	}
	@media only screen and (max-width:1080px){
		.riskFooter{
			width:90%;
			.samerisk{
				line-height: 0.4rem;

			}
		}
		.footerBox{
			width: 90%;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 auto;
			.sameFooter{
				min-width: 48%;
				width: 48%;
				height: auto;
				padding-right: 0;
				margin-bottom: 0.5rem;
				.sameFooterTitle{
					font-weight: 700;
				}
			}
		}
		.footerRisk{
			width: 100%;
			height: auto;
			padding:0.2rem 0.5rem;
			.footerRiskDiv{
				display: flex;
				align-items: center;
				justify-content: center;
				max-width: 90%;
				line-height: 0.3rem;
				cursor: pointer;
				>span{
					color: #D51820;
					width: auto;
					display: inline-block;
					white-space: nowrap;
				}
			}
			>img:last-child{
				margin-left: 0.5rem;
			}
		}
	}
</style>
