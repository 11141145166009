<template>
	<div id="ResearchInstitute">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner  :style="{backgroundImage:'url('+$t('marketOne.banner.src')+')'}"-->
		<div class="bannerOuter" v-if="mainJson.block1" :style="{backgroundImage:'url('+mainJson.block1.img+')'}">
			<div class="bannerBox">
				<div class="bannerTitle" v-html="mainJson.block1.title"></div>
				<div class="bannerDesc" v-html="mainJson.block1.desc"></div>
				<router-link class="botton discen" to="/contactUs">{{ $t('contactUs.title') }}</router-link>
			</div>
		</div>
		<!-- <div id="newQuotesWidgetChart"></div>
		<div id="newQuotesWidgetOverview"></div> -->
		<div class="twoBox">	
			<!-- <div class="researchInstituteTitle discen">Unlimited opportunities in the global market</div> -->
			<div class="twoBoxCon">
				<!-- <div class="twoBoxConLeft">
					<div class="twoTab">
						<div class="sameTab" v-for="(item,index) in tabList" :key="index"
						:class="index == current?'sameTabSelect':''" @click="tabFun(index)">
							<span>{{item.title}}</span>
							<div></div>
						</div>
					</div>
					<div class="twoImg">
						<img src="@/assets/images/img/researchInstitute.png" />
					</div>
					<div class="twoBoxConLeftList disbet" v-for="(item,index) in 5" :key="index"
					:class="index == 0?'twoBoxConLeftListSelect':''">
						<div class="one discolcen">
							<span>EURUSD</span>
							<span>Euro vs Us Dollar</span>
						</div>
						<div class="two discen">1.07160</div>
						<div class="three discolcen">
							<span class="red">-0.16%</span>
							<span>-0.00169</span>
						</div>
					</div>
				</div> -->
				<div class="twoBoxConLeft">
					<div id="newQuotesWidgetChart" style="width: 100%;height: 3rem;"></div>
					<div id="newQuotesWidgetOverview" style="width: 100%;height: 5rem;"></div>
				</div>
				<div class="twoBoxConRight">
					<router-link :to="'/marketingResearchDetails?id='+item.id" class="sectorList" v-for="(item,index) in newsList" :key="index">
						<div class="newsTitle" v-html="item.title"></div>
						<div class="newtime">{{item.author?item.author+'-':''}}{{item.newTime}}</div>
						<div class="newsDesc" v-html="item.remark"></div>
						<div class="imgBox">
							<img v-for="(sitem,sindex) in item.iconList" :key="sindex" :src="sitem"/>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<div class="threeBox" v-if="mainJson.block3">
			<div class="researchInstituteTitle discen" v-html="mainJson.block3.title"></div>
			<div class="threeListBox">
				<div class="threeList" v-for="(item,index) in mainJson.block3.list" :key="index">
					<img class="img" :src="item.img"/>
					<div class="title" v-html="item.title"></div>
					<div class="desc" v-html="item.desc"></div>
					<a class="more display" v-if="item.link" :href="item.link" target="_blank">
						<span>{{ $t('element.learnMore') }}</span>
						<img src="@/assets/images/img/moreRed.png"/>
					</a>
				</div>
			</div>
		</div>
		<div class="fourBox" v-if="mainJson.block4">
			<div class="researchInstituteTitle discen" v-html="mainJson.block4.title"></div>
			<div class="newSectorRight disbet">
				<div class="tvBox" v-if="roomInfo.living && !iframeShow">
					<vue-aliplayer-v2
						width="100%" height="300px"
						:source="roomInfo.playUrl"
						:cover="roomInfo.cover"
						ref="VueAliplayerV2"
						:options="options"
					  />	  
				</div>
				<div class="tvBox" v-if="!roomInfo.living && !iframeShow">
					<img :src="roomInfo.cover" />
				</div>
				<div class="tvBox" v-if="iframeShow">
					<iframe width="100%" height="300px" :key="iframeKey" :src="iframeInfo.sourceUrl" :title="iframeInfo.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
				</div>
				<div class="tvRight">
					<!-- <div class="title display">TV</div> -->
					<div class="newSectorList" v-if="roomInfo" @click="iframeShow = false">
						<!-- <div class="newSectorListTitle">{{roomInfo.createTime}}</div> -->
						<div class="newSectorListTitle" :class="!iframeShow?'newSectorListSelect':''" v-html="roomInfo.name"></div>
						<div class="subtitle display">
							<img src="@/assets/images/img/rightTriangle.png" />
							<div v-html="roomInfo.remark"></div>
						</div>
					</div>
					<div class="newSectorList" v-for="(item,index) in mainJson.block4.list" :key="index"
					@click="liveBroadcast(item,index)">
						<div class="newSectorListTitle" :class="iframeShow && iframeIndex == index?'newSectorListSelect':''" v-html="item.title"></div>
						<div class="subtitle display">
							<img src="@/assets/images/img/rightTriangle.png" />
							<div v-html="item.desc"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="fiveBox" v-if="mainJson.block5&&mainJson.block5.show">
			<div class="researchInstituteTitle discen" v-html="mainJson.block5.title"></div>
			<div class="descTitle discen" v-html="mainJson.block5.desc"></div>
			<div class="fiveCon">
				<div class="fiveList" v-for="(item,index) in mainJson.block5.list" :key="index"
				@click="teacherJump(item)">
					<img :src="item.img"/>
					<div class="fiveListContent">
						<div class="title" v-html="item.title"></div>
						<div class="desc" v-html="item.desc"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TCPlayer from 'tcplayer.js';
	import 'tcplayer.js/dist/tcplayer.min.css';
	export default {
		name: 'ResearchInstitute',
		data() {
			return {
				 options: {
				  // source:'//player.alicdn.com/video/aliyunmedia.mp4',
				  isLive: false, //切换为直播流的时候必填
				  // format: 'm3u8'  //切换为直播流的时候必填
				},
				mainJson:{},
				tabList:[
					{id:1,title:'AllInstruments'},
					{id:2,title:'AllInstr'},
					{id:3,title:'AllInstr'}
				],
				current:0,
				formData:{
					fileName:"index_en.json"
				},
				iframeKey:'',
				page:1,
				newsList:[],
				totalPage:1,
				roomInfo:{},
				iframeInfo:{},
				iframeShow:false,
				iframeIndex:0,
				options:{
					autoplay:false,
					isLive:true
				}
				
			}
		},
		mounted() {
			
		},
		created() {
			
			this.getJsonConfig();
			this.getNewsList();
			this.getLiveRoomInfo();
			
			// this.scriptFun1();
			
			// console.log(this.markets[this.navIndex].six)
		},
		methods: {
			teacherJump(item){
				if(item.detail){
					localStorage.setItem('teacherDetails',JSON.stringify(item));
					this.$router.push('/teacherDetails')
				}
			},
			scriptFun(){
				// 创建一个新的script标签
				const script = document.createElement('script');
				// script.async = true;		
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				script.type = 'text/javascript';
				script.src = 'https://c.mql5.com/js/widgets/quotes/widget.js?v=1';
				script.dataset.type = 'quotes-widget';
				var jsonScript = '{"type":"chart","filter":"EURCAD","period":"D1","width":"100%","height":"100%","lang":"'+lang+'","id":"newQuotesWidgetChart"}';
				script.text = jsonScript;
				
				
				// 创建一个新的script标签
				const script1 = document.createElement('script');	
				// script1.async = true;
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				script1.type = 'text/javascript';
				script1.src = 'https://c.mql5.com/js/widgets/quotes/widget.js?v=1';
				script1.dataset.type = 'quotes-widget';
				var jsonScript1 = '{"type":"overview","style":"table","filter":["USDCAD","USDJPY","GBPUSD","EURUSD","XAUUSD","XAGUSD"],"width":"100%","height":"100%","period":"D1","lang":"'+lang+'","id":"newQuotesWidgetOverview"}';
				script1.text = jsonScript1;	

				document.body.appendChild(script);
				document.body.appendChild(script1);
				
			},

			liveBroadcast(item,index){
				this.iframeInfo = item;
				this.iframeIndex = index;
				this.iframeKey = new Date().getTime();
				this.iframeShow = true;
			},
			tabFun(index){
				this.current = index;
			},
			suspend(){
				// 暂停播放
				this.$refs.VueAliplayerV2.pause();
			},
			getLiveRoomInfo(){
				var url = localStorage.getItem('lang') == 'zh'?'getLiveRoomInfo?lang=zh':'getLiveRoomInfo?lang=en';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					this.roomInfo = res.data;
					// player-container-id 为播放器容器 ID，必须与 html 中一致
					// if(res.data.living){
					// 	var player = TCPlayer('playerContainerId', {
					// 	    sources: [{
					// 	      src: res.data.playUrl, // 播放地址
					// 	    }],
					// 		poster:res.data.cover,
					// 		licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1308111394_1/v_cube.license', // license 地址，参考准备工作部分，在视立方控制台申请 license 后可获得 licenseUrl
					// 	});
					// }
					this.scriptFun();
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			formatPast(date, type = "default", zeroFillFlag = true){
				// 定义countTime变量，用于存储计算后的数据
				let countTime;
				// 获取当前时间戳
				let time = new Date().getTime();
				// 转换传入参数为时间戳
				let afferentTime = new Date(date).getTime();
				// 当前时间戳 - 传入时间戳
				time = Number.parseInt(`${time - afferentTime}`);
				if (time < 10000) {
				    // 10秒内
				    return "刚刚";
				} else if (time < 60000) {
				    // 超过10秒少于1分钟内
				    countTime = Math.floor(time / 1000);
				    return `${countTime}秒前`;
				} else if (time < 3600000) {
				    // 超过1分钟少于1小时
				    countTime = Math.floor(time / 60000);
				    return `${countTime}分钟前`;
				} else if (time < 86400000) {
				    // 超过1小时少于24小时
				    countTime = Math.floor(time / 3600000);
				    return `${countTime}小时前`;
				} else if (time >= 86400000 && type == "default") {
				    // 超过二十四小时（一天）且格式参数为默认"default"
				    countTime = Math.floor(time / 86400000);
				    //大于等于365天
				    if (countTime >= 365) {
				        return `${Math.floor(countTime / 365)}年前`;
				    }
				    //大于等于30天
				    if (countTime >= 30) {
				        return `${Math.floor(countTime / 30)}个月前`;
				    }
				    return `${countTime}天前`;
				} else {
				    // 一天（24小时）以上且格式不为"default"则按传入格式参数显示不同格式
				    // 数字补零
				    let Y = new Date(date).getFullYear();
				    let M = new Date(date).getMonth() + 1;
				    let zeroFillM = M > 9 ? M : "0" + M;
				    let D = new Date(date).getDate();
				    let zeroFillD = D > 9 ? D : "0" + D;
				    // 传入格式为"-" "/" "."
				    if (type == "-" || type == "/" || type == ".") {
				        return zeroFillFlag
				            ? Y + type + zeroFillM + type + zeroFillD
				            : Y + type + M + type + D;
				    }
				    // 传入格式为"年月日"
				    if (type == "年月日") {
				        return zeroFillFlag
				            ? Y + type[0] + zeroFillM + type[1] + zeroFillD + type[2]
				            : Y + type[0] + M + type[1] + D + type[2];
				    }
				    // 传入格式为"月日"
				    if (type == "月日") {
				        return zeroFillFlag
				            ? zeroFillM + type[0] + zeroFillD + type[1]
				            : M + type[0] + D + type[1]
				    }
				    // 传入格式为"年"
				    if (type == "年") {
				        return Y + type
				    }
							
				}
			},
			getNewsList(){
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				this.$http.get(this.$apiBaseUrl+'newsList?lang='+lang+'&pageSize=4'+'&pageNum='+this.page).then((res)=>{
					// console.log('res',res)
					res.rows.forEach((item,index)=>{
						item['newTime'] = this.formatPast(item.createTime);
						if(item.icons){
							item['iconList'] = (item.icons).split(',');
						}else{
							item['iconList'] = [];
						}
					})
					this.newsList = res.rows;
					
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=college.json':'getJsonConfig?fileName=college_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block2.list.length>0&&configData.block2.list[0].hasOwnProperty('sortNo')){
						configData.block2.list =  (configData.block2.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block3.list.length>0&&configData.block3.list[0].hasOwnProperty('sortNo')){
						configData.block3.list =  (configData.block3.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block4.list.length>0&&configData.block4.list[0].hasOwnProperty('sortNo')){
						configData.block4.list =  (configData.block4.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block5.list.length>0&&configData.block5.list[0].hasOwnProperty('sortNo')){
						configData.block5.list =  (configData.block5.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					this.mainJson = configData;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	 @import '@/assets/css/researchInstitute.scss';
	
</style>