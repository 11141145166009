<template>
	<div id="about">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="aboutbanner" v-if="mainJson.block1" :style="{backgroundImage:'url('+mainJson.block1.img+')'}">
			<div class="bannerBox">
				<div class="bannerTitle" v-html="mainJson.block1.title"></div>
				<div class="bannerDesc" v-html="mainJson.block1.desc"></div>
			</div>
		</div>
		<!-- 关于我们 -->
		<div class="aboutBox" v-if="mainJson.block2">
			<div class="indexTitle" v-html="mainJson.block2.title"></div>
			<div class="indexSubTitle" v-html="mainJson.block2.desc"></div>
		</div>
		<!-- 板块2 -->
		<div class="plateTwoAbout" v-if="mainJson.block3">
			<div class="marketsTitle" v-html="mainJson.block3.title"></div>
			<div class="plateTwoBox">
				<div class="sameplateTwo" v-for="(item,index) in mainJson.block3.list" :key="index">
					<img :src="item.img"/>
					<div class="sameplateTwoTitle" v-html="item.title"></div>
					<div class="sameplateTwodesc" v-html="item.desc"></div>
				</div>
			</div>
		</div>
		<!-- 使命 -->
		<div class="ourMission" v-if="mainJson.block4">
			<div class="indexTitle" v-html="mainJson.block4.title"></div>
			<div class="indexSubTitle" v-html="mainJson.block4.desc"></div>
		</div>
		<!-- 愿景 -->
		<div class="vision" v-if="mainJson.block4">
			<div class="visionBox" :style="{backgroundImage:'url('+mainJson.block4.img.img+')'}">
				<div class="visionTitle" v-html="mainJson.block4.img.title"></div>
				<div class="visionDesc" v-html="mainJson.block4.img.desc"></div>
			</div>
		</div>
		<!-- 价值观 -->
		<div class="ourValues" v-if="mainJson.block5">
			<div class="indexTitle" v-html="mainJson.block5.title"></div>
			<div class="indexSubTitle" v-html="mainJson.block5.desc"></div>
			<div class="ourValuesBox">
				<div class="ourValuesplateTwo" v-for="(item,index) in mainJson.block5.list" :key="index">
					<div class="ourValuesplateTwoTitle" v-html="item.title"></div>
					<div class="ourValuesplateTwodesc" v-html="item.desc"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'about',
		data() {
			return {
				mainJson:{}
			}
		},
		mounted() {

		},
		created() {
			this.getJsonConfig();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=about_us.json':'getJsonConfig?fileName=about_us_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block3.list.length>0&&configData.block3.list[0].hasOwnProperty('sortNo')){
						configData.block3.list =  (configData.block3.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block5.list.length>0&&configData.block5.list[0].hasOwnProperty('sortNo')){
						configData.block5.list =  (configData.block5.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					this.mainJson = configData;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/assets/css/aboutus.css';
	
	.indexTitle{
		width: 100%;
		height: auto;
		font-size: 0.32rem;
		color: #202020;
		text-align: center;
		padding: 0 0.3rem;
		line-height: 0.4rem;
		font-weight: 700;
	}
	.indexSubTitle{
		width: 100%;
		height: auto;
		line-height: 0.3rem;
		padding: 0 1rem;
		font-size: 0.16rem;
		color: #7F8284;
		margin: 0.15rem auto 0;
		text-align: center;
	}
	.aboutbanner{
		width: 100%;
		height: 5rem;
		background-image: url('../assets/images/about/about01.png');
		background-size: cover;
		.bannerBox{
			width: 100%;
			height: 5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.bannerTitle{
				font-size: 0.44rem;
				color: #FFFFFF;
			}
			.bannerDesc{
				font-size: 0.26rem;
				color: #FFFFFF;
				margin:0.35rem 0 0;
				text-align: center;
			}
		}
	}
	.aboutBox{
		width: 100%;
		height: auto;
		padding: 1rem 0;
	}
	.marketsTitle{
		width: 100%;
		height: auto;
		font-size: 0.32rem;
		font-weight: 700;
		color: #202020;
		text-align: center;
	}
	.marketsDesc{
		width: 100%;
		height: auto;
		font-size: 0.22rem;
		color: #202020;
		line-height: 0.3rem;
		text-align: center;
		margin-top: 0.2rem;
	}
	.plateTwoAbout{
		width: 100%;
		height: auto;
		padding: 0 0.5rem 0.5rem;
		.plateTwoBox{
			width: 12rem;
			height: auto;
			margin: 0.6rem auto 0;
			display: flex;
			flex-wrap: wrap;
			/* justify-content: space-between; */
			.sameplateTwo{
				// width: 3.6rem;
				width: 30%;
				height: auto;
				margin-bottom: 0.5rem;
				// margin-right: 0.4rem;
				margin-left: 2.5%;
				>img{
					width: 100%;
					height: 2.4rem;
					display: block;
					margin-bottom: 0.2rem;
				}
				.sameplateTwoTitle{
					width: 100%;
					height: 0.6rem;
					line-height: 0.3rem;
					font-size: 0.22rem;
					color: #29261C;
				}
				.sameplateTwodesc{
					width: 100%;
					height: auto;
					font-size: 0.16rem;
					color: #7F8284;
					line-height: 0.25rem;
				}
			}
		}
	}
	.ourMission{
		width: 100%;
		height: auto;
	}
	
	.vision{
		width: 100%;
		height: auto;
		margin: 0.7rem 0 0;
		.visionBox{
			width: 12rem;
			height: 3.6rem;
			padding-top: 0.9rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-size: cover;
			margin: 0 auto;
			.visionTitle{
				font-size: 0.32rem;
				color: #FFFFFF;
				font-weight: 700;
			}
			.visionDesc{
				font-size: 0.16rem;
				color: #FFFFFF;
				margin:0.39rem 0 0;
				text-align: center;
			}
		}
	}
	
	.ourValues{
		width: 100%;
		height: auto;
		padding: 1rem 0.5rem 2rem;
		.ourValuesBox{
			width: 12rem;
			height: auto;
			margin: 0.4rem auto 0;
			display: flex;
			flex-wrap: wrap;
			/* justify-content: space-between; */
			.ourValuesplateTwo{
				// width: 3.6rem;
				width: 30%;
				height: auto;
				margin-bottom: 0.5rem;
				// margin-right: 0.4rem;
				margin-left: 2.5%;
				.ourValuesplateTwoTitle{
					width: 100%;
					height: 0.6rem;
					line-height: 0.3rem;
					font-size: 0.2rem;
					color: #29261C;
				}
				.ourValuesplateTwodesc{
					width: 100%;
					height: auto;
					font-size: 0.16rem;
					color: #7F8284;
					line-height: 0.25rem;
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		.aboutbanner{
			width: 100%;
			height: auto;
			.bannerBox{
				width: 100%;
				height: auto;
				padding: 1.5rem 0.3rem;
				.bannerTitle{
					font-size: 0.36rem;
					text-align: center;
				}
				.bannerDesc{
					font-size: 0.26rem;
					color: #FFFFFF;
					margin:0.35rem 0 0;
					text-align: center;
				}
			}
		}
		.plateTwoAbout{
			.plateTwoBox{
				width: 100%;
				justify-content: space-between;
				.sameplateTwo{
					width: 48%;
					margin-right: 0;
					margin-left: 0;
					.sameplateTwoTitle{
						font-weight: 700;
					}
				}
			}
		}
		.vision{
			width: 100%;
			padding: 0 0.3rem;
			.visionBox{
				width: 100%;
				height: auto;
				padding: 0.9rem 0 1.2rem;
			}
		}
		.ourValues{
			.ourValuesBox{
				width: 100%;
				.ourValuesplateTwo{
					width: 100%;
					height: auto;
					margin-bottom: 0.5rem;
					margin-right: 0;
					.ourValuesplateTwoTitle{
						font-weight: 700;
					}
				}
			}
		}
		
		
		
	}
</style>