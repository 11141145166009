<template>
	<div class="Tips" v-show="clickCcountAll">
		<div class="tipsContent">
			<img @click="openAccountAllFun" class="close" src="@/assets/images/img/close.png"/>
			<img src="@/assets/images/img/tipss.png"/>
			<span>{{ $t('login.tips') }}</span>
			<div @click="openAccountAllFun">{{ $t('element.confirm') }}</div>
		</div>
	</div>
</template>

<script>
	export default {
	  name:'Tips',
	  props: {
		  clickCcountAll: {
			  type:Boolean,
			  default: false
			  // require: true // 必填
		  }
	  },
	  components: {
		
	  },
	  
	  data() {
	  	return{

		}
	  },
	  mounted() {

	  },
	  created() {

	  },

	  methods:{
		  openAccountAllFun(){
			  this.$emit("openAccountAllFun");
		  }
		  
	  }	
	}
</script>

<style lang="scss" scoped>
	.Tips{
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		left:0;
		top:0;
		z-index: 11111;
		transition: all 0.3s;
		.tipsContent{
			width: 4rem;
			height: 3rem;
			background: #FFFFFF;
			box-shadow: 0px 3px 30px rgba(155,158,162,0.16);
			opacity: 1;
			border-radius: 6px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 0.16rem;
			color:#202020;
			position: relative;
			.close{
				width: 0.28rem;
				height:0.28rem;
				position: absolute;
				right: 0.2rem;
				top:0.2rem;
				z-index: 11;
				cursor: pointer;
			}
			>img{
				width: 1.28rem;
				height:1.28rem;
			}
			>div{
				cursor: pointer;
				width: 1.20rem;
				height: 0.36rem;
				background: #D51820;
				opacity: 1;
				border-radius: 25px;
				margin-top: 0.4rem;
				font-size: 0.16rem;
				color:#ffffff;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	@media only screen and (max-width:1080px){
	
	}
</style>