import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "@/views/Index.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Forget from "@/views/Forget.vue";
import Markets from "@/views/Markets.vue";
import AboutUs from "@/views/AboutUs.vue";
import Partners from "@/views/Partners.vue";
import TradingPlatform from "@/views/TradingPlatform.vue";
import Risk from "@/views/Risk.vue";
import OpenAccount from "@/views/OpenAccount.vue";
import Access from "@/views/Access.vue";
import Problem from "@/views/Problem.vue";
import ContactUs from "@/views/ContactUs.vue";
import Activity from "@/views/Activity.vue";
import College from "@/views/College.vue";
import ResearchInstitute from "@/views/researchInstitute.vue";
import MarketingResearch from "@/views/MarketingResearch.vue";
import TradingSignalAnalysis from "@/views/TradingSignalAnalysis.vue";
import EconomicCalendar from "@/views/EconomicCalendar.vue";
import MarketingResearchDetails from "@/views/MarketingResearchDetails.vue";
import TradingSignalAnalysisDetails from "@/views/TradingSignalAnalysisDetails.vue";
import cun from "@/views/cun.vue";
import commoditiesDetails from "@/views/commoditiesDetails.vue";
import teacherDetails from "@/views/teacherDetails.vue";
// import header from "@/components/layout/header.vue";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
	{
	    path: '/register',
	    name: 'Register',
	    component: Register
	},
	{
	    path: '/forget',
	    name: 'Forget',
	    component: Forget
	},
	{
		path:'/markets',
		name:'Markets',
		component:Markets
	},
	{
		path:'/aboutUs',
		name:'AboutUs',
		component:AboutUs
	},
	{
		path:'/partners',
		name:'Partners',
		component:Partners
	},
	{
		path:'/tradingPlatform',
		name:'TradingPlatform',
		component:TradingPlatform
	},
	{
		path:'/risk',
		name:'Risk',
		component:Risk
	},
	{
		path:'/openAccount',
		name:'OpenAccount',
		component:OpenAccount
	},
	{
	    path: '/access',
	    name: 'Access',
	    component: Access
	},
	{
	    path: '/problem',
	    name: 'Problem',
	    component: Problem
	},
	{
	    path: '/contactUs',
	    name: 'ContactUs',
	    component: ContactUs
	},
	{
	    path: '/activity',
	    name: 'Activity',
	    component: Activity
	},
	{
	    path: '/college',
	    name: 'College',
	    component: College
	},
	{
	    path: '/ResearchInstitute',
	    name: 'ResearchInstitute',
	    component: ResearchInstitute
	},
	{
	    path: '/marketingResearch',
	    name: 'MarketingResearch',
	    component: MarketingResearch
	},
	{
	    path: '/tradingSignalAnalysis',
	    name: 'TradingSignalAnalysis',
	    component: TradingSignalAnalysis
	},
	{
	    path: '/economicCalendar',
	    name: 'EconomicCalendar',
	    component: EconomicCalendar
	},
	{
	    path: '/marketingResearchDetails',
	    name: 'MarketingResearchDetails',
	    component: MarketingResearchDetails
	},
	{
	    path: '/tradingSignalAnalysisDetails',
	    name: 'TradingSignalAnalysisDetails',
	    component: TradingSignalAnalysisDetails
	},
	{
	    path: '/cun',
	    name: 'cun',
	    component: cun
	},
	{
	    path: '/commoditiesDetails',
	    name: 'commoditiesDetails',
	    component: commoditiesDetails
	},
	{
	    path: '/teacherDetails',
	    name: 'teacherDetails',
	    component: teacherDetails
	}
	
	
	
]

const router = new VueRouter({
    routes,
	scrollBehavior (to, from, savedPosition) {
	  return { x: 0, y: 0 }
	}
})

export default router

