<template>
	<div id="access">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="accessBanner" v-if="mainJson.block1" v-html="mainJson.block1.title"
		:style="{backgroundImage:'url('+mainJson.block1.img+')'}"></div>
		<div class="accessBox" v-if="mainJson.block2">
			<div class="accessBoxTitle" v-html="mainJson.block2.title"></div>
			<div class="accessBoxDesc" v-html="mainJson.block2.desc"></div>
			<div class="accessBoxMian" v-html="mainJson.block2.tips"></div>
			<div class="accessListBoxBox">
				<div class="accessListBox">
				<div class="sameaccess" v-for="(item,index) in mainJson.block2.list" :key="index">
					<img :src="item.img"/>
					<div class="accOne" v-html="item.title"></div>
					<div class="accTwo" v-html="item.content">
						<!-- <div>{{item.subtitle}}</div>
						<div>{{item.desc}}</div> -->
					</div>
					<div class="accThree" v-html="item.desc">
						<!-- <div>{{item.top}}</div>
						<div><span>{{item.num}}</span>{{item.bot}}</div> -->
					</div>
					<a :href="item.link" target="_blank" class="accFour">{{ $t('access.cash.button')}}</a>
				</div>
			</div>
			</div>
			<div class="annotateBox">
				<div class="sameannotate" v-for="(item,index) in mainJson.block3" :key="index">
					<div class="sameannotateTitle" v-html="item.title"></div>
					<div class="sameannotateDesc" v-html="item.desc"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'access',
		data() {
			return {
				mainJson:{},
			}
		},
		mounted() {

		},
		created() {
			this.getJsonConfig();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=access.json':'getJsonConfig?fileName=access_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block2.list.length>0&&configData.block2.list[0].hasOwnProperty('sortNo')){
						configData.block2.list =  (configData.block2.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					this.mainJson = configData;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.accessBanner{
		width: 100%;
		height: 4.3rem;
		background-image: url('../assets/images/custom/custom02.png');
		background-size: cover;
		font-size: 0.44rem;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		// align-items: center;
		text-align: center;
		padding: 1.28rem 0 0;
		line-height: 0.7rem;
	}
	.accessBox{
		width: 12rem;
		height: auto;
		margin: 0 auto;
		padding: 0.94rem 0 2.6rem;
		.accessBoxTitle{
			width: 100%;
			height:auto;
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
		}
		.accessBoxDesc{
			width: 100%;
			height:auto;
			font-size: 0.18rem;
			color: #202020;
			margin: 0.28rem 0 0.12rem;
			line-height: 0.25rem;
		}
		.accessBoxMian{
			width: 100%;
			height:auto;
			font-size: 0.16rem;
			color: #7F8284;
			line-height: 0.25rem;
		}
		.accessListBox{
			width: 11.4rem;
			height: auto;
			border-top: 1px solid #E4E4E4;
			margin: 1rem auto ;
			.sameaccess{
				width: 100%;
				height: 1rem;
				padding: 0 0.3rem;
				display: flex;
				justify-content: space-between;
				align-items:center;
				font-size: 0.16rem;
				color: #202020;
				>img{
					width: 1.28rem;
					height: 0.7rem;
				}
				.accOne{
					width: 1.59rem;
					height: 1rem;
					padding-left: 0.24rem;
					display: flex;
					align-items: center;
				}
				.accTwo{
					width: 4.3rem;
					height: 1rem;
					padding: 0 0.2rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					line-height: 0.3rem;
				}
				.accThree{
					width: 2.4rem;
					height: 1rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-size: 0.14rem;
					line-height: 0.3rem;
					>div>span{
						color: #D51820;
					}
				}
				.accFour{
					width: 1.23rem;
					height: 0.46rem;
					background: #D51820;
					opacity: 1;
					border-radius: 0.25rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.16rem;
					color: #FFFFFF;
					cursor: pointer;
				}
			}
			.sameaccess:nth-child(even){
				background-color: rgba(200, 209, 216, 0.12);
			}
		}
	}
	.annotateBox{
		width: 100%;
		height: auto;
		.sameannotate{
			width: 100%;
			height: auto;
			margin: 0.56rem 0 0;
			.sameannotateTitle{
				width: 100%;
				height:auto;
				font-size: 0.32rem;
				color: #202020;
				font-weight: 700;
			}
			.sameannotateDesc{
				width: 100%;
				height:auto;
				font-size: 0.16rem;
				color: #202020;
				margin: 0.28rem 0 0;
				line-height: 0.25rem;
			}
		}
	}
	@media only screen and (max-width:1080px){
		.accessBanner{
			width: 100%;
			height: auto;
			font-size: 0.36rem;
			padding: 1.28rem 0.3rem;
			line-height: 0.5rem;
		}
		.accessBox{
			width: 100%;
			padding: 0.94rem 0.3rem 2rem;
			.accessListBoxBox{
				width: 100%;
				height: auto;
				overflow: hidden;
				overflow-x: scroll;
			}
		}
		
	}
</style>