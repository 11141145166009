<template>
	<div id="Partners">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="mtbanner" v-if="mainJson.block1">
			<div class="mtbannerBox">
				<div class="mtbannerBoxLeft">
					<div class="mtbannerTitle" v-html="mainJson.block1.title"></div>
					<div class="mtbannerDesc" v-html="mainJson.block1.desc"></div>
					<div class="mtbannera" @click="openAccount(1)">
						<span>{{ $t('mt5.banner.button') }}</span>
						<img src="@/assets/images/img/more.png"/>
					</div>
				</div>
				<img :src="mainJson.block1.img"/>
			</div>
		</div>
		<!-- 介绍 -->
		<div class="introduce" v-if="mainJson.block2">
			<div class="introduceLeft">
				<div class="introduceTitle" v-html="mainJson.block2.title"></div>
				<div class="introduceDesc" v-html="mainJson.block2.desc"></div>
			</div>
			<a href="#platformBox" class="introduceRight">
				<span>{{ $t('mt5.introduce.button') }}</span>
				<img src="@/assets/images/img/more.png"/>
			</a>
		</div>
		<!-- 主要特性 -->
		<div class="mainFeatures" v-if="mainJson.block3">
			<div class="mainFeaturesTitle" v-html="mainJson.block3.title"></div>
			<div class="mainFeaturesBox">
				<div class="samemainFeatures">
					<div class="samemainFeatureslist" v-for="(item,index) in mainJson.block3.lList" :key="index">
						<img src="@/assets/images/mt5/tp02.png"/>
						<span>{{item}}</span>
					</div>
				</div>
				<div class="samemainFeatures">
					<div class="samemainFeatureslist" v-for="(item,index) in mainJson.block3.rList" :key="index">
						<img src="@/assets/images/mt5/tp02.png"/>
						<span>{{item}}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 各平台版本 -->
		<div class="platformBox" id="platformBox" v-if="mainJson.block4">
			<div class="platformTitle" v-html="mainJson.block4.title"></div>
			<div class="platform">
				<div class="sameplatform" v-for="(item,index) in mainJson.block4.list" :key="index" >
					<img :src="item.img"/>
					<div class="sameplatformTitle" v-html="item.title"></div>
					<div class="sameplatformDesc" v-html="item.desc"></div>
					<a :href="item.link" target="_blank" class="sameplatforma">{{item.btnTitle}}</a>
				</div>
			</div>
		</div>
		<!-- 移动端 -->
		<div class="mobileterminalBox" v-if="mainJson.block5">
			<div class="mobileterminal">
				<div class="mobileterminalTitle" v-html="mainJson.block5.title"></div>
				<div class="mobileterminalDesc" v-html="mainJson.block5.desc"></div>
				<div class="mobileterminalLink">
					<a :href="item.link ? item.link:'javascript:;'" :target="item.link ? '_blank':''" 
					class="mobileterminalLinkDiv" v-for="(item,index) in mainJson.block5.list" :key="index">
						<img :src="item.icon" />
						<span v-html="item.btnTitle"></span>
						<a :href="item.link ? item.link:'javascript:;'" :target="item.link ? '_blank':''"  class="mobilLink">
							<img v-if="item.qrCode" :src="item.qrCode"/>
						</a>
					</a>
				</div>
			</div>
			<img :src="mainJson.block5.img"/>
		</div>
		<Tips :clickCcountAll="clickCcountAll" @openAccountAllFun="openAccountAllFun"></Tips>
	</div>
</template>

<script>
	import Tips  from '../components/layout/tips';
	export default {
		name: 'Partners',
		components:{
			Tips
		},
		data() {
			return {
				clickCcountAll:false,
				mainJson:{}
			}
		},
		mounted() {

		},
		created() {
			this.getJsonConfig();
			// console.log(this.markets[this.navIndex].six)
		},
		
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=trading_platform.json':'getJsonConfig?fileName=trading_platform_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block4.list.length>0&&configData.block4.list[0].hasOwnProperty('sortNo')){
						configData.block4.list =  (configData.block4.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					this.mainJson = configData;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			
			openAccountAllFun(){
				this.clickCcountAll = !this.clickCcountAll;
			},
			openAccount(type){
				// type:1 开户   2合作伙伴注册
				if(localStorage.getItem('token')){
					this.openAccountAllFun();
				}else{
					this.openAccountAll(type);
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/assets/css/TradingPlatform.css';
	
	.mtbanner{
		width: 100%;
		height: 6.8rem;
		display: flex;
		justify-content: center;
		align-items: center;
		.mtbannerBox{
			width: 12rem;
			height: auto;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			position: relative;
			.mtbannerBoxLeft{
				width: 7.15rem;
				height: auto;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				display: flex;
				flex-direction: column;
				padding-top: 0.43rem;
				.mtbannerTitle{
					width: 100%;
					height: auto;
					font-size: 0.44rem;
					color: #202020;
					font-weight: 700;
				}
				.mtbannerDesc{
					width: 100%;
					height: auto;
					font-size: 0.22rem;
					color: #202020;
					line-height: 0.3rem;
					margin: 0.3rem auto 0.6rem;
				}
				.mtbannera{
					width: 2.70rem;
					height: 0.46rem;
					background: #D51820;
					border-radius: 0.25rem;
					font-size: 0.16rem;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					>img{
						width: 0.12rem;
						height:0.12rem;
						margin-left: 0.05rem;
					}
				}
			}
			>img{
				width: 5.7rem;
				height: 4.56rem;
			}
		}
	}
	.introduce{
		width: 100%;
		height: 4.1rem;
		background-color: #161616;
		display: flex;
		justify-content: center;
		align-items: center;
		.introduceLeft{
			width: 10rem;
			height: auto;
			padding-right: 0.7rem;
			.introduceTitle{
				font-size: 0.32rem;
				color: #FFFFFF;
				font-weight: 700;
			}
			.introduceDesc{
				font-size: 0.16rem;
				color: #FFFFFF;
				line-height: 0.25rem;
				margin-top: 0.36rem;
			}
		}
		.introduceRight{
			width: 2rem;
			height: 0.46rem;
			background: #D51820;
			border-radius: 0.25rem;
			font-size: 0.16rem;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
			>img{
				width: 0.12rem;
				height:0.12rem;
				margin-left: 0.05rem;
			}
		}
	}
	.mainFeatures{
		width: 100%;
		height: auto;
		padding: 1rem 0 0;
		.mainFeaturesTitle{
			width: 100%;
			height: 0.9rem;
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
			text-align: center;
		}
		.mainFeaturesBox{
			width: 12rem;
			height: auto;
			display: flex;
			justify-content: space-between;
			margin:0 auto;
			.samemainFeatures{
				width: 5.80rem;
				height: 5.11rem;
				background: #F7F7F7;
				border-radius: 0.10rem;
				padding: 0 0.3rem 0 0.7rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				.samemainFeatureslist{
					width: 100%;
					height: 0.5rem;
					display: flex;
					align-items: center;
					font-size: 0.16rem;
					color: #202020;
					>img{
						width: 0.14rem;
						height: 0.14rem;
						margin-right: 0.2rem;
					}
				}
			}
			
		}
	}
	.platformBox{
		width: 12rem;
		height: auto;
		margin: 1.2rem auto 0;
		background: #FFFFFF;
		box-shadow: 0px 0px 0.3rem rgba(159,159,159,0.16);
		opacity: 1;
		border-radius: 2px;
		padding: 0.65rem 0 0.7rem;
		.platformTitle{
			text-align: center;
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
		}
		.platform{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: space-between;
			.sameplatform{
				width: 3.7rem;
				height: auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0.9rem 0.5rem 0 0.5rem;
				>img{
					width: 0.42rem;
					height: 0.42rem;
					margin-bottom: 0.18rem;
				}
				.sameplatformTitle{
					width: 100%;
					height: 0.5rem;
					line-height: 0.25rem;
					text-align: center;
					font-size: 0.18rem;
					color: #202020;
				}
				.sameplatformDesc{
					width: 100%;
					height: 1.5rem;
					font-size: 0.16rem;
					color: #7F8284;
					line-height: 0.25rem;
					margin-bottom: 0.5rem;
					overflow: hidden;
					white-space: normal;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 6;
					overflow: hidden;
				}
				.sameplatforma{
					width: 2.60rem;
					height: 0.46rem;
					border: 1px solid #D51820;
					opacity: 1;
					border-radius: 0.25rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.16rem;
					color: #D51820;
				}
			}
		}
	}
	.mobileterminalBox{
		width: 100%;
		height: auto;
		padding: 1.4rem 0 2.6rem;
		display: flex;
		justify-content: center;
		align-items: center;
		.mobileterminal{
			width: 5.7rem;
			height: auto;
			padding-right: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.mobileterminalTitle{
			width: 100%;
			height: auto;
			font-size: 0.32rem;
			color: #202020;
			font-weight: 700;
		}
		.mobileterminalDesc{
			width: 100%;
			height: auto;
			font-size: 0.16rem;
			color: #202020;
			line-height: 0.25rem;
			margin: 0.4rem 0 0.6rem;
		}
		.mobileterminalLink{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.mobileterminalLinkDiv{
				width: 1.44rem;
				height: 0.46rem;
				background: #202020;
				border-radius: 0.06rem;
				font-size: 0.16rem;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				cursor: pointer;
				>img{
					width: 0.24rem;
					height: 0.24rem;
					margin-right: 0.1rem;
				}
				.mobilLink{
					width: 1.44rem;
					height: 1.44rem;
					position: absolute;
					left: 0;
					top: 0.46rem;
					z-index: 11;
					display: none;
					transition: all 0.3s;
					>img{
						width: 100%;
						height: 100%;
						display: block;
					}
				}
			}
		}
		>img{
			width: 6.3rem;
			height: 5rem;
		}
	}
	.mobileterminalLinkDiv:hover .mobilLink,.mobilLink:hover{
		display: block !important;
		transition: all 0.3s;
	}
	
	.mobileterminalLinkDiv:click .mobilLink,.mobilLink:click{
		display: block !important;
		transition: all 0.3s;
	}
	@media only screen and (max-width:1080px){
		.mtbanner{
			width: 100%;
			height: auto;
			padding: 0 0.3rem;
			.mtbannerBox{
				width: 100%;
				height: auto;
				flex-direction: column;
				.mtbannerBoxLeft{
					width: 100%;
					height: auto;
					position: relative;
					.mtbannera{
						width: 3rem;
						margin: 0.3rem auto;
					}
				}
				>img{
					width: 90%;
					height: auto;
					display: block;
					margin: 0 auto 0.6rem;
				}
			}
		}
		.introduce{
			width: 100%;
			height: auto;
			flex-direction: column;
			padding: 1rem 0.3rem;
			.introduceLeft{
				width: 100%;
				height: auto;
				padding-right: 0;
			}
			.introduceRight{
				width: 2.5rem;
				margin: 0.5rem auto 0;
			}
		}
		.mainFeatures{
			width: 100%;
			padding: 1rem 0.3rem 0;
			.mainFeaturesBox{
				width: 100%;
				height: auto;
				flex-direction: column;
				.samemainFeatures{
					width: 80%;
					margin:0 auto 0.5rem;
				}
				
			}
		}
		.platformBox{
			width: 100%;
			height: auto;
			padding: 0.65rem 0.3rem 0.7rem;
			.platform{
				width: 100%;
				height: auto;
				flex-direction: column;
				.sameplatform{
					width: 80%;
					margin:0 auto 0.5rem;
					padding: 0.3rem 0.5rem;
					.sameplatforma{
						width: 3.3rem;
					}
					.sameplatformDesc{
						line-height: 0.35rem;
					}
				}
			}
		}
		.mobileterminalBox{
			width: 100%;
			height: auto;
			padding: 1.4rem 0.3rem;
			flex-direction: column;
			.mobileterminal{
				width: 100%;
				height: auto;
				padding-right: 0;
			}
			
			.mobileterminalLink{
				width: 100%;
				height: auto;
				.mobileterminalLinkDiv{
					width: 30%;
					height: 0.5rem;
					.mobilLink{
						width: 100%;
						height: auto;
					}
				}
			}
			>img{
				width: 100%;
				height: auto;
				display: block;
				margin-top: 0.5rem;
			}
		}
		.mobileterminalLinkDiv:hover .mobilLink,.mobilLink:hover{
			display: none !important;
			transition: all 0.3s;
		}
		.mobileterminalLinkDiv:click .mobilLink,.mobilLink:click{
			display: none !important;
			transition: all 0.3s;
		}
		
	}
</style>