<template>
	<!-- :class="fadeIn?'headerColor':''" -->
	<div id="topheader">
		<div class="header headerColor" >
			<router-link class="logo" to="/">
				<img class="logoImg" src="@/assets/images/img/logo.png"/>
			</router-link>
			<div class="navBox display">
				<div class="sameNavList display" v-for="(item,index) in navList" :key="index">
					<router-link :to="item.src || ''" class="firstButton">{{item.title}}</router-link>
					<div class="navHideBox" v-if="item.list.length>0">
						<div class="secondaryButton" v-for="(sitem,sindex) in item.list" :key="sindex"
						@click="openEducation" v-if="index == 2 && sindex == 4">{{sitem.title}}</div>
						<router-link class="secondaryButton" v-for="(sitem,sindex) in item.list" :key="sindex"
						v-if="!((index == 6 && sindex == 1) || (index == 2 && sindex == 4)||(index == 2 && sindex == 3))" 
						:to="index == 0?(item.src+'?index='+sindex+'&navIndex='+sindex+'&currentTime='+currentTime):(sitem.src?sitem.src:(item.src || ''))">{{sitem.title}}</router-link>	
						<router-link class="secondaryButton" v-for="(sitem,sindex) in item.list" :key="sindex"
						v-if="index == 2 && sindex == 3 && education_video_show == 1" 
						:to="index == 0?(item.src+'?index='+sindex+'&navIndex='+sindex+'&currentTime='+currentTime):(sitem.src?sitem.src:(item.src || ''))">{{sitem.title}}</router-link>	
						
					</div>
				</div>
			</div>
			<div class="headerRight display">
				<img class="customerService" src="@/assets/images/img/customerService.png" />
				<div class="language display">
					<div class="languageTitle display">
						<img v-if="language == 1" src="@/assets/images/img/zh.png" />
						<img v-else src="@/assets/images/img/en.png" />
						<span>{{language == 1?$t('language.zh'):$t('language.en')}}</span>
					</div>
					<div class="languageHide">
						<div class="languageSame display" @click="langChange(2)">
							<img src="@/assets/images/img/en.png"/>
							<span>{{ $t('language.en') }}</span>
						</div>
						<div class="languageSame display" @click="langChange(1)">
							<img src="@/assets/images/img/zh.png"/>
							<span>{{ $t('language.zh') }}</span>
						</div>
					</div>
				</div>
				<div class="display" v-if="!login">
					<!-- <router-link class="logOn discen" to="/login">{{ $t('login.signIn') }}</router-link>
					<router-link class="register discen" to="/register">{{ $t('login.signUp') }}</router-link> -->
					<a class="logOn discen" :href="loginUrl" target="_blank">{{ $t('login.signIn') }}</a>
					<a class="register discen" :href="registerUrl" target="_blank">{{ $t('login.signUp') }}</a>
				</div>
				<div class="headerTips" v-if="login">
					<span>{{ $t('login.tips') }}</span>
					<span @click="signOutFun">{{ $t('login.signOut') }}</span>
				</div>
			</div>
		</div>
		<div class="mobileheader">
			<router-link class="logo" to="/">
				<img class="logoImg" src="@/assets/images/img/logo.png"/>
			</router-link>
			<div class="menu" @click="mobileMenu = !mobileMenu">
				<img src="@/assets/images/img/menu.png"/>
			</div>
			<div class="mobileMenu" :class="mobileMenu?'mobileMenuShow':''">
				<div class="mobilelanguage">
					<div class="language display">
						<div class="languageTitle display" @click="languageshow = !languageshow">
							<img v-if="language == 1" src="@/assets/images/img/zh.png" />
							<img v-else src="@/assets/images/img/en.png" />
							<span>{{language == 1?$t('language.zh'):$t('language.en')}}</span>
						</div>
						<div class="languageHide" :class="languageshow?'languageshowView':''">
							<div class="languageSame display" @click="langChange(2,2)">
								<img src="@/assets/images/img/en.png"/>
								<span>{{ $t('language.en') }}</span>
							</div>
							<div class="languageSame display" @click="langChange(1,2)">
								<img src="@/assets/images/img/zh.png"/>
								<span>{{ $t('language.zh') }}</span>
							</div>
						</div>
					</div>
					<img class="close" @click="mobileMenu = !mobileMenu" src="@/assets/images/img/close.png"/>
				</div>
				
				<div class="mobileMenuHide">
					<div class="samemobil" :class="mobilIndex == index?'samemobilMore':''" 
					v-for="(item,index) in navList" :key="index">
						<div class="samemobilTop">
							<router-link :to="item.src || ''" class="firstButton">{{item.title}}</router-link>
							<img v-if="item.list.length>0" @click="mobilIndexFun(index,item.list.length)" :class="mobilIndex == index?'moreMobileMore':''" class="moreMobile" src="@/assets/images/img/moreMobile.png"/>
						</div>
						<div class="samemobilhide" v-if="item.list.length>0">
							<div class="secondaryButton"  v-for="(sitem,sindex) in item.list" :key="sindex"
							@click="openEducation" v-if="index == 2 && sindex == 4">{{sitem.title}}</div>
							<router-link class="secondaryButton"  v-for="(sitem,sindex) in item.list" :key="sindex"
							v-if="!((index == 6 && sindex == 1)||(index == 2 && sindex == 4) || (index == 2 && sindex == 3))"
							:to="index == 0?(item.src+'?index='+sindex+'&navIndex='+sindex+'&currentTime='+currentTime):(sitem.src?sitem.src:(item.src || ''))">{{sitem.title}}</router-link>
							<router-link class="secondaryButton"  v-for="(sitem,sindex) in item.list" :key="sindex"
							v-if="index == 2 && sindex == 3 && education_video_show == 1"
							:to="index == 0?(item.src+'?index='+sindex+'&navIndex='+sindex+'&currentTime='+currentTime):(sitem.src?sitem.src:(item.src || ''))">{{sitem.title}}</router-link>
							
						</div>
					</div>
					<div class="infoBox" v-if="!login">
						<!-- <router-link class="logOn discen" to="/login">{{ $t('login.signIn') }}</router-link>
						<router-link class="register discen" to="/register">{{ $t('login.signUp') }}</router-link> -->
						<a class="logOn discen" :href="loginUrl" target="_blank">{{ $t('login.signIn') }}</a>
						<a class="register discen" :href="registerUrl" target="_blank">{{ $t('login.signUp') }}</a>
					</div>
					<div class="headerTips" v-if="login">
						<span>{{ $t('login.tips') }}</span>
						<span @click="signOutFun">{{ $t('login.signOut') }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	export default {
	  name:'topheader',
	  components: {
		
	  },
	  data() {
	  	return{
			fadeIn:false,
			language:2,
			mobilIndex:null,
			mobileMenu:false,
			languageshow:false,
			login:false,
			loginUrl:'',
			registerUrl:'',
			educationUrl:'',
			navList:[],
			currentTime:'',
			education_video_show:0
		}
	  },
	  mounted() {
	      // console.log('mounted')
		  window.addEventListener('scroll', this.handleScrollbox, true)
	  },
	  created() {
			// console.log('created')
			// console.log(this.$t('nav'))
			this.navList = this.$t('nav');
			// var setVal = setInterval(function(){
			// 	if(localStorage.getItem('newProduct')){
			// 		this.navList[0].list = JSON.parse(localStorage.getItem('newProduct'));
			// 		clearInterval(setVal)
			// 	}
			// },1000)
			if(localStorage.getItem('newProduct')){
				this.navList[0].list = JSON.parse(localStorage.getItem('newProduct'));
			}
			this.currentTime = new Date().getTime();
			this.language = localStorage.getItem('lang') == 'zh'?1:2;
			this.login = localStorage.getItem('token')?true:false;
			this.getCommonConfig();
			
	  },
	  watch:{
	  	$route(to,from) {
			this.mobileMenu = false;
			// window.location.reload();
	  	},
	  },
	  methods:{
		  // 打开教育视频
		  openEducation(){
			  window.open(this.educationUrl, '_blank');
		  },
		  //获取登录注册地址
		  getCommonConfig(){
			  this.$http.get(this.$apiBaseUrl+'getCommonConfig?key=education_video_show').then((res)=>{
				  // 1显示  0 隐藏
			  	this.education_video_show = res.data.svalue;
				console.log('education_video_show:',this.education_video_show)
			  }).catch((err)=>{
			     console.log(err) 
			     loading.close();
			  })
		  	this.$http.get(this.$apiBaseUrl+'getCommonConfig?key=login_url').then((res)=>{
		  		this.loginUrl = res.data.svalue;
		  	}).catch((err)=>{
		  	   console.log(err) 
		  	   loading.close();
		  	})
			this.$http.get(this.$apiBaseUrl+'getCommonConfig?key=regist_url').then((res)=>{
				this.registerUrl = res.data.svalue;
			}).catch((err)=>{
			   console.log(err) 
			   loading.close();
			})
			var url = localStorage.getItem('lang') == 'zh'?'getCommonConfig?key=live_room_address_zh':'getCommonConfig?key=live_room_address_en';
			this.$http.get(this.$apiBaseUrl+url).then((res)=>{
				this.educationUrl = res.data.svalue;
				localStorage.setItem('liveAddress',res.data.svalue);
			}).catch((err)=>{
			   console.log(err) 
			   loading.close();
			})
			
		  },
		  signOutFun(){
			  this.$confirm(this.$t('login.signtips'), this.$t('element.prompt'), {
				confirmButtonText: this.$t('element.confirm'),
				cancelButtonText: this.$t('element.cancel'),
				type: 'warning'
			  }).then(() => {
				  localStorage.setItem('token',"");
				this.$message({
				  type: 'success',
				  message: 'success',
				  duration:2000
				});
				setTimeout(function(){
					window.location.reload();
					window.scrollTo(0,0);
				},1500)
			  }).catch(() => {
				          
			  });
		  },
		  mobilIndexFun(index,length){
			  if(this.mobilIndex == index){
					this.mobilIndex = null;
			  }else{
				  this.mobilIndex = index;
			  }
			  console.log(this.mobilIndex)
		  },
		  handleScrollbox() {
			  var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
			  // console.log(top)
			  if(top>200){
				  this.fadeIn = true;
			  }else{
				  this.fadeIn = false;
			  }
		  },
		  langChange(value,type){
			  this.language = value;
			  if(type == 2){
				  console.log(this.languageshow)
				  this.languageshow = !this.languageshow;
			  }
			  if(value==1){
				this.$i18n.locale = "zh";
				localStorage.setItem('lang',"zh");
			  }else{
				this.$i18n.locale = "en";
				localStorage.setItem('lang',"en");
			  }
			  localStorage.removeItem('newProduct');
			  window.location.reload();
			  window.scrollTo(0,0);
		  },
	  }	
	}
</script>

<style lang="scss" scoped>
	.mobileheader{
		display:none;
	}
	.header{
		width: 100%;
		height: 0.7rem;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 10002;
		padding: 0 0.7rem 0 0.64rem;
		display: flex;
		align-items: center;
		.logo{
			width: 1.17rem;
			height: 0.38rem;
			display: block;
			.logoImg{
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}
	.headerColor{
		background-color: #0D0A0A;
		transition: all 0.3s;
	}
	.navBox{
		width: auto;
		height: auto;
		margin-left: 0.35rem;
		.sameNavList{
			width: auto;
			height: 0.70rem;
			position: relative;
			margin-right: 0.22rem;
		}
		.firstButton{
			width: auto;
			height: 0.45rem;
			line-height: 0.45rem;
			font-size: 0.16rem;
			color: #FFFFFF;
			font-family: 'Roboto';
		}
		.navHideBox{
			min-width: 2.10rem;
			width: auto;
			height: auto;
			background: #FFFFFF;
			box-shadow: 0 0.03rem 0.30rem rgba(155,158,162,0.16);
			opacity: 1;
			border-radius: 0.06rem;
			padding: 0.05rem 0.30rem;
			position: absolute;
			left: 0;
			top:0.70rem;
			z-index: 11;
			transition: 0.2s 0s;
			display:none;
			.secondaryButton{
				width: 100%;
				height: 0.4rem;
				line-height: 0.40rem;
				font-size: 0.16rem;
				color: #202020;
				display: block;
				white-space: nowrap;
			}
			.secondaryButton:hover{
				color: #D51820;
			}
		}
	}
	.sameNavList:hover .navHideBox,.navHideBox:hover{
		display: block;
		transition: 0.2s 0s;
	}
	.headerRight{
		width: auto;
		margin-left: auto;
		.customerService{
			width: 0.18rem;
			height: 0.18rem;
			margin-right: 0.17rem;
		}
		.language{
			width: auto;
			height: 0.70rem;
			position: relative;
			.languageTitle{
				width: auto;
				height: 0.45rem;
				font-size: 0.16rem;
				color: #FFFFFF;
				cursor:pointer;
				img{
					width: 0.20rem;
					height: 0.14rem;
					margin-right: 0.05rem;
				}
			}
			.languageHide{
				width: 2.10rem;
				height: auto;
				background: #FFFFFF;
				box-shadow: 0 0.03rem 0.3rem rgba(155,158,162,0.16);
				opacity: 1;
				border-radius: 0.06rem;
				padding: 0.05rem 0.10rem 0.05rem 0.30rem;
				position: absolute;
				left: 0;
				top:0.70rem;
				z-index: 11;
				transition: 0.2s 0s;
				display:none;
				.languageSame{
					width: 100%;
					height: 0.40rem;
					font-size: 0.16rem;
					cursor:pointer;
					color: #202020;
					img{
						width: 0.20rem;
						height: 0.14rem;
						margin-right: 0.05rem;
					}
				}
			}
		}
		.logOn{
			width: 1rem;
			height: 0.36rem;
			background: #D51820;
			border-radius: 0.19rem;
			font-size: 0.16rem;
			color: #FFFFFF;
			margin: 0 0.24rem;
		}
		.register{
			width: 1rem;
			height: 0.36rem;
			border: 1px solid #D51820;
			border-radius: 0.19rem;
			font-size: 0.16rem;
			color: #D51820;
		}
		.headerTips{
			font-size: 0.16rem;
			color: #D51820;
			margin-left: 0.5rem;
			font-weight: 700;
			>span{
				margin-right: 0.2rem;
			}
			>span:last-child{
				cursor: pointer;
			}
		}
	}
	.language:hover .languageHide,.languageHide:hover{
		display: block;
		transition: 0.2s 0s;
	}
	@media only screen and (max-width:1080px) {
	    /* For mobile phones: */
		.header{
			display: none;
		}
		.mobileheader{
			display: flex;
			width: 100%;
			height:0.9rem;
			background-color: #0D0A0A;
			transition: all 0.3s;
			padding:0 0.2rem;
			justify-content: space-between;
			align-items: center;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 10002;
			.logo{
				width: 1.5rem;
				height: auto;
				display: block;
				.logoImg{
					width: 100%;
					height: auto;
					display: block;
				}
			}
			.menu{
				width: 0.48rem;
				height: 0.48rem;
				display: block;
				>img{
					width: 100%;
					height: 100%;
					display: block;
				}
			}
			.mobileMenu{
				width: 60%;
				height:100%;
				background-color: #FFFFFF;
				position: fixed;
				right: -60%;
				top:0;
				z-index: 11;
				padding:1rem 0;
				transition: all 0.3s;
				.mobilelanguage{
					width: 100%;
					height:1rem;
					padding:0 0.3rem;
					border-bottom: 1px solid #F3F3F3;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 12;
					.language{
						width: auto;
						height: 0.70rem;
						position: relative;
						.languageTitle{
							width: auto;
							height: 0.7rem;
							font-size: 0.16rem;
							color: #202020;
							cursor:pointer;
							img{
								width: 0.40rem;
								height: 0.28rem;
								margin-right: 0.15rem;
							}
						}
						.languageHide{
							width: 3rem;
							height: auto;
							background: #FFFFFF;
							border: 1px solid #F3F3F3;
							box-shadow: 0 0.03rem 0.3rem rgba(155,158,162,0.16);
							opacity: 1;
							border-radius: 0.06rem;
							padding: 0.05rem 0.10rem 0.05rem 0.30rem;
							position: absolute;
							left: 0;
							top:0.70rem;
							z-index: 11;
							transition: 0.2s 0s;
							display:none;
							.languageSame{
								width: 100%;
								height: 0.70rem;
								font-size: 0.16rem;
								cursor:pointer;
								color: #202020;
								img{
									width: 0.40rem;
									height: 0.28rem;
									margin-right: 0.15rem;
								}
							}
						}
						.languageshowView{
							display: block;
							transition: 0.2s 0s;
						}
					}
					
					.close{
						width: 0.48rem;
						height: 0.48rem;
					}
				}
				
				.mobileMenuHide{
					width: 100%;
					height:100%;
					overflow: hidden;
					overflow-y: scroll;
					.samemobil{
						width: 100%;
						height:0.8rem;
						overflow: hidden;
						transition: all 0.3s;
					}
					.samemobilMore{
						height:auto;
						transition: all 0.3s;
					}
					.samemobilTop{
						width: 100%;
						height:0.8rem;
						padding:0 0.3rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						>img{
							width: 0.35rem;
							height: 0.35rem;
							cursor: pointer;
							transition: all 0.3s;
						}
						.moreMobileMore{
							transform: rotate(90deg);
							transition: all 0.3s;
						}
						.firstButton{
							flex: 1;
							height:0.8rem;
							line-height: 0.8rem;
							font-size: 0.2rem;
							color: #202020;
							font-weight: 700;
						}
					}
					.samemobilhide{
						width: 100%;
						height: auto;
						.secondaryButton{
							width: 100%;
							height:0.8rem;
							padding:0 0.3rem;
							display: flex;
							align-items: center;
							font-size: 0.2rem;
							color: #202020;
							text-indent: 2em;
						}
					}
					.infoBox{
						width: 100%;
						height:0.9rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding:0 0.2rem;
						margin-top: 1rem;
						.logOn{
							width: 45%;
							height: 0.7rem;
							background: #D51820;
							border-radius: 0.35rem;
							font-size: 0.16rem;
							color: #FFFFFF;
						}
						.register{
							width: 45%;
							height: 0.7rem;
							border: 1px solid #D51820;
							border-radius: 0.35rem;
							font-size: 0.16rem;
							color: #D51820;
						}
						
					}
					.headerTips{
						width: 100%;
						height: 0.9rem;
						display: flex;
						align-items: center;
						font-size: 0.2rem;
						color: #D51820;
						padding:0 0.2rem;
						font-weight: 700;
						>span{
							margin-right: 0.2rem;
						}
						>span:last-child{
							cursor: pointer;
						}
					}
				}
			}
			.mobileMenuShow{
				right:0;
				transition: all 0.3s;
			}
		}
		
	   
	}
	
</style>