<template>
	<div id="Login">
		<HeaderAccount></HeaderAccount>
		<div class="loginBox discen">
			<div class="loginLeft">
				<div class="loginLeftTitle display" v-html="$t('aboutAccount.title')"></div>
				<div class="loginLeftDesc">{{ $t('aboutAccount.desc') }}</div>
			</div>
			<div class="loginRight discolcen">
				<div class="loginRightTitle">{{ $t('login.signIn') }}</div>
				<!-- <div class="sameForm">
					<div class="formTitle display">{{ $t('aboutAccount.accountNumber') }}:</div>
					<input class="inputBox" type="text"  placeholder=""/>
				</div>
				<div class="sameForm">
					<div class="formTitle display">{{ $t('aboutAccount.password') }}:</div>
					<div class="sameInput disbet">
						<input class="inputpassword" v-if="ispassword" type="text"  placeholder=""/>
						<input class="inputpassword" v-if="!ispassword" type="password"  placeholder=""/>
						<img class="ispassword" @click="ispasswordFun" v-if="ispassword" src="@/assets/images/img/display.png"/>
						<img class="ispassword" @click="ispasswordFun" v-if="!ispassword" src="@/assets/images/img/hide.png"/>
					</div>
				</div> -->
				<div class="sameForm">
					<div class="formTitle display">{{ $t('aboutAccount.accountNumberNew') }}:</div>
					<input class="inputBox" v-model="testForm.username" type="text"  placeholder=""/>
				</div>
				<div class="sameForm">
					<div class="formTitle display">{{ $t('aboutAccount.password') }}:</div>
					<div class="sameInput disbet">
						<input class="inputpassword" v-model="testForm.password" :type="ispassword?'text':'password'"  placeholder=""/>
						<img class="ispassword" @click="ispasswordFun" :src="ispassword?require('@/assets/images/img/display.png'):require('@/assets/images/img/hide.png') "/>
					</div>
				</div>
				<div class="forGet">
					<!-- <router-link class="forGeta" to="/forget">{{ $t('aboutAccount.forget') }}?</router-link> -->
				</div>
				<div class="submit discen" @click="submit">{{ $t('login.signIn') }}</div>
				<div class="signUp discen">
					<span>{{ $t('aboutAccount.noAccount') }}</span>
					<router-link class="signUpa" to="/register">{{ $t('login.signUp') }}</router-link>
				</div>
				<div class="loginRightBottm">
					{{ $t('element.loginTips') }}
					<router-link to="/risk?id=1" class="footerRiskDiv">{{ $t('element.word1') }}</router-link>、
					<router-link to="/risk?id=2" class="footerRiskDiv">{{ $t('element.word2') }}</router-link>、
					<router-link to="/risk?id=3" class="footerRiskDiv">{{ $t('element.word3') }}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderAccount  from '../components/layout/headerAccount';
	export default {
		name: 'Login',
		components:{
		        HeaderAccount
		    },
		data() {
			return {
				ispassword:false,
				testForm:{
					username:"",
					password:""
				}
			}
		},
		mounted() {

		},
		created() {
			
		},
		methods: {
			ispasswordFun(){
				this.ispassword = !this.ispassword;
			},
			submit(){
				console.log(111)
				if(!this.testForm.username){
					this.$message({
					  message: this.$t('contactUs.tips')+this.$t('aboutAccount.accountNumberNew'),
					  type: 'error',
					  duration:3000
					});
					return;
				}
				if(!this.testForm.password){
					this.$message({
					  message: this.$t('contactUs.tips')+this.$t('aboutAccount.password'),
					  type: 'error',
					  duration:3000
					});
					return;
				}
				const loading = this.$loading({
				  lock: true,
				  text: '',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.3)'
				});
				// axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8;';
				// config.headers['Content-Type'] = 'application/json;charset=UTF-8;';
				this.$http.post('member/login',this.testForm).then((res)=>{
					console.log(res)
					localStorage.setItem('token',res.data.token);
					loading.close();
				　　this.$message({
					  message: res.msg,
					  type: 'success',
					  duration:3000
					});
					var that = this;
					setTimeout(function(){
						that.$router.push('/')
					},2500)
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			
		}
	}
</script>
<style>
	.el-icon-loading{
		font-size: 1rem;
	}
</style>
<style lang="scss" scoped>
	
	#Login{
		width: 100%;
		height: 100%;
		background-image: url('/src/assets/images/img/loginBg.png');
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		
		.loginBox{
			width: auto;
			height: auto;
			overflow: hidden;
			border-radius: 0.18rem;
			.loginLeft{
				width: 5.2rem;
				height: 6rem;
				background: #D51820;
				box-shadow: 0px 0px 40px rgba(184,187,192,0.16);
				opacity: 0.72;
				padding: 1.2rem 0.73rem 0;
				.loginLeftTitle{
					width: 100%;
					height: auto;
					margin-bottom: 0.2rem;
					font-size: 0.4rem;
					color: #FFFFFF;
					font-weight: 700;
				}
				.loginLeftDesc{
					width: 100%;
					height: auto;
					font-size: 0.19rem;
					color: #FFFFFF;
					line-height: 0.4rem;
				}
			}
			.loginRight{
				width: 5.20rem;
				height: 6rem;
				background: #FFFFFF;
				padding: 0 0.5rem 0.5rem;
				position: relative;
				.loginRightBottm{
					width: 100%;
					height: 0.5rem;
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 11;
					font-size: 0.14rem;
					padding: 0 0.2rem;
					color: #202020;
					text-align: center;
					>a{
						color: #D51820;
					}
				}
				.loginRightTitle{
					width: 100%;
					height: 0.4rem;
					font-size: 0.34rem;
					color: #202020;
					font-weight: 700;
				}
				.sameForm{
					width: 100%;
					height: auto;
					margin-top: 0.3rem;
					.formTitle{
						width: 100%;
						height: 0.4rem;
						font-size: 0.16rem;
						color: #202020;
					}
					.inputBox{
						width: 100%;
						height: 0.54rem;
						background: #F3F3F3;
						border-radius: 2px;
						padding: 0 0.2rem;
						font-size: 0.16rem;
						color: #202020;
						margin: 0;
						display: block;
					}
					.sameInput{
						width: 100%;
						height: 0.54rem;
						background: #F3F3F3;
						border-radius: 2px;
						padding: 0 0.2rem;
						font-size: 0.16rem;
						color: #202020;
						.inputpassword{
							width: 80%;
							height: 0.54rem;
							font-size: 0.16rem;
							color: #202020;
							margin: 0;
							display: block;
						}
						.ispassword{
							width: 0.44rem;
							height: 0.44rem;
							cursor: pointer;
						}
					}
				}
				.forGet{
					width: 100%;
					height: 0.5rem;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					.forGeta{
						width: auto;
						height: 0.5rem;
						font-size: 0.14rem;
						color: #BEBEBE;
						line-height: 0.5rem;
					}	
				}
				.submit{
					width: 100%;
					height: 0.54rem;
					background: #D51820;
					border-radius: 2px;
					font-size: 0.16rem;
					color: #FFFFFF;
					margin-top: 0.3rem;
				}
				.signUp{
					width: 100%;
					height: 0.5rem;
					font-size: 0.14rem;
					color: #BEBEBE;
					.signUpa{
						width: auto;
						height: 0.5rem;
						line-height: 0.5rem;
						font-size: 0.16rem;
						color: #D51820;
					}
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		#Login{			
			.loginBox{
				width:100%;
				padding:0 0.5rem;
				.loginLeft{
					display: none;
				}
				.loginRight{
					width: 100%;
					min-height: 6rem;
					height: auto;
					padding: 0.5rem;
					border-radius: 0.2rem;
					.loginRightTitle{
						width: 100%;
						height: 0.8rem;
					}
					.sameForm{
						width: 100%;
						height: auto;
						margin-top: 0.3rem;
						.formTitle{
							width: 100%;
							height: 0.8rem;
						}
						.inputBox{
							width: 100%;
							height: 0.8rem;
						}
						.sameInput{
							width: 100%;
							height: 0.8rem;
							.inputpassword{
								height: 0.8rem;
							}
							.ispassword{
								width: 0.44rem;
								height: 0.44rem;
								cursor: pointer;
							}
							.sameRight{
								width: 0.95rem;
								height: 0.4rem;
							}
						}
					}
					.forGet{
						width: 100%;
						height: 0.7rem;
						.forGeta{
							width: auto;
							height: 0.5rem;
							font-size: 0.14rem;
							color: #BEBEBE;
							line-height: 0.5rem;
						}	
					}
					.submit{
						width: 100%;
						height: 0.7rem;
					}
					.signUp{
						width: 100%;
						height: 0.6rem;
						.signUpa{
							width: auto;
							height: 0.6rem;
							line-height: 0.6rem;
						}
					}
				}
			}
		}
		
	}
</style>