<template>
	<div id="markets">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- banner -->
		<div class="marketsbanner" v-if="mainJson.detail" 
		:class="'banner'+navIndex" :style="{backgroundImage:'url('+ mainJson.detail.block1.img+')'}">
			<div class="bannerBox">
				<div class="bannerTitle" v-html="mainJson.detail.block1.title"></div>
				<div class="bannerDesc" v-html="mainJson.detail.block1.desc"></div>
				<div class="bannerNot"></div>
				<div @click.stop="openAccount(1)" class="bannerButton discen" v-if="navIndex != 2 && navIndex != 3">
					<span>{{ $t('element.marketsBannerTitle') }}</span>
					<img class="bannerMore" src="@/assets/images/img/more.png"/>
				</div>
			</div>
		</div>
		<!-- 板块2 -->
		<div class="plateTwo" v-if="mainJson.detail">
			<div class="marketsTitle" v-html="mainJson.detail.block2.title"></div>
			<div class="marketsDesc" v-html="mainJson.detail.block2.desc"></div>
			<div class="plateTwoBox">
				<div class="sameplateTwo" v-for="(item,index) in mainJson.detail.block2.list" :key="index">
					<div class="sameplateTwoTitle" v-html="item.title"></div>
					<div class="sameplateTwodesc" v-html="item.desc"></div>
				</div>
			</div>
		</div>
		<!-- 板块3 -->
		<div class="plateThree" v-if="mainJson.detail">
			<div class="marketsDesc" v-html="mainJson.detail.block3.title"></div>
			<div class="plateThreeBox">
				<img class="leftImg" :src="mainJson.detail.block3.img"/>
				<div class="plateBox">
					<div class="plateTitle">{{ $t('element.marketsThreeTitle') }}</div>
					<div class="samePlate display" v-for="(item,index) in mainJson.detail.block3.desc" :key="index">
						<i class="samePlatedrop"></i>
						<span>{{item}}</span>
					</div>
					<div @click.stop="openAccount(1)" class="plateMore discen">
						<span>{{ $t('element.marketsBannerTitle') }}</span>
						<img class="moreImg" src="@/assets/images/img/more.png"/>
					</div>
				</div>
			</div>
		</div>
		<!-- 板块4 -->
		<div class="plateFour" v-if="mainJson.detail">
			<div class="marketsTitle" v-html="mainJson.detail.block4.title"></div>
			<div class="plateFourBox">
				<div class="sameplateFour" :class="'sameplateFour'+navIndex" v-for="(item,index) in mainJson.detail.block4.list" :key="index">
					<img class="sameplateFourImg" :src="item.img"/>
					<div v-html="item.title"></div>
					<div class="desc" v-html="item.desc"></div>
				</div>
			</div>
		</div>
		<!-- 板块5 -->
		<!-- navindex0 -->
		
		<!-- {{$t('marketTab['+navIndex+'].list')}} -->
		<div class="plateFive plateFiveZero" v-if="mainJson.detail">
			<div class="marketsTitle" v-html="mainJson.detail.block5.title"></div>
			<div class="plateFiveBox">
				<div class="plateFiveTab">
					<div class="sameTab" :class="tabIndex == index?'sameTabSelect':''" 
					v-for="(item,index) in mainJson.detail.block5.list" :key="index" @click="tabFun(index)">
						<span v-html="item.title"></span>
						<div></div>
					</div>
				</div>
				<div class="plateFiveTabBox">
					<div class="plateFiveTabBoxTitle">{{mainJson.detail.block5.list[tabIndex].title}}</div>
					<div class="sameFiveList">
						<div class="smaeTabBox" v-for="(item,index) in mainJson.detail.block5.list[tabIndex].list"  v-if="index == 0":key="index">
							<div class="fiveLeft">{{item.value}}</div>
							<div class="fiveRight">{{item.value1}}</div>
						</div>
						<div class="smaeTabBoxLine" v-for="(item,index) in mainJson.detail.block5.list[tabIndex].list"  v-if="index > 0":key="index">
							<div>{{item.value}}</div>
							<div>{{item.value1}}</div>
						</div>
						<div class="tips" v-html="mainJson.detail.block5.desc"></div>
					</div>
				</div>
			</div>

		</div>

		<div class="plateFive" style="padding: 0;">
			<div class="plateFiveBox">
				<div id="quotesWidgetOverview"></div>
			</div>
		</div>
		<!-- 板块6 -->
		<div class="plateSix">
			<div class="samePlateSix" v-for="(item,index) in sixList" :key="index"
			:class="item.show?'samePlateSixSe':''">
				<div class="samePlateSixTitle" @click="showPlateSix(index)">
					<span>{{item.title}}</span>
					<img :class="item.show?'samePlateSixImg':''" src="@/assets/images/markets/dropdown.png"/>
				</div>
				<div class="samePlateSixDesc">
					<div class="samesixDec" v-html="item.desc"></div>
					<div class="ifSixList" v-if="item.list" v-for="(sitem,sindex) in item.list" :key="sindex">
						<div class="ifSixListTitle" v-html="sitem.title"></div>
						<div class="ifSixListDesc" v-html="sitem.desc"></div>
					</div>
				</div>
			</div>
		</div>
		<Tips :clickCcountAll="clickCcountAll" @openAccountAllFun="openAccountAllFun"></Tips>
	</div>
</template>

<script>
	import Tips  from '../components/layout/tips';
	export default {
		name: 'markets',
		components:{
			Tips
		},
		data() {
			return {
				clickCcountAll:false,
				navIndex:0,
				tabIndex:0,
				index:0,
				sixList:[],
				mainJson:{}
			}
		},
		mounted() {
			// this.scriptFun();
			this.navIndex = this.$route.query.navIndex || 0;
			this.index = this.$route.query.index || 0;
			this.getJsonConfig();
			// this.sixList = this.$t('markets['+this.navIndex+'].six');
			// var that = this;
			
			// window.addEventListener('setItemEvent', function(e) {
			//       // const newdata = JSON.parse(e.newValue)
			//    that.sixList = that.$t('markets['+that.navIndex+'].six');
			// })
		},
		created() {
			
			// console.log(localStorage.getItem('newProduct'))
			// console.log(this.markets[this.navIndex].six)
		},
		watch:{
			$route(to,from) {
				window.location.reload();
				// this.navIndex = this.$route.query.navIndex || 0;
				// this.sixList = this.$t('markets['+this.navIndex+'].six');
				// var that = this;
				// window.addEventListener('setItemEvent', function(e) {
				//       // const newdata = JSON.parse(e.newValue)
				//    that.sixList = that.$t('markets['+that.navIndex+'].six');
				// })
			},
		},
		methods: {
			getJsonConfig(){
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=index.json':'getJsonConfig?fileName=index_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.block4.list.length>0&&configData.block4.list[0].hasOwnProperty('sortNo')){
						configData.block4.list =  (configData.block4.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					this.mainJson = configData.block4.list[this.index];
					if(this.mainJson.detail.block6.list){
						this.sixList = this.mainJson.detail.block6.list;
						(this.sixList).forEach((item,index)=>{
							item['show'] = false;
						})
					}
					console.log('mainJson:',this.mainJson);
					if(this.mainJson.code){
						this.scriptFun(this.mainJson.code);
					}
					
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			
			scriptFun(code){
				// 创建一个新的script标签
				const script = document.createElement('script');
				// script.async = true;				
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				script.type = 'text/javascript';
				script.src = 'https://c.mql5.com/js/widgets/quotes/widget.js?v=1';
				script.dataset.type = 'quotes-widget';
				var filter = code.split(',');
				console.log(filter)
				var jsonScript = '{"type":"overview","style":"table","filter":"'+filter+'","width":"100%","height":"500","period":"D1","lang":"'+lang+'","id":"quotesWidgetOverview"}';
				script.text = jsonScript;
				// 将新创建的script标签添加到body中
				console.log(script.text)
				document.body.appendChild(script);
			},
			openAccountAllFun(){
				this.clickCcountAll = !this.clickCcountAll;
			},
			openAccount(type){
				// type:1 开户   2合作伙伴注册
				if(localStorage.getItem('token')){
					this.openAccountAllFun();
				}else{
					this.openAccountAll(type);
				}
			},
			tabFun(index){
				this.tabIndex = index;
			},
			showPlateSix(index){
				if(!this.sixList[index].show){
					this.sixList.forEach((item,i)=>{
						if(index == i){
							item.show = true
						}else{
							item.show = false
						}
					})
				}else{
					this.sixList[index].show = !this.sixList[index].show
				}
				this.$forceUpdate()
			}
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/assets/css/markets.css';
	
	.marketsbanner{
		width: 100%;
		height: 4.3rem;
		background-image: url('../assets/images/markets/forex01.png');
		background-size: cover;
		.bannerBox{
			width: 100%;
			height: 4.3rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			.bannerTitle{
				font-size: 0.44rem;
				color: #FFFFFF;
			}
			.bannerDesc{
				font-size: 0.16rem;
				color: #FFFFFF;
				margin:0.25rem 0 0.7rem;
				text-align: center;
			}
			.bannerNot{
				font-size: 0.18rem;
				color: #FFFFFF;
			}
			.bannerButton{
				width: 2.70rem;
				height: 0.46rem;
				background: #D51820;
				border-radius: 0.25rem;
				font-size: 0.16rem;
				color: #FFFFFF;
				margin:0.2rem auto 0.5rem;
				cursor: pointer;
				.bannerMore{
					width: 0.12rem;
					height:0.12rem;
					margin-left: 0.05rem;
				}
			}
		}
	}
	.banner1{
		// background-image: url('../assets/images/markets/indic01.png');
	}
	.banner2{
		// background-image: url('../assets/images/markets/commodities01.png');
		.bannerBox{
			justify-content: flex-start;
			padding-top: 0.9rem;
		}
	}
	.banner3{
		// background-image: url('../assets/images/markets/forex01.png');
	}
	.banner4{
		// background-image: url('../assets/images/markets/metals01.png');
		.bannerBox{
			justify-content: flex-start;
			padding-top: 0.9rem;
		}
	}
	.marketsTitle{
		width: 100%;
		height: auto;
		font-size: 0.32rem;
		font-weight: 700;
		color: #202020;
		text-align: center;
	}
	.marketsDesc{
		width: 100%;
		height: auto;
		font-size: 0.22rem;
		color: #202020;
		line-height: 0.3rem;
		text-align: center;
		margin-top: 0.2rem;
	}
	.plateTwo{
		width: 100%;
		height: auto;
		background-color: #F7F7F7;
		padding: 0.8rem 0.5rem 1rem;
		.plateTwoBox{
			width: 12rem;
			height: auto;
			margin: 0.9rem auto 0;
			display: flex;
			flex-wrap: wrap;
			/* justify-content: space-between; */
			.sameplateTwo{
				// width: 3.6rem;
				width: 30%;
				height: auto;
				margin-bottom: 0.5rem;
				// margin-right: 0.4rem;
				// margin-right: 3.33%;
				margin-left: 2.5%;
				.sameplateTwoTitle{
					width: 100%;
					height: 0.6rem;
					line-height: 0.3rem;
					font-size: 0.2rem;
					color: #29261C;
				}
				.sameplateTwodesc{
					width: 100%;
					height: auto;
					font-size: 0.16rem;
					color: #7F8284;
					line-height: 0.25rem;
				}
			}
		}
	}
	.plateThree{
		width: 100%;
		height: auto;
		padding: 1rem 0.5rem;
		.plateThreeBox{
			width: 12rem;
			height: auto;
			margin: 0.9rem auto 0;
			display: flex;
			justify-content: center;
			.leftImg{
				width: 6.3rem;
				height: 4.2rem;
			}
			.plateBox{
				flex: 1;
				height: 4.2rem;
				padding: 0 0 0 0.9rem;
				display: flex;
				flex-direction: column;
				justify-content:center;
				.plateTitle{
					width: 100%;
					height: auto;
					font-size: 0.32rem;
					color: #202020;
					font-weight: 500;
					line-height: 0.4rem;
					margin-bottom: 0.2rem;
				}
				.samePlate{
					width: 100%;
					height: auto;
					font-size: 0.16rem;
					color: #202020;
					margin-bottom: 0.12rem;
					.samePlatedrop{
						width: 0.06rem;
						height: 0.06rem;
						background: #D51820;
						border-radius: 50%;
						margin-right: 0.05rem;
					}
				}
				.plateMore{
					width: 2.5rem;
					height: 0.46rem;
					background: #D51820;
					border-radius: 0.25rem;
					font-size: 0.16rem;
					color: #FFFFFF;
					margin-top:0.3rem;
					padding: 0 0.2rem;
					cursor: pointer;
					.moreImg{
						width: 0.12rem;
						height: 0.12rem;
						margin-left: 0.05rem;
					}
				}
			}
		}
		
	}
	.plateFour{
		width: 100%;
		height: auto;
		padding: 0.5rem 0;
		.plateFourBox{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 0.7rem;
			.sameplateFour{
				width: 2.20rem;
				height: 2.70rem;
				background: #FFFFFF;
				box-shadow: 0px 3px 20px rgba(159,159,159,0.16);
				border-radius: 2px;
				padding: 0.62rem 0.3rem 0;
				margin-right: 0.25rem;
				font-size: 0.2rem;
				line-height: 0.25rem;
				color: #202020;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				.sameplateFourImg{
					width: 0.72rem;
					height: 0.72rem;
					margin-bottom: 0.24rem;
				}
				.desc{
					display: block;
					width: 100%;
					font-size: 0.12rem;
					color: #7F8284;
					line-height: 0.2rem;
					margin-top: 0.1rem;
					white-space: normal;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
				}
			}
			.sameplateFour1{
				width: 3.60rem;
				height: 3.70rem;
				margin-right: 0.6rem;
				padding: 1rem 0.4rem 0;
			}
			.sameplateFour2{
				width: 2.76rem;
				height: 3.12rem;
				margin-right: 0.3rem;
				padding: 0.7rem 0.2rem 0;
			}
			.sameplateFour4{
				width: 2.76rem;
				height: 3.12rem;
				margin-right: 0.3rem;
				padding: 0.4rem 0.2rem 0;
			}
		}
	}
	.plateFive{
		width: 100%;
		height: auto;
		padding: 0.8rem 0;
		.plateFiveBox{
			width: 12rem;
			height: auto;
			margin: 0.5rem auto 0;
			.plateFiveTab{
				width: 100%;
				height: 0.4rem;
				border-bottom: 1px solid #E4E4E4;
				display: flex;
				/* justify-content: space-between; */
				align-items: center;
				.sameTab{
					width: 2rem;
					height: 0.4rem;
					margin-right: 1rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 0.2rem;
					color: #202020;
					position: relative;
					cursor: pointer;
					>div{
						width: 1.20rem;
						height: 2px;
						background: #D51820;
						opacity: 0;
						position: absolute;
						left: 0.4rem;
						bottom: -1px;
						z-index: 1;
						transition: all 0.3s;
					}
				}
				.sameTabSelect{
					>div{
						opacity: 1;
						transition: all 0.3s;
					}
				}
			}
			.plateFiveTabBox{
				width: 100%;
				height: auto;
				padding: 0 0.3rem;
				.plateFiveTabBoxTitle{
					width: 100%;
					height: 1.2rem;
					font-size: 0.24rem;
					color: #202020;
					display: flex;
					align-items: center;
				}
				.plateFiveTabBoxdesc{
					width: 100%;
					height: auto;
					font-size: 0.16rem;
					color: #202020;
					line-height: 0.3rem;
					padding-bottom: 0.5rem;
				}
				.sameFiveList{
					width: 100%;
					height: auto;
					.smaeTabBox{
						width: 100%;
						height: 0.66rem;
						background-color: #ECEFF2;
						display: flex;
						align-items: center;
						>div{
							width: 50%;
							height: 0.66rem;
							/* line-height: 0.66rem; */
							display: flex;
							align-items: center;
							font-size: 0.2rem;
							color: #202020;
							padding: 0 0 0 0.4rem;
						}
					}
					.smaeTabBoxSeven{
						.smaeTabBoxdivse{
							width: 14.28%;
							padding: 0 0 0 0.2rem;
						}
						.smaeTabBoxdiveig{
							width: 12.5%;
							padding: 0 0 0 0.2rem;
						}
					}
					.smaeTabBoxFive{
						>div{
							width: 20%;
							padding: 0 0 0 0.2rem;
						}
					}
					.smaeTabBoxLine{
						width: 100%;
						height: 0.66rem;
						border-bottom: 1px solid #E4E4E4;
						display: flex;
						align-items: center;
						justify-content: space-between;
						cursor: pointer;
						>div{
							width: 50%;
							height: 0.66rem;
							/* line-height: 0.66rem; */
							font-size: 0.16rem;
							color: #202020;
							padding: 0 0 0 0.4rem;
							display: flex;
							align-items: center;
						}
					}
					.smaeTabBoxLineseven{
						.smaeTabBoxLinedivse{
							width: 14.28%;
							padding: 0 0 0 0.2rem;
						}
						.smaeTabBoxLinediveig{
							width: 12.5%;
							padding: 0 0 0 0.2rem;
						}
					}
					.smaeTabBoxLineFive{
						>div{
							width: 20%;
							padding: 0 0 0 0.2rem;	
						}
					}
					.tips{
						width: 100%;
						height: auto;
						font-size: 0.16rem;
						color: #202020;
						line-height: 0.3rem;
						margin-top: 0.3rem;
					}
				}
			}
		}
	}
	.plateSix{
		width: 12rem;
		height: auto;
		margin: 0.5rem auto 2.5rem;
		.samePlateSix{
			width: 100%;
			height: 1rem;
			border-bottom: 1px solid #EAECEC;
			overflow: hidden;
			transition: all 0.5s;
			.samePlateSixTitle{
				width: 100%;
				height: 1rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 0.2rem;
				color: #202020;
				>img{
					width: 0.2rem;
					height: 0.2rem;
					transition: all 0.3s;
				}
				.samePlateSixImg{
					transform: rotate(180deg);
					transition: all 0.3s;
				}
			}
			.samePlateSixDesc{
				width: 100%;
				height: auto;
				padding-bottom: 0.3rem;
				font-size: 0.16rem;
				color: #202020;
				line-height: 0.35rem;
				.ifSixListTitle{
					font-weight: 700;
				}
			}
		}
		.samePlateSixSe{
			height: auto;
			transition: all 0.5s;
		}
	}
	@media only screen and (max-width:1080px){
		.marketsbanner{
			height: auto;
			padding:1rem 0.3rem;
			.bannerBox{
				height: auto;
				padding-top: 0;
				.bannerTitle{
					font-size: 0.3rem;
					color: #FFFFFF;
					text-align: center;
				}
				.bannerDesc{
					font-size: 0.16rem;
					margin:0.25rem 0 0.3rem;
				}
				.bannerButton{
					width: 3rem;
					height: 0.5rem;
				}
			}
		}
		.plateTwo{
			.plateTwoBox{
				width: 100%;
				justify-content: space-between;
				.sameplateTwo{
					width: 48%;
					// margin-right: 0;
					margin-left: 0;
					.sameplateTwoTitle{
						width: 100%;
						height: 0.7rem;
						line-height: 0.3rem;
						font-weight: 700;
					}
				}
			}
		}
		.plateThree{
			padding: 1rem 0.3rem;
			.plateThreeBox{
				width: 100%;
				flex-direction: column;
				.leftImg{
					width: 100%;
					height: auto;
					display: block;
					margin: 0 auto;
				}
				.plateBox {
				    flex: 1;
				    height:auto;
				    padding: 0 0.3rem;
					margin-top: 0.5rem;
					.plateMore {
					    width: 3rem;
					    height: 0.5rem;
						margin: 0.3rem auto 0;
					}
				}
			}
			
		}
		.plateFour{
			padding: 0.5rem 0.3rem;
			.plateFourBox{
				justify-content: flex-start;
				flex-wrap: wrap;
				.sameplateFour{
					width: 30%;
					margin-right: 0rem;
					margin-left: 2.5%;
					font-size: 0.2rem;
					margin-bottom: 0.5rem;
				}
				.sameplateFour1{
					width: 45.5%;
					height: 3.70rem;
					margin-right: 0;
					margin-bottom: 0.5rem;
					margin-left: 3%;
				}
				.sameplateFour2{
					width: 30%;
					height: 3.12rem;
					margin-right: 0;
					margin-bottom: 0.5rem;
					padding: 0.4rem 0.2rem 0;
				}
				.sameplateFour4{
					width:45.5%;
					height: 3.12rem;
					margin-right: 0;
					margin-bottom: 0.5rem;
					padding: 0.4rem 0.2rem 0;
					margin-left: 3%;
				}
			}
		}
		.plateFive{
			width: 92%;
			overflow: hidden;
			overflow-x: scroll;
			margin: 0 auto;
			.plateFiveBox{
				width: 12rem;
				.plateFiveTab{
					width: 100%;
					height: 0.8rem;;
					.sameTab{
						height: 0.8rem;
					}
				}
			}
		}
		
		.plateFive{
			width: 92%;
			overflow: hidden;
			overflow-x: scroll;
			margin: 0 auto;
			.plateFiveBox{
				.plateFiveTab{
					width: 100%;
					height: 0.8rem;
					.sameTab{
						width: 2.5rem;
						height: 0.8rem;
						margin-right: 0.5rem;
					}
					.sameTabSelect{
						>div{
							opacity: 1;
							transition: all 0.3s;
						}
					}
				}
				.plateFiveTabBox{
					.sameFiveList{
						width: 100%;
						height: auto;
						.smaeTabBox{
							width: 100%;
							height: 0.9rem;
							>div{
								width: 50%;
								height: 0.9rem;
								padding: 0 0 0 0.2rem;
							}
						}
	
						.smaeTabBoxLine{
							width: 100%;
							height: 0.9rem;
							>div{
								width: 50%;
								height: 0.9rem;
								font-size: 0.14rem;
								color: #202020;
								padding: 0 0 0 0.2rem;
								word-wrap: break-word;
								word-break:break-all;
							}
						}
					}
				}
			}
		}
		.plateFiveZero{
			overflow: hidden;
			overflow-x: hidden;
			.plateFiveBox{
				width: 100%;
				.plateFiveTab{
					width: 100%;
					height: 0.8rem;
					.sameTab{
						width:25%;
						height: 0.8rem;
						margin-right: 0;
					}
				}
			}
		}
		.plateSix{
			width: 100%;
			padding: 0 0.3rem;
		}
		
		
		
		
	}
</style>