<template>
	<!-- :class="fadeIn?'headerColor':''" -->
	<div class="header disbet">
		<router-link class="logo" to="/">
			<img class="logoImg" src="@/assets/images/img/logo.png"/>
		</router-link>
		<div class="language display">
			<div class="languageTitle display" @click="languageshow = !languageshow">
				<img v-if="language == 1" src="@/assets/images/img/zh.png" />
				<img v-else src="@/assets/images/img/en.png" />
				<span>{{language == 1?$t('language.zh'):$t('language.en')}}</span>
			</div>
			<div class="languageHide" v-if="languageshow">
				<div class="languageSame display" @click="langChange(2)">
					<img src="@/assets/images/img/en.png"/>
					<span>{{ $t('language.en') }}</span>
				</div>
				<div class="languageSame display" @click="langChange(1)">
					<img src="@/assets/images/img/zh.png"/>
					<span>{{ $t('language.zh') }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  name:'headerAccount',
	  components: {
		
	  },
	  data() {
	  	return{
			language:2,
			languageshow:false,
		}
	  },
	  mounted() {

	  },
	  created() {
			this.language = localStorage.getItem('lang') == 'zh'?1:2;
			this.languageshow = false;
	  },

	  methods:{
		  langChange(value){
			  this.language = value;
			  this.languageshow = !this.languageshow;
			  if(value==1){
				this.$i18n.locale = "zh";
				localStorage.setItem('lang',"zh");
			  }else{
				this.$i18n.locale = "en";
				localStorage.setItem('lang',"en");
			  }
			  window.location.reload();
			  window.scrollTo(0,0);
		  },
	  }	
	}
</script>

<style lang="scss" scoped>
	.header{
		width: 100%;
		height: 0.7rem;
		padding:0 3.2rem 0 0.64rem; 
		position: fixed;
		left: 0;
		top:0;
		z-index: 11;
		.logo{
			width: 1.17rem;
			height: 0.38rem;
			display: block;
			.logoImg{
				width: 100%;
				height: 100%;
				display: block;
			}
		}
		.language{
			width: auto;
			height: 0.70rem;
			position: relative;
			.languageTitle{
				width: auto;
				height: 0.45rem;
				font-size: 0.16rem;
				color: #FFFFFF;
				cursor:pointer;
				img{
					width: 0.20rem;
					height: 0.14rem;
					margin-right: 0.05rem;
				}
			}
			.languageHide{
				width: 2.10rem;
				height: auto;
				background: #FFFFFF;
				box-shadow: 0 0.03rem 0.3rem rgba(155,158,162,0.16);
				opacity: 1;
				border-radius: 0.06rem;
				padding: 0.05rem 0.10rem 0.05rem 0.30rem;
				position: absolute;
				left: 0;
				top:0.70rem;
				z-index: 11;
				transition: 0.2s 0s;
				.languageSame{
					width: 100%;
					height: 0.40rem;
					font-size: 0.16rem;
					cursor:pointer;
					color: #202020;
					img{
						width: 0.20rem;
						height: 0.14rem;
						margin-right: 0.05rem;
					}
				}
			}
		}
	}
	.language:hover .languageHide,.languageHide:hover{
		display: block;
		transition: 0.2s 0s;
	}
	@media only screen and (max-width:1080px){
		.header{
			width: 100%;
			height: 0.9rem;
			padding:0 1.5rem 0 0.64rem !important;
			 .logo{
			 	width: 1.5rem;
			 	height: auto;
			 	display: block;
			 	.logoImg{
			 		width: 100%;
			 		height: auto;
			 		display: block;
			 	}
			 }
			 .language{
			 		width: auto;
			 		height: 0.70rem;
			 		position: relative;
			 		.languageTitle{
			 			height: 0.6rem;
			 			font-size: 0.2rem;
			 			img{
			 				width: 0.30rem;
			 				height: auto;
							display: block;
			 				margin-right: 0.15rem;
			 			}
			 		}
			 		.languageHide{
			 			.languageSame{
			 				width: 100%;
			 				height: 0.60rem;
			 				font-size: 0.16rem;
			 				cursor:pointer;
			 				color: #202020;
			 				img{
			 					width: 0.30rem;
			 					height: auto;
			 					display: block;
			 					margin-right: 0.15rem;
			 				}
			 			}
			 		}
			 	}
			 
		}
		
	}
</style>