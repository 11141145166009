<template>
	<div id="Index">
		<!-- banner -->
		<div class="banner" v-if="mainJson.banners">
			<div class="swiper-container swiper-banner">
				<div class="swiper-wrapper banner-wrapper">
					<div class="swiper-slide samebanner discolcen" v-for="(item,index) in mainJson.banners" :key="index"
					:style="{backgroundImage:'url('+item.img+')'}" @click.stop="openLink(item)">
						<div class="bannerTitle" v-html="item.title"></div>
						<div class="bannerText" v-html="item.desc"></div>
						<div class="buttonBox discen">
							<div class="buttonleft discen" @click.stop="bannerJump(1)">{{ $t('bannerButton.center') }}</div>
							<!-- <div class="buttonleft discen" @click.stop="openAccount(1)">{{ $t('bannerButton.left') }}</div>
							<div class="buttonright discen" @click.stop="openAccount(2)">{{ $t('bannerButton.right') }}</div> -->
							<!-- <router-link class="buttonleft discen" to="">{{ $t('bannerButton.left') }}</router-link>
							<router-link class="buttonright discen" to="">{{ $t('bannerButton.right') }}</router-link> -->
						</div>
					</div>
				</div>
				<!-- 如果需要分页器 -->
				<div class="swiper-pagination"></div>
			</div>
		</div>
		<!-- banner下的数据 -->
		<div class="webData discen" v-if="mainJson.block1">
			<div class="smaeBannerData discolcen" v-for="(item,index) in mainJson.block1" :key="index">
				<span class="smaeBannerDataSpan" v-html="item.title"></span>
				<i class="smaeBannerDataI"></i>
				<div v-html="item.desc"></div>
			</div>
		</div>
		<!-- 板块2 -->
		<div class="plateTwo" v-if="mainJson.block2">
			<img class="plateTwoLeft" :src="mainJson.block2.img" v-if="mainJson.block2.img"/>
			<div class="plateBox">
				<div class="plateTitle" v-html="mainJson.block2.title"></div>
				<div class="samePlate display" v-for="(item,index) in mainJson.block2.desc" :key="index">
					<i class="samePlatedrop"></i>
					<span>{{item}}</span>
				</div>
				<router-link to="/tradingPlatform" class="plateMore discen">
					<span>{{ $t('plateTwo.more') }}</span>
					<img class="moreImg" src="@/assets/images/img/more.png"/>
				</router-link>
			</div>
		</div>
		<!-- 支持 -->
		<div class="supportBox" v-if="mainJson.block3">
			<div class="indexTitle" v-html="mainJson.block3.title"></div>
			<div class="support discen">
				<a v-for="(item,index) in mainJson.block3.buttons" :key="index" :href="item.link" target="_blank" class="sameSupport discen">
					<img class="supportImg" :src="item.icon"/>
					<span v-html="item.title"></span>
				</a>
			</div>
		</div>
		<!-- 板块3 -->
		<div class="plateThree" v-if="mainJson.block4">
			<div class="indexTitle" v-html="mainJson.block4.title"></div>
			<div class="plateThreeBox discen">
				<router-link :to="'/markets?index='+index+'&navIndex='+index+'&currentTime='+currentTime" class="plateThreeSame discolcen" v-for="(item,index) in mainJson.block4.list" :key="index">
					<!-- <img class="plateThreeSameImg" src="@/assets/images/img/plateThree01.png"/> -->
					<img class="plateThreeSameImg" :src="item.icon"/>
					<span v-html="item.title"></span>
				</router-link>
			</div>
		</div>
		<!-- 板块4 -->
		<div class="plateFour" v-if="mainJson.block5">
			<div class="indexTitle" v-html="mainJson.block5.title"></div>
			<div class="plateFourBox discen">
				<div class="samePlateFour" v-for="(item,index) in mainJson.block5.list" :key="index">
					<img class="samePlateFourImg" :src="item.img"/>
					<div class="samePlateFourBox">
						<div class="samePlateFourTitle" v-html="item.title"></div>
						<div class="samePlateFourDesc" v-html="item.desc"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 板块5 -->
		<div class="plateFive" v-if="mainJson.block6">
			<div class="indexTitle" v-html="mainJson.block6.title"></div>
			<div class="indexSubTitle" v-html="mainJson.block6.desc"></div>
			<div class="plateFiveBox discen">
				<div class="plateFiveSame" :class="plateFiveIndex == index?'plateFiveSameClick':''" 
				v-for="(item,index) in mainJson.block6.list" :key="index" 
				@mouseover="plateFiveIndex = index" @click="accountJump()">
					<div class="plateFiveSameTitle" v-html="item.title"></div>
					<div class="plateFiveSameDesc" v-html="item.subTitle"></div>
					<div class="plateFiveSamePrice">
						<span class="plateFiveSamePriceNum">{{item.price}}</span>
						<span>/{{item.priceDesc}}</span>
					</div>
					<div class="plateFiveSameList display" v-for="(sitem,sindex) in item.desc" :key="sindex">
						<i class="samePlatedrop"></i>
						<span>{{sitem}}</span>
					</div>
				</div>
			</div>
			<div class="subTitle discen">{{ $t('plateFive.subtitle') }}</div>
			<div class="subDesc discen">{{ $t('plateFive.subDesc') }}</div>
			<router-link class="subLink discen" to="/contactUs">{{ $t('plateFive.customerManager') }}</router-link>
		</div>
		<!-- 板块6 -->
		<!-- <div class="plateSix">
			<div class="indexTitle">{{ $t('plateSix.title') }}</div>
			<div class="swiper-container swiper-plateSix">
				<div class="swiper-wrapper plateSix-wrapper">
					<div class="swiper-slide sameplateSix" 
					:style="{backgroundImage:'url('+require('@/assets/images/img/plateSixBg0'+item.id+'.png')+')'}"
					v-for="(item,index) in $t('plateSix.list')" :key="index">
						<div class="sameplateSixTitle">{{item.title}}</div>
						<div class="sameplateSixDesc" v-html="item.text"></div>
					</div>
				</div>
				<div class="swiper-pagination"></div>
			</div>
		</div> -->
		<div class="plateSix" v-if="mainJson.block7">
			<div class="indexTitle" v-html="mainJson.block7.title"></div>
			<div class="swiper-container swiper-plateSix">
				<div class="swiper-wrapper plateSix-wrapper">
					<div class="swiper-slide sameplateSixNew" v-for="(item,index) in mainJson.block7.banners" :key="index">
						<div class="sameplateSixLeft">
							<div class="sameplateSixTitle" v-html="item.title"></div>
							<div class="sameplateSixDesc" v-html="item.desc"></div>
						</div>
						<img class="sameplateSixRight" :src="item.img"/>
					</div>
				</div>
				<div class="swiper-pagination"></div>
			</div>
		</div>
		<!-- 板块7 -->
		<div class="plateSeven" v-if="mainJson.block8">
			<div class="indexTitle" v-html="mainJson.block8.title"></div>
			<div class="plateSevenBox">
				<div class="sameplateSeven" v-for="(item,index) in mainJson.block8.list" :key="index">
					<span class="sameplateSevenNum">{{index+1}}</span>
					<div class="sameplateSevenRight">
						<div class="sameplateSevenRightTitle" v-html="item.title"></div>
						<div class="sameplateSevenRightDesc" v-html="item.desc"></div>
					</div>
				</div> 
			</div>
		</div>
		<!-- 板块8 -->
		<!-- <div class="swiper-container swiper-plateEight">
			<div class="swiper-wrapper plateEight-wrapper">
				<div class="swiper-slide sameplateEight" 
				:style="{backgroundImage:'url('+require('@/assets/images/img/plateEightBg0'+item.id+'.png')+')'}"
				v-for="(item,index) in $t('plateEight')" :key="index">
					<div class="sameplateEightTitle">{{item.title}}</div>
					<div class="sameplateEightDesc">{{item.text}}</div>
				</div>
			</div>
			<div class="swiper-pagination"></div>
		</div> -->
		<!-- 新增板块 -->
		<div class="newSectorBox discen" v-if="mainJson.block9">
			<div class="newSector">
				<div class="newSectorLeft">
					<div class="title">{{$t('element.newsList')}}</div>
					<!-- disbet -->
					<router-link :to="'/marketingResearchDetails?id='+item.id" class="sectorList" v-for="(item,index) in newsList" :key="index">
						<div class="time">
							<span>{{item.month}}</span>
							<span>{{item.day}}</span>
						</div>
						<div class="sectorListRight">
							<div class="newsTitle" v-html="item.title"></div>
							<div class="newtime">{{item.author?item.author+'-':''}}{{item.newTime}}</div>
							<div class="newsDesc" v-html="item.remark"></div>
							<div class="imgBox">
								<img v-for="(sitem,sindex) in item.iconList" :key="sindex" :src="sitem"/>
							</div>
						</div>
					</router-link>
				</div>
				<div class="newSectorRight">
					<div class="title">{{mainJson.block9.right.title}}</div>
					<!-- <div class="tvBox" v-if="roomInfo.living && !iframeShow">
						<vue-aliplayer-v2
							width="100%" height="100%"
							:source="roomInfo.playUrl"
							:cover="roomInfo.cover"
							ref="VueAliplayerV2"
							:options="options"
						  />	  
					</div>
					<div class="tvBox" v-if="!roomInfo.living && !iframeShow">
						<img :src="roomInfo.cover" />
					</div> -->
					<div class="tvBox" v-if="iframeShow">
						<iframe width="100%" height="100%" :key="iframeKey" :src="iframeInfo.sourceUrl" :title="iframeInfo.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
					</div>
					<!-- <div class="newSectorList" :class="!iframeShow?'newSectorListSelect':''" v-if="roomInfo" @click="iframeShow = false">
						<div class="newSectorListTitle" v-html="roomInfo.name"></div>
						<div class="subtitle display">
							<img src="@/assets/images/img/rightTriangle.png" />
							<div v-html="roomInfo.remark"></div>
						</div>
					</div> -->
					<div class="newSectorList" :class="iframeShow && iframeIndex == index?'newSectorListSelect':''" v-for="(item,index) in mainJson.block9.right.list" :key="index"
					@click="liveBroadcast(item,index)">
					<!-- {{item.date}} -->
						<div class="newSectorListTitle">{{item.date}}</div>
						<div class="subtitle display">
							<img src="@/assets/images/img/rightTriangle.png" />
							<div v-html="item.title"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 板块9 样式可公用板块2的-->
		<div class="plateTwo plateNine" v-if="mainJson.block10">
			<div class="plateBox plateNineBox">
				<div class="plateTitle plateTitleNine" v-html="mainJson.block10.title"></div>
				<div class="samePlate samePlateNine display">
					<span v-html="mainJson.block10.desc"></span>
				</div>
				<div @click="openAccount(2)" class="plateMore discen plateMoreNine">
					<span>{{ $t('plateNine.joinNow') }}</span>
					<img class="moreImg" src="@/assets/images/img/more.png"/>
				</div>
			</div>
			<img class="lastLeft" :src="mainJson.block10.img" v-if="mainJson.block10.img"/>
		</div>
		<!-- 联系我们 -->
		<div class="contactUs">
			<div class="indexTitle">{{ $t('contactUs.title') }}</div>
			<div class="indexSubTitle">{{ $t('contactUs.desc') }}</div>
			<div class="formBox">
				<div class="sameForm"  v-for="(item,index) in contactUs" :key="index" 
				@click="item.type == 'select'?selectFun():''">
					<div class="sameFormTitle">{{item.title}}:</div>
					<input class="sameFormInput" v-model="item.value" :type="item.type" v-if="item.type != 'select'"
					:disabled="item.type == 'select'?true:false"  />
					<div class="sameFormInput display" v-if="item.type == 'select'">{{item.value}}</div>
					<!-- :placeholder="item.type == 'select'?($t('contactUs.tipss')+ item.title):($t('contactUs.tips')+item.title)" -->
					<div class="sameFormInputSelect" :class="(isSelect && index == 4?'sameFormInputSelectMore':'')">
						<div class="sameCountry" @click="chooseFun(cindex)" v-for="(item,cindex) in countryList" :key="cindex">{{item.name}}</div>
					</div>
				</div>
			</div>
			<div class="submit discen" @click="submit">{{ $t('contactUs.submit') }}</div>
		</div>
		<!--  -->
		<Tips :clickCcountAll="clickCcountAll" @openAccountAllFun="openAccountAllFun"></Tips>
	</div>
</template>

<script>
	import TCPlayer from 'tcplayer.js';
	import 'tcplayer.js/dist/tcplayer.min.css';
	import Tips  from '../components/layout/tips';
	import Swiper from 'swiper';
	export default {
		name: 'Index',
		components:{
			Tips
		},
		data() {
			return {
				options:{
					autoplay:false,
					isLive:true
				},
				iframeShow:true,
				iframeIndex:0,
				iframeKey:'',
				page:1,
				newsList:[],
				totalPage:1,
				roomInfo:{},
				mainJson:{},
				clickCcountAll:false,
				isSelect:false,
				contactUs:[],
				plateFiveIndex:1,
				currentTime:'',
				countryList: [
					{name:"中国 (China)",areaCode:"86"},
					{name:"中国香港 (Hong Kong)",areaCode:"852"},
					{name:"中国澳門 (Macau)",areaCode:"853"},
					{name:"中国台湾 (Taiwan)",areaCode:"886"},
					{name:"日本 (Japan)",areaCode:"81"},
					{name:"韩国 (South Korea)",areaCode:"82"},
					{name:"美国 (USA or Canada)",areaCode:"1"},
					{name:"阿富汗 (Afghanistan)",areaCode:"93"},
					{name:"阿尔巴尼亚 (Albania)",areaCode:"355"},
					{name:"阿尔及利亚 (Algeria)",areaCode:"213"},
					{name:"萨摩亚 (American Samoa)",areaCode:"684"},
					{name:"安道尔共和国 (Andorra)",areaCode:"376"},
					{name:"安哥拉 (Angola)",areaCode:"244"},
					{name:"安圭拉岛 (Anguilla)",areaCode:"1264"},
					{name:"南极洲 (Antarctica)",areaCode:"672"},
					{name:"安提瓜和巴布达 (Antigua and Barbuda)",areaCode:"1268"},
					{name:"阿根廷 (Argentina)",areaCode:"54"},
					{name:"亚美尼亚 (Armenia)",areaCode:"374"},
					{name:"阿鲁巴 (Aruba)",areaCode:"297"},
					{name:"澳大利亚 (Australia)",areaCode:"61"},
					{name:"奥地利 (Austria)",areaCode:"43"},
					{name:"阿塞拜疆 (Azerbaijan)",areaCode:"994"},
					{name:"巴哈马 (Bahamas)",areaCode:"1242"},
					{name:"巴林 (Bahrain)",areaCode:"973"},
					{name:"孟加拉国 (Bangladesh)",areaCode:"880"},
					{name:"巴巴多斯 (Barbados)",areaCode:"1246"},
					{name:"白俄罗斯 (Belarus)",areaCode:"375"},
					{name:"比利时 (Belgium)",areaCode:"32"},
					{name:"伯利兹城 (Belize)",areaCode:"501"},
					{name:"贝宁 (Benin)",areaCode:"229"},
					{name:"百慕大 (Bermuda)",areaCode:"1441"},
					{name:"不丹 (Bhutan)",areaCode:"975"},
					{name:"玻利维亚 (Bolivia)",areaCode:"591"},
					{name:"波斯尼亚和黑塞哥维那 (Bosnia and Herzegovina)",areaCode:"387"},
					{name:"博茨瓦纳 (Botswana)",areaCode:"267"},
					{name:"巴西 (Brazil)",areaCode:"55"},
					{name:"英属印度洋领地 (British Indian Ocean Territory)",areaCode:"246"},
					{name:"文莱达鲁萨兰国 (Brunei Darussalam)",areaCode:"673"},
					{name:"保加利亚 (Bulgaria)",areaCode:"359"},
					{name:"布基纳法索 (Burkina Faso)",areaCode:"226"},
					{name:"布隆迪 (Burundi)",areaCode:"257"},
					{name:"柬埔寨 (Cambodia)",areaCode:"855"},
					{name:"喀麦隆 (Cameroon)",areaCode:"237"},
					{name:"佛得角 (Cape Verde)",areaCode:"238"},
					{name:"开曼群岛 (Cayman Islands)",areaCode:"1345"},
					{name:"中非共和国 (Central African Republic)",areaCode:"236"},
					{name:"乍得 (Chad)",areaCode:"235"},
					{name:"智利 (Chile)",areaCode:"56"},
					{name:"圣延岛 (Christmas Island)",areaCode:"61"},
					{name:"科科斯群岛 (Cocos (Keeling) Islands)",areaCode:"61"},
					{name:"哥伦比亚(Colombia)",areaCode:"57"},
					{name:"科摩罗 (Comoros)",areaCode:"269"},
					{name:"刚果 (Congo)",areaCode:"242"},
					{name:"刚果民主共和国(Congo,The Democratic Republic Of The)",areaCode:"243"},
					{name:"库克群岛 (Cook Islands)",areaCode:"682"},
					{name:"哥斯达黎加 (Costa Rica)",areaCode:"506"},
					{name:"科特迪瓦 (Cote D Ivoire)",areaCode:"225"},
					{name:"克罗地亚 (Croatia)",areaCode:"385"},
					{name:"古巴 (Cuba)",areaCode:"53"},
					{name:"塞浦路斯 (Cyprus)",areaCode:"357"},
					{name:"捷克 (Czech Republic)",areaCode:"420"},
					{name:"丹麦 (Denmark)",areaCode:"45"},
					{name:"吉布提 (Djibouti)",areaCode:"253"},
					{name:"多米尼克国 (Dominica)",areaCode:"1767"},
					{name:"多米尼加共和国 (Dominican Republic)",areaCode:"1849"},
					{name:"东帝汶 (East Timor)",areaCode:"670"},
					{name:"厄瓜多尔 (Ecuador)",areaCode:"593"},
					{name:"埃及 (Egypt)",areaCode:"20"},
					{name:"萨尔瓦多 (El Salvador)",areaCode:"503"},
					{name:"赤道几内亚 (Equatorial Guinea)",areaCode:"240"},
					{name:"爱沙尼亚 (Estonia)",areaCode:"372"},
					{name:"埃塞俄比亚 (Ethiopia)",areaCode:"251"},
					{name:"福克兰群岛(Falkland Islands (Malvinas))",areaCode:"500"},
					{name:"法罗群岛 (Faroe Islands)",areaCode:"298"},
					{name:"斐济 (Fiji)",areaCode:"679"},
					{name:"芬兰 (Finland)",areaCode:"358"},
					{name:"法国 (France)",areaCode:"33"},
					{name:"法国大都会 (France Metropolitan)",areaCode:"33"},
					{name:"法属圭亚那 (French Guiana)",areaCode:"594"},
					{name:"法属玻里尼西亚 (French Polynesia)",areaCode:"689"},
					{name:"加蓬 (Gabon)",areaCode:"241"},
					{name:"冈比亚 (Gambia)",areaCode:"220"},
					{name:"格鲁吉亚 (Georgia)",areaCode:"995"},
					{name:"德国 (Germany)",areaCode:"49"},
					{name:"加纳 (Ghana)",areaCode:"233"},
					{name:"直布罗陀 (Gibraltar)",areaCode:"350"},
					{name:"希腊 (Greece)",areaCode:"30"},
					{name:"格陵兰 (Greenland)",areaCode:"45"},
					{name:"格林纳达 (Grenada)",areaCode:"1473"},
					{name:"瓜德罗普岛 (Guadeloupe)",areaCode:"590"},
					{name:"关岛 (Guam)",areaCode:"1671"},
					{name:"危地马拉 (Guatemala)",areaCode:"502"},
					{name:"几内亚 (Guinea)",areaCode:"224"},
					{name:"几内亚比绍 (Guinea-Bissau)",areaCode:"245"},
					{name:"圭亚那 (Guyana)",areaCode:"592"},
					{name:"海地 (Haiti)",areaCode:"509"},
					{name:"洪都拉斯 (Honduras)",areaCode:"504"},
					{name:"匈牙利 (Hungary)",areaCode:"36"},
					{name:"冰岛 (Iceland)",areaCode:"354"},
					{name:"印度 (India)",areaCode:"91"},
					{name:"印度尼西亚 (Indonesia)",areaCode:"62"},
					{name:"伊朗 (Iran (Islamic Republic of))",areaCode:"98"},
					{name:"伊拉克 (Iraq)",areaCode:"964"},
					{name:"爱尔兰 (Ireland)",areaCode:"353"},
					{name:"以色列 (Israel)",areaCode:"972"},
					{name:"意大利 (Italy)",areaCode:"39"},
					{name:"牙买加 (Jamaica)",areaCode:"1876"},
					{name:"约旦 (Jordan)",areaCode:"962"},
					{name:"哈萨克 (Kazakhstan)",areaCode:"7"},
					{name:"肯尼亚 (Kenya)",areaCode:"254"},
					{name:"科威特 (Kuwait)",areaCode:"965"},
					{name:"吉尔吉斯 (Kyrgyzstan)",areaCode:"996"},
					{name:"拉脱维亚 (Latvia)",areaCode:"371"},
					{name:"黎巴嫩 (Lebanon)",areaCode:"961"},
					{name:"莱索托 (Lesotho)",areaCode:"266"},
					{name:"利比里亚 (Liberia)",areaCode:"231"},
					{name:"利比亚 (Libyan Arab Jamahiriya)",areaCode:"218"},
					{name:"列支敦士登 (Liechtenstein)",areaCode:"423"},
					{name:"立陶宛 (Lithuania)",areaCode:"370"},
					{name:"卢森堡 (Luxembourg)",areaCode:"352"},
					{name:"马达加斯加 (Madagascar)",areaCode:"261"},
					{name:"马拉维 (Malawi)",areaCode:"265"},
					{name:"马来西亚 (Malaysia)",areaCode:"60"},
					{name:"马尔代夫 (Maldives)",areaCode:"960"},
					{name:"马里 (Mali)",areaCode:"223"},
					{name:"马尔他 (Malta)",areaCode:"356"},
					{name:"马提尼克岛 (Martinique)",areaCode:"596"},
					{name:"毛里塔尼亚 (Mauritania)",areaCode:"222"},
					{name:"毛里求斯(Mauritius)",areaCode:"230"},
					{name:"马约特 (Mayotte)",areaCode:"262"},
					{name:"墨西哥 (Mexico)",areaCode:"52"},
					{name:"密克罗尼西亚 (Micronesia)",areaCode:"691"},
					{name:"摩尔多瓦 (Moldova)",areaCode:"373"},
					{name:"摩纳哥 (Monaco)",areaCode:"377"},
					{name:"外蒙古 (Mongolia)",areaCode:"976"},
					{name:"黑山共和国 (Montenegro)",areaCode:"382"},
					{name:"蒙特塞拉特 (Montserrat)",areaCode:"1664"},
					{name:"摩洛哥 (Morocco)",areaCode:"212"},
					{name:"莫桑比克 (Mozambique)",areaCode:"258"},
					{name:"缅甸 (Myanmar)",areaCode:"95"},
					{name:"那米比亚 (Namibia)",areaCode:"264"},
					{name:"瑙鲁 (Nauru)",areaCode:"674"},
					{name:"尼泊尔 (Nepal)",areaCode:"977"},
					{name:"荷兰 (Netherlands)",areaCode:"31"},
					{name:"荷兰安的列斯群岛 (Netherlands Antilles)",areaCode:"599"},
					{name:"新喀里多尼亚 (New Caledonia)",areaCode:"687"},
					{name:"新西兰 (New Zealand)",areaCode:"64"},
					{name:"尼加拉瓜 (Nicaragua)",areaCode:"505"},
					{name:"尼日尔 (Niger)",areaCode:"227"},
					{name:"尼日利亚 (Nigeria)",areaCode:"234"},
					{name:"诺福克岛 (Norfolk Island)",areaCode:"6723"},
					{name:"朝鲜 (North Korea)",areaCode:"850"},
					{name:"北马里亚纳群岛 (Northern Mariana Islands)",areaCode:"1670"},
					{name:"挪威 (Norway)",areaCode:"47"},
					{name:"阿曼 (Oman)",areaCode:"968"},
					{name:"巴基斯坦 (Pakistan)",areaCode:"92"},
					{name:"帛琉 (Palau)",areaCode:"680"},
					{name:"巴勒斯坦 (Palestine)",areaCode:"970"},
					{name:"巴拿马 (Panama)",areaCode:"507"},
					{name:"巴布亚新几内亚 (Papua New Guinea)",areaCode:"675"},
					{name:"巴拉圭 (Paraguay)",areaCode:"595"},
					{name:"秘鲁 (Peru)",areaCode:"51"},
					{name:"菲律宾共和国 (Philippines)",areaCode:"63"},
					{name:"皮特凯恩岛 (Pitcairn)",areaCode:"64"},
					{name:"波兰 (Poland)",areaCode:"48"},
					{name:"葡萄牙 (Portugal)",areaCode:"351"},
					{name:"波多黎各 (Puerto Rico)",areaCode:"1787"},
					{name:"卡塔尔 (Qatar)",areaCode:"974"},
					{name:"留尼汪岛 (Reunion)",areaCode:"262"},
					{name:"罗马尼亚 (Romania)",areaCode:"40"},
					{name:"俄罗斯联邦 (Russian Federation)",areaCode:"7"},
					{name:"卢旺达 (Rwanda)",areaCode:"250"},
					{name:"美属萨摩亚 (Samoa)",areaCode:"685"},
					{name:"圣马力诺共和国 (San Marino)",areaCode:"378"},
					{name:"沙特阿拉伯 (Saudi Arabia)",areaCode:"966"},
					{name:"塞内加尔 (Senegal)",areaCode:"221"},
					{name:"塞尔维亚共和国 (Serbia)",areaCode:"381"},
					{name:"塞舌尔 (Seychelles)",areaCode:"248"},
					{name:"塞拉利昂 (Sierra Leone)",areaCode:"232"},
					{name:"新加坡 (Singapore)",areaCode:"65"},
					{name:"斯洛伐克 (Slovakia (Slovak Republic))",areaCode:"421"},
					{name:"斯洛文尼亚 (Slovenia)",areaCode:"386"},
					{name:"索罗门群岛 (Solomon Islands)",areaCode:"677"},
					{name:"索马里 (Somalia)",areaCode:"252"},
					{name:"南非 (South Africa)",areaCode:"27"},
					{name:"西班牙 (Spain)",areaCode:"34"},
					{name:"斯里兰卡 (Sri Lanka)",areaCode:"94"},
					{name:"苏丹 (Sudan)",areaCode:"249"},
					{name:"苏里南 (Suriname)",areaCode:"597"},
					{name:"斯威士兰 (Swaziland)",areaCode:"268"},
					{name:"瑞典 (Sweden)",areaCode:"46"},
					{name:"瑞士 (Switzerland)",areaCode:"41"},
					{name:"叙利亚 (Syrian Arab Republic)",areaCode:"963"},
					{name:"塔吉克 (Tajikistan)",areaCode:"992"},
					{name:"坦桑尼亚 (Tanzania)",areaCode:"255"},
					{name:"泰国 (Thailand)",areaCode:"66"},
					{name:"多哥 (Togo)",areaCode:"228"},
					{name:"汤加 (Tonga)",areaCode:"676"},
					{name:"特立尼达和多巴哥 (Trinidad and Tobago)",areaCode:"1868"},
					{name:"突尼斯 (Tunisia)",areaCode:"216"},
					{name:"土耳其 (Turkey)",areaCode:"90"},
					{name:"土库曼 (Turkmenistan)",areaCode:"993"},
					{name:"土克斯及开科斯群岛 (Turks and Caicos Islands)",areaCode:"1809"},
					{name:"乌干达 (Uganda)",areaCode:"256"},
					{name:"乌克兰 (Ukraine)",areaCode:"380"},
					{name:"阿拉伯联合酋长国 (United Arab Emirates)",areaCode:"971"},
					{name:"英国 (United Kingdom)",areaCode:"44"},
					{name:"乌拉圭 (Uruguay)",areaCode:"598"},
					{name:"乌兹别克斯坦 (Uzbekistan)",areaCode:"998"},
					{name:"瓦努阿图 (Vanuatu)",areaCode:"678"},
					{name:"梵蒂冈 (Vatican City State (Holy See))",areaCode:"39"},
					{name:"委内瑞拉 (Venezuela)",areaCode:"58"},
					{name:"越南 (Vietnam)",areaCode:"84"},
					{name:"维尔京群岛(英国) (Virgin Islands (British))",areaCode:"1284"},
					{name:"维尔京群岛(美国) (Virgin Islands (U.S.))",areaCode:"1340"},
					{name:"西撒哈拉 (Western Sahara)",areaCode:"685"},
					{name:"也门 (Yemen)",areaCode:"967"},
					{name:"南斯拉夫 (Yugoslavia)",areaCode:"381"},
					{name:"赞比亚 (Zambia)",areaCode:"260"},
					{name:"津巴布韦 (Zimbabwe)",areaCode:"263"},
					{name:"阿布哈兹 (the Republic of Abkhazia)",areaCode:"7"},
					{name:"南奥赛梯 (the Republic of South Ossetia)",areaCode:"7"},
					{name:"泽西岛 (Bailiwick of Jersey)",areaCode:"44"},
					{name:"老挝 (Lao People s Democratic Republic)",areaCode:"856"},
					{name:"马其顿 (The Republic of Macedonia)",areaCode:"389"},
					{name:"圣基茨和尼维斯(The Federation of Saint Kitts and Nevis)",areaCode:"1869"},
					{name:"圣卢西亚岛 (Santa Luzia Island)",areaCode:"1758"},
					{name:"圣文森特和格林纳丁斯(Saint Vincent and the Grenadines)",areaCode:"1784"},
					{name:"圣多美和普林西比 (Sao Tome and Principe)",areaCode:"239"},
					{name:"南苏丹共和国 (The Republic of South Sudan)",areaCode:"211"}
				
				],
				
			}
		},
		mounted() {
			
		},
		created() {
			this.contactUs = this.$t('contactUs.list');
			this.getJsonConfig();
			this.getNewsList();
			this.getLiveRoomInfo();
			console.log()
			
			this.currentTime = new Date().getTime();
			console.log(this.currentTime);
		},
		methods: {
			accountJump(){
				this.$http.get(this.$apiBaseUrl+'getCommonConfig?key=regist_url').then((res)=>{
					window.open(res.data.svalue,'_blank')
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			openLink(item){
				if(item.link){
					window.open(item.link,'_blank')
				}
			},
			liveBroadcast(item,index){
				this.iframeInfo = item;
				this.iframeIndex = index;
				this.iframeKey = new Date().getTime();
				this.iframeShow = true;
			},
			getNewsList(){
				var lang = localStorage.getItem('lang') == 'zh'?'zh':'en';
				this.$http.get(this.$apiBaseUrl+'newsList?lang='+lang+'&pageSize=4'+'&pageNum='+this.page).then((res)=>{
					// console.log('res',res)
					(res.rows).forEach((item,index)=>{
						item['month'] = item.createTime.substring(5, 7);
						item['day'] = item.createTime.substring(8,10)
						item['newTime'] = this.formatPast(item.createTime);
						if(item.icons){	
							item['iconList'] = (item.icons).split(',');
						}else{
							item['iconList'] = [];
						}
						
					})
					this.newsList = res.rows;
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			formatPast(date, type = "default", zeroFillFlag = true){
				// 定义countTime变量，用于存储计算后的数据
				let countTime;
				// 获取当前时间戳
				let time = new Date().getTime();
				// 转换传入参数为时间戳
				let afferentTime = new Date(date).getTime();
				// 当前时间戳 - 传入时间戳
				time = Number.parseInt(`${time - afferentTime}`);
				if (time < 10000) {
				    // 10秒内
				    return "刚刚";
				} else if (time < 60000) {
				    // 超过10秒少于1分钟内
				    countTime = Math.floor(time / 1000);
				    return `${countTime}秒前`;
				} else if (time < 3600000) {
				    // 超过1分钟少于1小时
				    countTime = Math.floor(time / 60000);
				    return `${countTime}分钟前`;
				} else if (time < 86400000) {
				    // 超过1小时少于24小时
				    countTime = Math.floor(time / 3600000);
				    return `${countTime}小时前`;
				} else if (time >= 86400000 && type == "default") {
				    // 超过二十四小时（一天）且格式参数为默认"default"
				    countTime = Math.floor(time / 86400000);
				    //大于等于365天
				    if (countTime >= 365) {
				        return `${Math.floor(countTime / 365)}年前`;
				    }
				    //大于等于30天
				    if (countTime >= 30) {
				        return `${Math.floor(countTime / 30)}个月前`;
				    }
				    return `${countTime}天前`;
				} else {
				    // 一天（24小时）以上且格式不为"default"则按传入格式参数显示不同格式
				    // 数字补零
				    let Y = new Date(date).getFullYear();
				    let M = new Date(date).getMonth() + 1;
				    let zeroFillM = M > 9 ? M : "0" + M;
				    let D = new Date(date).getDate();
				    let zeroFillD = D > 9 ? D : "0" + D;
				    // 传入格式为"-" "/" "."
				    if (type == "-" || type == "/" || type == ".") {
				        return zeroFillFlag
				            ? Y + type + zeroFillM + type + zeroFillD
				            : Y + type + M + type + D;
				    }
				    // 传入格式为"年月日"
				    if (type == "年月日") {
				        return zeroFillFlag
				            ? Y + type[0] + zeroFillM + type[1] + zeroFillD + type[2]
				            : Y + type[0] + M + type[1] + D + type[2];
				    }
				    // 传入格式为"月日"
				    if (type == "月日") {
				        return zeroFillFlag
				            ? zeroFillM + type[0] + zeroFillD + type[1]
				            : M + type[0] + D + type[1]
				    }
				    // 传入格式为"年"
				    if (type == "年") {
				        return Y + type
				    }
							
				}
			},
			
			getLiveRoomInfo(){
				var url = localStorage.getItem('lang') == 'zh'?'getLiveRoomInfo?lang=zh':'getLiveRoomInfo?lang=en';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					this.roomInfo = res.data;
					// if(res.data.living){
					// 	// 暂停播放
					// 	this.$refs.VueAliplayerV2.pause();
					// }
					
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			getJsonConfig(){
				// fileName=index_en.json  英文
				// index.json 中文
				// http://47.236.4.7:9999/app-api/website/config/getJsonConfig?fileName=index.json
				// http://47.236.4.7:9999/app-api/website/config/getJsonConfig?fileName=index_en.json
				var url = localStorage.getItem('lang') == 'zh'?'getJsonConfig?fileName=index.json':'getJsonConfig?fileName=index_en.json';
				this.$http.get(this.$apiBaseUrl+url).then((res)=>{
					var configData = res.data;
					if(configData.banners.length>0&&configData.banners[0].hasOwnProperty('sortNo')){
						configData.banners =  (configData.banners).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block1.length>0&&configData.block1[0].hasOwnProperty('sortNo')){
						configData.block1 =  (configData.block1).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block3.buttons.length>0&&configData.block3.buttons[0].hasOwnProperty('sortNo')){
						configData.block3.buttons =  (configData.block3.buttons).sort((a, b) => a.sortNo - b.sortNo);
					}
					
					if(configData.block4.list.length>0&&configData.block4.list[0].hasOwnProperty('sortNo')){
						configData.block4.list =  (configData.block4.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block5.list.length>0&&configData.block5.list[0].hasOwnProperty('sortNo')){
						configData.block5.list =  (configData.block5.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block6.list.length>0&&configData.block6.list[0].hasOwnProperty('sortNo')){
						configData.block6.list =  (configData.block6.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block7.banners.length>0&&configData.block7.banners[0].hasOwnProperty('sortNo')){
						configData.block7.banners =  (configData.block7.banners).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block8.list.length>0&&configData.block8.list[0].hasOwnProperty('sortNo')){
						configData.block8.list =  (configData.block8.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					if(configData.block9.left.list.length>0&&configData.block9.left.list[0].hasOwnProperty('sortNo')){
						configData.block9.left.list =  (configData.block9.left.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					
					if(configData.block9.right.list.length>0&&configData.block9.right.list[0].hasOwnProperty('sortNo')){
						configData.block9.right.list =  (configData.block9.right.list).sort((a, b) => a.sortNo - b.sortNo);
					}
					this.iframeInfo = configData.block9.right.list[0];
					this.iframeKey = new Date().getTime();
					(configData.block9.left.list).forEach((item,index)=>{
						item['month'] = item.date.substring(5, 7);
						item['day'] = item.date.substring((item.date).length - 2)
					})
					localStorage.setItem('newProduct',JSON.stringify(configData.block4.list));
					this.mainJson = configData;
					this.swiperFun();
					
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			},
			swiperFun(){
				this.$nextTick(function(){
					var swiperBanner = new Swiper('.swiper-banner', {
						loop: this.mainJson.banners.length>1?true:false,
						// 如果需要分页器
						pagination: '.swiper-pagination',
						// 如果需要滚动条
						// scrollbar: '.swiper-scrollbar',
						//如果需要自动切换海报
						autoplay: 5000,
					})
					var swiperPlateSix = new Swiper('.swiper-plateSix', {
						loop: true,
						// 如果需要分页器
						pagination: '.swiper-pagination',
						// 如果需要滚动条
						// scrollbar: '.swiper-scrollbar',
						//如果需要自动切换海报
						autoplay: 5000,
					})
					var swiperPlateEight = new Swiper('.swiper-plateEight', {
						loop: true,
						// 如果需要分页器
						pagination: '.swiper-pagination',
						// 如果需要滚动条
						// scrollbar: '.swiper-scrollbar',
						//如果需要自动切换海报
						autoplay: 5000,
					})
				})
				
			},
			openAccountAllFun(){
				this.clickCcountAll = !this.clickCcountAll;
			},
			selectFun(){
				this.isSelect = !this.isSelect;
			},
			chooseFun(index){
				// this.isSelect = !this.isSelect;
				this.contactUs[4].value = this.countryList[index].name;
				// this.$set(this.isSelect,'',!this.isSelect)
				// this.$forceUpdate();
				// this.selectFun();
				// this.$set(this.isSelect,!this.isSelect)
				console.log(this.isSelect)
				
			},
			openAccount(type){
				// type:1 开户   2合作伙伴注册
				// if(localStorage.getItem('token')){
				// 	this.$message({
				// 	  message: this.$t('element.inReview'),
				// 	  type: 'warning',
				// 	  duration:3000
				// 	});
				// }else{
				// 	this.$message({
				// 	  message: this.$t('element.logon'),
				// 	  type: 'error',
				// 	  duration:3000
				// 	});
				// }
				if(localStorage.getItem('token')){
					this.openAccountAllFun();
				}else{
					this.openAccountAll(type);
					
				}
				
				
			},
			bannerJump(){
				if(localStorage.getItem('token')){
					this.openAccountAllFun();
				}else{
					var liveAddress = localStorage.getItem('liveAddress');
					window.open(liveAddress, '_blank');
				}
				
			},
			submit(){
				for(var i = 0;i < this.contactUs.length;i++){
					var item = this.contactUs[i];
					console.log(item.value)
					if(!item.value){
						this.$message({
						  message: item.type == 'select'?(this.$t('contactUs.tipss')+ item.title):(this.$t('contactUs.tips')+item.title),
						  type: 'error',
						  duration:3000
						});
						return;
					}
				}
				this.$message({
				  message: localStorage.getItem('lang') == 'zh'?'上传成功':'Upload successful',
				  type: 'success',
				  duration:3000
				});
				
			}
		}
	}
</script>
<style>
	.swiper-pagination .swiper-pagination-bullet {
		width: 0.08rem;
		height: 0.08rem;
		background: #FFFFFF;
		border-radius: 50%;
		opacity: 1;
	}
	
	.swiper-pagination .swiper-pagination-bullet-active {
		width: 0.16rem;
		height: 0.08rem;
		background: #D51820;
		border-radius: 0.07rem;
		opacity: 1;
	}
</style>
<style lang="scss" scoped>
	 @import '@/assets/css/index.scss';
</style>